import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ParentLinkService } from 'app/services/parent-link.service';
import { Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
@Component({
  selector: 'attendant-link',
  templateUrl: './attendant-link.component.html',
  styleUrls: ['./attendant-link.component.css']
})
export class AttendantLinkComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _parentLinkService: ParentLinkService
  ) { }

  @Output('onCloseModal') onCloseModal: EventEmitter<boolean> = new EventEmitter(false);
  @Input("relationships") relationships:any[] = [];

  public associatedUser: any = undefined;
  public newAttendant: any = undefined;
  public studentList: any[] = [];
  public parentList: any[] = [];
  public selectedChild: any = null;
  public showSuccessSave:boolean = false;
  public showSuccessDelete:boolean = false;

  public showStudentDropdown: boolean = false;
  public showParentDropdown: boolean = false;

  public loadingData: boolean = false;
  public savingData: boolean = false;
  public deletingAttendant: boolean = false;
  public selectedRelationship: number | null = null;
  private _childrenSubscription: Subscription = new Subscription();


  ngOnInit() {
    this.document.body.style.overflow = 'hidden';
  }

  //On select student
  public onSelectStudent(item: any) {
    this.showSuccessSave = false;
    this.showSuccessDelete = false;

    this.parentList = [];
    this.newAttendant = undefined;
    this.associatedUser = undefined;
    this.selectedChild = item;

    this.loadingData = true;
    this._childrenSubscription = this.getParentQry().subscribe({
      next: (resp) => {
        if(resp.length > 0){
          this.associatedUser = [...resp.map(this.processStudentData.bind(this))][0];
          this.selectedRelationship = resp[0].typeRelationshipIDAttended;
        }
      }, error: (err) => {
        console.log(err);
      },complete: () =>  this.loadingData = false
    })
  }

  //On select parent
  public onSelectParent(item:any){    
    if (this.loadingData)
      return

    //Disable previous item
    if (this.newAttendant && this.newAttendant.id !== item.id)
      this.newAttendant.isSelected = false;

    this.newAttendant = item;
    this.newAttendant.isSelected = true;
  }

  //On search student
  public onLoadStudents(students: any) {
    //If it is already selected disable it
    this.studentList = students.map(e => ({
      ...e,
      isSelected: this.selectedChild && this.selectedChild.id === e.id
    }));

    this.showStudentDropdown = this.studentList.length > 0;
  }

  //On search parent
  public onLoadParents(parents:any){
    const parentList = this.selectedChild ?
      parents.filter(parent => this.selectedChild.id != parent.id) :
      parents;

    //If it is already selected disable it
    this.parentList = parentList.map(e => ({
      ...e,
      isSelected: this.newAttendant && this.newAttendant.id === e.id
    }));

    this.showParentDropdown = this.parentList.length > 0;
  }

  //Prepare get parent query
  private getParentQry() {
    const filter = JSON.stringify({
      where: { StudentUserId: this.selectedChild.id },
      include: [{ parentUser: 'UserDocuments' }, { studentUser: 'UserDocuments' }, { attendantUser: 'UserDocuments' }]
    });

    return this._parentLinkService.getParentToStudents(filter);
  }

  //Save the new attendant
  public assignAttendant(){
    if(!this.newAttendant || this.savingData || !this.selectedRelationship)
      return

    this.savingData = true;
    const data = {
      attendedBy: this.newAttendant.id,
      typeRelationshipIDAttended: this.selectedRelationship
    }

    this._parentLinkService.updateParentToStudents(this.associatedUser.parentToStudentId, data).subscribe({
      next:()=>{
        this.showSuccessSave = true;
      },
      error: (err) => {
        this.showSuccessSave = false;
        console.log(err);
      }, complete:()=>{
        this.savingData = false;
      }
    })
  }

  //Delete the already associated attendant
  public deleteParent(){
    if(this.deletingAttendant)
      return;

    this.deletingAttendant = true;
    const data = {
      attendedBy: null,
      typeRelationshipIDAttended: null
    }

    this._parentLinkService.updateParentToStudents(this.associatedUser.parentToStudentId, data)
    .pipe(
      switchMap(deleted => this.getParentQry().pipe(
        map(parentData => ({deleted, parentData}))
      ))
    ).subscribe({
      next: ({deleted, parentData}) => {

        if(parentData.length > 0){
          this.associatedUser = [...parentData.map(this.processStudentData.bind(this))][0];
          this.selectedRelationship = parentData[0].typeRelationshipIDAttended;
        }

        this.newAttendant = undefined;
        this.parentList = [];
        this.showSuccessDelete = true;
      }, error: (err) => {
        this.showSuccessDelete = false;
        console.log(err);
      }, complete:()=>{
        this.loadingData = false;
        this.deletingAttendant = false;
      }
    })
  }

  private processStudentData(data: any) {
    const { studentUser, parentUser, attendantUser, id } = data;
    const result = {
      parentToStudentId: id,
      id: studentUser.id,
      Name1: studentUser.Name1,
      Name2: studentUser.Name2,
      LastName1: studentUser.LastName1,
      LastName2: studentUser.LastName2,
      Document: studentUser.UserDocuments && studentUser.UserDocuments.length > 0 ? studentUser.UserDocuments[0].Document : null,

      attendantUser: attendantUser ? {
        id: attendantUser.id,
        Name1: attendantUser.Name1,
        Name2: attendantUser.Name2,
        LastName1: attendantUser.LastName1,
        LastName2: attendantUser.LastName2,
        Document: attendantUser.UserDocuments && attendantUser.UserDocuments.length > 0 ? attendantUser.UserDocuments[0].Document : null,
      } : undefined,

      parentUser: {
        id: parentUser.id,
        Name1: parentUser.Name1,
        Name2: parentUser.Name2,
        LastName1: parentUser.LastName1,
        LastName2: parentUser.LastName2,
        Document: parentUser.UserDocuments && parentUser.UserDocuments.length > 0 ? parentUser.UserDocuments[0].Document : null,
      }
    };
    return result;
  }

  public setRelationship(event:Event){
    this.selectedRelationship = Number((event.target as HTMLInputElement).value);
  }

  public closeModal(){
    this.onCloseModal.emit(false);
    this.document.body.style.overflow = 'auto';
  }

  ngOnDestroy(): void {
    this._childrenSubscription.unsubscribe();
  }

  //Hide dropdown
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    const targetElement = event.target as HTMLElement;

    if (!targetElement.closest('.dropdown') && targetElement.id !== 'student-input')
      this.showStudentDropdown = false;

    if (!targetElement.closest('.dropdown') && targetElement.id !== 'parent-input')
      this.showParentDropdown = false;
  }
}