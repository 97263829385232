import { Component, OnInit, OnDestroy, AfterViewChecked, EventEmitter, Output, Input } from '@angular/core';
import { LocalDataSource, ViewCell } from 'ng2-smart-table';
import { UsersField } from '../../../models/usersInfo/users-fields';
import { UsersService } from '../../../models/usersInfo/users.service';
import { AuthService } from '../../../models/Auth/auth.service';
import { Router } from '@angular/router';
import { ProfileNavComponent } from '../../../components/profile-nav/profile-nav.component';
import { Role } from 'app/models/usersInfo/role';
import { RoleMapping } from 'app/models/usersInfo/rolemapping';
import { AttachmentService } from 'app/models/attachment/attachment.service';
import { environment } from 'environments/environment';


// INICIO COMPONENTE PARA EL NG SMART TABLE ROLES
@Component( {
  selector: 'button-view-roles',
  template: `
    <a href="javascript: void(0);" (click)="loadInfo(renderValue)" class="btn btn-primary mr-3">
        Editar Roles
    </a>
  `
} )
export class ButtonViewRoles implements ViewCell, OnInit, AfterViewChecked {
  constructor (

  ) { }
  renderValue: string;
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter()
  ngOnInit() {
    this.renderValue = this.value.toString()
  }

  ngAfterViewChecked() {
    this.renderValue = String( this.value );
  }

  loadInfo( id ) {
    this.save.emit( id )
  }

}
// FIN COMPONENTE PARA EL NG SMART TABLE  ROLES


@Component( {
  selector: 'admin-roles',
  templateUrl: './admin-roles.component.html',
  styleUrls: [ './admin-roles.component.css' ],
  providers: [ ButtonViewRoles ]
} )

export class AdminRolesComponent implements OnInit, OnDestroy {


  schoolID: number = 0;
  user: UsersField;
  userId: number;
  users: UsersField[] = []
  editRole: boolean = false;
  userRoles: RoleMapping[] = []
  roles: Role[] = []
  rolesTotal: Role[] = []
  settingsEmail = {
    noDataMessage: "No hay datos disponibles",
    actions: {
      delete: false,
      add: false,
      edit: false
    },
    pager: {
      perPage: 15,
    },
    columns: {
      id: {
        title: 'Ver Rol',
        filter: false,
        editable: false,
        type: 'custom',
        renderComponent: ButtonViewRoles,
        onComponentInitFunction: ( instance ) => {
          instance.save.subscribe( row => {

            //console.log("algo se edito", row)
            this.loadInfo( row )
            // this.saveDocenteDetails(row.id)
          } );
        }
      },
      CedocEmail: {
        title: 'Correo',
        filter: false,
        editable: false
      },
      Name1: {
        title: 'Primer Nombre',
        filter: true,
        editable: false
      },
      Name2: {
        title: 'Segundo Nombre',
        filter: true,
        editable: false
      },
      LastName1: {
        title: 'Primer Apellido',
        filter: true,
        editable: false
      },
      LastName2: {
        title: 'Segundo Apellido',
        filter: true,
        editable: false
      }
    }
  };
  isAuth = false;
  isSuperAdmin = false;
  sourceEmail: LocalDataSource;


  public UrlAPI:any = environment.UrlAPI;

  constructor (
    private usersService: UsersService,
    private authService: AuthService,
    private router: Router,
    public profileNavComponent: ProfileNavComponent,
    private _attachmentService: AttachmentService,
  ) {



    for ( let role of this.profileNavComponent.roles ) {

      if ( role.name == "Personal" ) {
        this.isAuth = true;
        this.isSuperAdmin = true;
        this.schoolID = role.SchoolID;
        this.sourceEmail = new LocalDataSource(); // create the source
      }
      if ( role.name == "admin" ) {
        this.isAuth = true;
        this.schoolID = role.SchoolID;
        this.sourceEmail = new LocalDataSource(); // create the source
      }
    }

    if ( !this.isAuth ) this.router.navigate( [ '/' ] );

    this.usersService.getAllRoles().subscribe( data => {
      if ( this.isSuperAdmin )
        this.roles = data;
      else
        this.roles = data.filter( r => !( r.name == "Personal" || r.name == "Diedu" ) );

      this.rolesTotal = this.roles;
    } )

  }

  ngOnInit() {
    this.userId = parseInt( this.authService.getCurrentUser() ); //devuelve el id del usuario
  }

  ngOnDestroy() {

  }


  onSearch( query: string = '' ) {

    this.authService.swalLoading( "Cargando Usuarios" )

    this.usersService.getUsersByEmailAndSchoolID( query ).subscribe( users => {

      if ( this.isSuperAdmin )
        this.users = users;
      else
        this.users = users.filter( u => {
          let isSchool = false
          u.roleMappings.forEach( role => {
            if ( role.SchoolID == this.schoolID )
              isSchool = true;
          } );
          return isSchool
        } );

      this.sourceEmail.empty()
      this.sourceEmail.load( this.users )
      this.user = {}
      this.editRole = false
      this.authService.swalEndLoad()
    }, err => {
      this.authService.swalError( "No se pudo cargar los usuarios", "" )
    } )


  }


  loadInfo( userID: number ) {

    // this.authService.swalLoading("Loading User's Info")
    this.user = this.users.find( x => x.id == userID );
    this.authService.swalLoading( "Cargando Roles" )
    this.usersService.getRolesByUserIDAndSchoolID( userID, this.schoolID ).subscribe( data => {
      this.userRoles = data
      if ( !this.isSuperAdmin )
        this.checkAdmonUser()
      this.editRole = true
      this.authService.swalEndLoad()
    }, err => {
      this.authService.swalError( "Error al cargar roles", "No se pudo cargar los roles del usuario, detalles: " + err )
    } )
  }

  checkRole( id ) {
    var num = this.userRoles.find( x => x.roleId == id );
    if ( num )
      return true
    else
      return false

  }

  changeRole( id ) {
    var userID = this.user.id

    console.log( "roleID", id )
    console.log( "los roles del usuario son", this.userRoles )
    var num = this.userRoles.findIndex( x => x.roleId == id );
    if ( num >= 0 ) {
      this.authService.swalLoading( "Realizando Modificación" )
      this.usersService.deleteRoleMappingID( this.userRoles[ num ].id ).subscribe( del => {
        this.authService.swalEndLoad()
        this.userRoles.splice( num, 1 )
      }, err => {
        this.authService.swalError( "Error Al Borrar Asociación", "Se produjo un error, detalles: " + err )
      } )
    }
    else {
      this.authService.swalLoading( "Realizando Modificación" )
      var data = {
        "roleId": id,
        "SchoolID": this.schoolID,
        "principalType": "USER",
        "principalId": userID
      }
      this.usersService.newRoleMappginToUserID( userID, data ).subscribe( newData => {
        this.authService.swalEndLoad()
        console.log( "nuevos datos", newData )
        var newRole = {
          id: id,
          roleMappingID: newData.id
        }
        this.userRoles.push( newRole )
      }, err => {
        this.authService.swalError( "Error Al Borrar Asociación", "Se produjo un error, detalles: " + err )
      } )
    }



  }

  checkAdmonUser() {

    let roles = this.userRoles.find( x => ( x.roleId == 2 || x.roleId == 10 || x.roleId == 3 || x.roleId == 18 ) );

    if ( !!roles || this.userRoles.length == 0 )
      this.roles = this.rolesTotal.filter( u => ( u.name == "Aspirante" || u.name == "Estudiante" || u.name == "Docente" || u.name == "SuperDocente" ) );
    else
      this.roles = this.rolesTotal.filter( u => !( u.name == "Aspirante" || u.name == "Estudiante" || u.name == "Docente" || u.name == "SuperDocente" ) );

  }

  public fileToUpload: File;
  public isArchiveError: boolean = false;
  public uploadArchiveText: string = 'Elija el archivo';
  onFileInput( event: any ) {
    if ( !<File>event.target.files[ 0 ] ) return;
    this.fileToUpload = <File>event.target.files[ 0 ];
    if ( this.fileToUpload.size > 5000000 ) {//5M
      this.uploadArchiveText = 'Error de Archivo'
      this.isArchiveError = true;
    } else {
      this.uploadArchiveText = 'Cambiar el archivo';
      this.isArchiveError = false;
    }
  }
  uploadAttachment() {
    console.log( 'uploadAttachment' );
    this._attachmentService.storageFile( 'singinfo', this.fileToUpload ).then( ( result: any ) => {
      console.log( result );
      let urlFile = `/attachments/singinfo/download/${ result[ 'result' ][ 'files' ][ 'file' ][ 0 ][ 'name' ] }`;
      this.saveUrlSing( urlFile );
    } );
  }
  saveUrlSing( url: string ): void {
    console.log( 'saveUrlSing' );
    let userDocumentID = this.user.UserDocuments[ 0 ].id;
    let data = { Signature: url };
    this.usersService.pachUserDocumentByID( userDocumentID, data ).subscribe( result => {
      console.log( result );
      if ( this.user.UserDocuments.length == 0 ) this.user.UserDocuments.push( result );
      else this.user.UserDocuments[ 0 ] = result;
      this.fileToUpload = undefined;
    } )
  }

  /**
   * Obtiene una URL completa para descargar un archivo PDF específico.
   *
   * @param urlComplement - Una cadena que representa la URL base o parcial.
   * @returns Una cadena que representa la URL completa del archivo PDF.
   */
  getCompleteUrlFile(urlComplement: string = ''): string {
    let url = `${environment.UrlAPI}/${urlComplement}`;
    return url;
  }

}




