import { Component, Input } from "@angular/core";

@Component({
  selector: 'btn-go-to-academic-record',
  template: `<a [href]="'/academicStatus/'+rowData.id" target="_blank" class="">Ver</a>`,
})

export class BtnGoToAcademicRecord {
  @Input() rowData: localRowData;

  constructor( ) { }
}

class localRowData {
  constructor (
    public id: number,
  ) {}
} 
