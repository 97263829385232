import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { CustomFormsModule } from 'ng2-validation';
import { ProfessorCoursesEditComponent, ButtonViewCoursesEditComponent, ButtonViewScheduleGroupsComponent } from './professor-edit-courses.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';

export const routes: Routes = [
  { path: 'agrupacion/professor/courses/edit', component: ProfessorCoursesEditComponent },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    CustomFormsModule,
    HttpModule,
    Ng2SmartTableModule,
  ],
  entryComponents: [
    ButtonViewCoursesEditComponent,
    ButtonViewScheduleGroupsComponent,
  ],
  declarations: [
    ProfessorCoursesEditComponent,
    ButtonViewCoursesEditComponent,
    ButtonViewScheduleGroupsComponent,




  ]

})
export class ProfessorCoursesEditModule { }
