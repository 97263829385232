import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';


import { UsersService } from './models/usersInfo/users.service';
import { OapinfoService } from './models/oapinfo/oapinfo.service';
import { CoursesService } from './models/Coursesinfo/courses.service';
import { AuthService } from "./models/Auth/auth.service";
import { AttachmentService } from "./models/attachment/attachment.service";
import { EvaluationsService } from './models/evaluation/evaluations.service';
import { RecordService } from './models/midterm/record.service';


import { MailService } from './models/mail/mail.service';
import { ChatService } from './models/chat/chat.service';

import { AccordingView } from './components/according-view/according-view.component';

import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrderModule } from 'ngx-order-pipe';
import { ChartsModule } from 'ng2-charts';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app.routing';

import { UrlSaniter } from './models/pipes/saniter.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProfileNavComponent } from './components/profile-nav/profile-nav.component';


@NgModule({
  declarations: [
    EmptyRouteComponent,
    AppComponent,
    AccordingView,
    ProfileNavComponent,
  ],
  imports: [
    RecaptchaModule,
    RecaptchaFormsModule,
    BrowserModule,
    NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    OrderModule,
    ChartsModule,
  ],

  entryComponents: [
    AccordingView],
  providers: [
    UsersService,
    OapinfoService,
    CoursesService,
    AuthService,
    AttachmentService,
    EvaluationsService,
    RecordService,
    MailService,
    ChatService,
    UrlSaniter,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {}
