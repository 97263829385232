import { schoolToLoad } from "./environment.common";

export const environment = {
  production: true,
  UrlAPI: 'https://test-user-dot-cedoc-360c.ue.r.appspot.com/api',
  UrlAPIBackV2: 'https://test-default-dot-cedoc-360c.ue.r.appspot.com/api',
  Url: 'https://test-user-dot-cedoc-360c.ue.r.appspot.com/',
  dialogflow: {
    saraBot: "1e9f879b83eb40bc9a4be925f9274604",
  },

  PRINCIPAL_APP_NAME: 'cedoc',

  STORAGE_NAME_QUALITATIVE_DESC: 'qualitativedescription',
  BUCKET_EXTENDED_DATES : 'new_dates_suport',
  BUCKET_WORK_INFO:'workinfo',
  BUCKET_ACADEMIC_INFO:'academicinfo',
  BUCKET_COURSE_RECIPE:'courcerecipe',
  BUCKET_SCIENCE_INFO:'scientificinfo',
  BUCKET_DOCUMENT_INFO:'documentsinfo',
  BUCKET_ICFES_INFO:'icefesinfo',
  BUCKET_ECAES_INFO:'ecaesinfo',

  userRegister: 'https://us-central1-cedoc-360c.cloudfunctions.net/dev-userRegister/register',
  ...schoolToLoad()
};
