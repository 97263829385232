import { Component } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Course } from '../../../models/Coursesinfo/course';
import { School } from '../../../models/Coursesinfo/school';
import { CoursesService } from '../../../models/Coursesinfo/courses.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../models/Auth/auth.service';
import { ProfileNavComponent } from '../../../components/profile-nav/profile-nav.component';
import { Student } from '../../../models/evaluation/student';
import { elementAt } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { EvaluationsService } from '../../../models/evaluation/evaluations.service';

import { UsersService } from 'app/models/usersInfo/users.service';
import { Inscription } from 'app/models/usersInfo/inscription';
import { UserCourseCert } from 'app/models/usersInfo/user-course-cert';
import { TypeCert } from 'app/models/usersInfo/type-cert';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'environments/environment';

declare var jQuery: any;



@Component({
  selector: 'CertificateView',
  templateUrl: './certificate-view.component.html',
  styleUrls: ['./certificate-view.component.css']

})
export class CertificateViewComponent {

  customOptions: OwlOptions = {
    loop: true,
    navSpeed: 700,
    nav: false,
    dots: false,
    margin: 10,
    responsive: {
      0: {
        items: 3
      },
      400: {
        items: 6
      },
      740: {
        items: 10
      },
      940: {
        items: 13
      }
    }
  }


  stateButton = false
  totalStudent: number;
  coursesbyschool: Course[];
  schools: School[] = [];
  SchoolID: number;
  serverTime: string
  user: any
  isResgistre = false
  isJefe = false
  isCalidad = false
  schoolID: number = 0;
  isDataReady = false;
  isSchoolReady = false;
  courseOferID: number = 0
  //isDataReady = false;
  modalSelect: any
  subjectByCourses: any
  courseID: number = 0
  subjectID: number = 0
  teacherViews: any
  teacherViewDelegates: any
  teacherViewSelfs: any
  listTeacher: any
  TotalF: number = 0
  TotalFC: number = 0
  nameSubject: string
  inforCerts: UserCourseCert[]
  inforCertsTotal: UserCourseCert[]
  newUserCert: UserCourseCert;
  year: number = 0

  courses: Course = {

    CourseOferID: 0
  }
  showdata: boolean = false;
  subjects: any[] = []
  schoolViews: any;
  schoolObs: any;
  certSelected: any;
  userid: string;
  userInscriptions: Inscription[];
  typeCerts: TypeCert;
  typeCertsTotal: TypeCert;
  isFinanciera: boolean = false;
  isInspector: boolean = false;


  fileToUploadCert: any;
  ApproveFi: boolean = false;

  public CEMIL_ICON:any = environment.CEMIL_ICON;
  public EJC_ICON:any = environment.EJC_ICON;




  constructor(
    private coursesService: CoursesService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public profileNavComponent: ProfileNavComponent,
    public evaluationService: EvaluationsService,
    public userService: UsersService

  ) {


    this.modalSelect = {
      Year: new Date().getFullYear(),
      TypeCertID: null,
      IsPaid: null,
      IsSigned: null
    

    }

    //GUARDO LA HORA DEL SERVIDOR
    this.authService.getTime().then(server2 => this.serverTime = server2.time);


    this.coursesService.getAllSchools().subscribe(p => this.schools = p);


    if (this.authService.getCurrentUser() == null) {
      this.router.navigate(['/register']);
    } else {
      //DE LO CONTRARO CARGAR EL ID DEL USUARIO
      this.user = this.authService.getCurrentUser(); //devuelve el id del usuario
      //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
      if (Number.isInteger(parseInt(this.user))) {
        //CONSULTO EL PERFIL DEL USUARIO
        var validar_usuario = [false];

        for (let role of this.profileNavComponent.roles) {
          if (role.name == "Inspector Escuela") {
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
            this.userid = this.authService.getCurrentUser();
            this.isInspector = true

          }
          if (role.name == "Financiera") {

            this.isResgistre = true
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
            this.userid = this.authService.getCurrentUser();
            this.isFinanciera = true
          }
        }

        if (!validar_usuario.includes(true)) { this.router.navigate(['/']); }
        var year = new Date().getFullYear();
        this.userService.getAllUserCourseCerts(this.SchoolID, year).subscribe(allCerts => {
          console.log('Infor Cert', allCerts);
          this.inforCerts = allCerts
          this.inforCertsTotal = allCerts
          //console.log('info cer', this.inforCert);

        })

        this.userService.getTypeCerts(this.SchoolID).subscribe(typeCert => {
          this.typeCerts = typeCert;
        })

        this.userService.getInscrptionByUserID(parseInt(this.userid)).subscribe(p => {

          this.userInscriptions = p
          console.log('Curso', this.userInscriptions);

        })

        this.coursesService.getAllSchoolByDepend(this.SchoolID).subscribe(depend => {
          if (depend.length > 0) {
            this.schools = depend

            this.isSchoolReady = true
            this.isDataReady = true
            this.LoadData()
          } else {
            this.isDataReady = true
            this.LoadData()

          }
        })

      }





    }


  }

  setNewSchool(id) {
    this.SchoolID = id
    this.isDataReady = true
    this.LoadData()
  }

  LoadData() {
    var year = new Date().getFullYear();
    this.userService.getAllUserCourseCerts(this.SchoolID, year).subscribe(allCerts => {
      console.log('Infor Cert', allCerts);
      this.inforCerts = allCerts
      this.inforCertsTotal = allCerts
      //console.log('info cer', this.inforCert);

    })
    this.userService.getTypeCerts(this.SchoolID).subscribe(typeCert => {
      this.typeCerts = typeCert
    })
  }

  dataCert(data: any, index) {
    this.certSelected = data;
    this.certSelected.position = index

  }

  patchUserCourseCert(model, nameModal: string) {

    this.stateButton = true
    console.log('model', model);
    //this.isInspector = true

    var tem = {
      id: model.id,
      IsPaid: true
    }
    this.userService.pachUserCourseCert(tem).subscribe(isPaid => {

      this.stateButton = false
      //Esperar 0.5 segundos despues de actualizar y cerrat ventana modal
      setTimeout(function () {
        jQuery("#" + nameModal).modal('hide');
      }, 500);
      this.inforCerts[this.certSelected.position].IsPaid = 1

    })
  }

  selectNewYear(model) {
    this.year = model.Year
    console.log('year', this.year);

    this.userService.getAllUserCourseCerts(this.SchoolID, this.year).subscribe(allCerts => {
      console.log('Infor Cert New year', allCerts);
      this.inforCerts = allCerts
      this.inforCertsTotal = allCerts  //console.log('info cer', this.inforCert);

      this.selectTypeCert()

    })

  }

  selectTypeCert() {


    this.inforCerts = this.inforCertsTotal.filter(item => {

      let ismatch = true
      for (let key in this.modalSelect) {

        if (item[key] != undefined ) {
          if (item[key] == this.modalSelect[key] || null == this.modalSelect[key]) {
            //ismatch = true
          } else {
            ismatch = false
          }
        }
        else{
          ismatch = true
        }

      }
      return ismatch
    })
  }


  /*
  
  */
  ngOnInit() {

  }

  ngOnDestroy() {

  }
  colorSchool(idSchool: number) {
    let classes = {};

    switch (idSchool) {
      case 0: classes = { cat__info__item_CEDOC: true }; break;
      case 1: classes = { cat__info__item_CEMIL: true }; break;
      case 4: classes = { cat__info__item_EAS: true }; break;
      case 5: classes = { cat__info__item_ESINF: true }; break;
      case 6: classes = { cat__info__item_ESCAB: true }; break;
      case 7: classes = { cat__info__item_ESART: true }; break;
      case 8: classes = { cat__info__item_ESING: true }; break;
      case 9: classes = { cat__info__item_ESCOM: true }; break;
      case 10: classes = { cat__info__item_ESICI: true }; break;
      case 16: classes = { cat__info__item_ESAVE: true }; break;
      case 17: classes = { cat__info__item_ESLOG: true }; break;
      case 18: classes = { cat__info__item_ESCEQ: true }; break;
      case 19: classes = { cat__info__item_ESPOM: true }; break;
      case 20: classes = { cat__info__item_ESMAI: true }; break;
      case 21: classes = { cat__info__item_ESDIH: true }; break;
      case 22: classes = { cat__info__item_CEMAI: true }; break;
      case 23: classes = { cat__info__item_ESIDE: true }; break;
      case 24: classes = { cat__info__item_ESFES: true }; break;
      case 25: classes = { cat__info__item_CEDOC: true }; break;
      case 33: classes = { cat__info__item_ESMIC: true }; break;
      case 34: classes = { cat__info__item_EMSUB: true }; break;
      case 35: classes = { cat__info__item_ESPRO: true }; break;
      default: classes = { cat__info__item_ALL: true }; break;


    }
    return classes;
  }

  cleanData(modalname: string) {


    setTimeout(function () {
      jQuery("#" + modalname).modal('hide');
    }, 500);

  }

  sendFileCert(model: UserCourseCert,  nameModal: string) {
    this.authService.swalLoading('Generando Certificado')
    console.log('model', model);
    
    this.userService.sendCertAprove(model).subscribe(data => {
      
      
   
      var cx = this
      setTimeout(function () {
        var year = new Date().getFullYear();
        cx.userService.getAllUserCourseCerts(cx.SchoolID, year).subscribe(allCerts => {
          console.log('Infor Cert', allCerts);
          cx.inforCerts = allCerts
          //console.log('info cer', cx.inforCert);
          cx.authService.swalSuccess('Certificado Generado', '');
        })
      }, 5000);
 

    }, (error) => {
      console.error(error);
      this.authService.swalError(
        "Error en Archivo",
        ""
      );
    })

    


  
  }

}