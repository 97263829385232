import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { CustomFormsModule } from 'ng2-validation';
import { TextMaskModule } from 'angular2-text-mask';
import { CourseAdminComponent } from './CourseMallas/courseadmin.component';
import { CourseOferComponent } from './CourseMallas/courseofer.component';
import { CourseMallasComponent } from './CourseMallas/coursemallas.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AdminUserComponent, ButtonViewFechasAdminUser, ResumeLinkComponent } from './admin-user/admin-user.component';
import { StudentFinalModule } from './students-final/student-final.module';
import { AntiquityComponent } from './antiquity/antiquity.component';
import { CvListTeacherComponent } from './cv-list-teacher/cv-list-teacher.component';
import { ContractsComponent, ButtonViewTypeContractComponent, isActiveViewProfessor, isActiveViewCV } from './contracts/contracts.page';
import { OrderModule } from 'ngx-order-pipe';
import { ColombiaPipeMododule } from './../../components/profile-nav/profile-nav.component';
import { Ng2AutoCompleteModule } from 'ng2-auto-complete';
import { AdminRolesComponent, ButtonViewRoles } from './admin-roles/admin-roles.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ParentLinkComponent } from 'app/components/parent-link/parent-link.component';
import { UserSearchInputComponent } from 'app/components/user-search-input/user-search-input.component';
import { AttendantLinkComponent } from 'app/components/attendant-link/attendant-link.component';
import { CreateUserAdminModalComponent } from './create-user-admin-modal/create-user-admin-modal.component';
import { BtnGoToAcademicRecord } from './antiquity/smartTableComponents/btnAcademicRecord';

const routes: Routes = [
  { path: 'admin/newcoursemallas/:id', component: CourseMallasComponent },//
  { path: 'admin/newcoursemallas', component: CourseMallasComponent },//
  { path: 'admin/courseofer', component: CourseOferComponent },//
  { path: 'admin/admincourse', component: CourseAdminComponent },//
  { path: 'admin/moduser', component: AdminUserComponent },
  { path: 'admin/antiquity', component: AntiquityComponent },//
  { path: 'admin/cvlistteacher', component: CvListTeacherComponent },//
  { path: 'admin/contracts', component: ContractsComponent },
  { path: 'admin/roles', component: AdminRolesComponent }
];


@NgModule( {
  imports: [
    CommonModule,
    ColombiaPipeMododule,
    RouterModule.forChild( routes ),
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    CustomFormsModule,
    HttpModule,
    Ng2SmartTableModule,
    StudentFinalModule,
    OrderModule,
    Ng2AutoCompleteModule,
    CarouselModule,
  ],
  declarations: [
    ParentLinkComponent,
    AttendantLinkComponent,
    UserSearchInputComponent,
    CourseMallasComponent,
    CourseOferComponent,
    CourseAdminComponent,
    AdminUserComponent,
    AdminRolesComponent,
    AntiquityComponent,
    CvListTeacherComponent,
    ButtonViewFechasAdminUser,
    ResumeLinkComponent,
    ButtonViewTypeContractComponent,
    ContractsComponent,
    isActiveViewProfessor,
    isActiveViewCV,
    ButtonViewRoles,
    CreateUserAdminModalComponent,
    BtnGoToAcademicRecord,
  ],
  exports: [ RouterModule ],
  entryComponents: [
    ButtonViewFechasAdminUser,
    ButtonViewTypeContractComponent,
    ResumeLinkComponent,
    isActiveViewProfessor,
    isActiveViewCV,
    ButtonViewRoles,
    BtnGoToAcademicRecord,
  ],
} )
export class modAdmin { }
