import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LocalDataSource } from 'ng2-smart-table';
import { AttachmentService } from "../../../models/attachment/attachment.service";
import swal from 'sweetalert2'
import { IsAcepted } from '../../../models/Coursesinfo/is-acepted';
import { School } from '../../../models/Coursesinfo/school';
import { Course } from '../../../models/Coursesinfo/course';
import { UsersField } from '../../../models/usersInfo/users-fields';
import { OapinfoService } from '../../../models/oapinfo/oapinfo.service';
import { UsersService } from '../../../models/usersInfo/users.service';
import { CoursesService } from '../../../models/Coursesinfo/courses.service';
import { ProfileNavComponent } from '../../../components/profile-nav/profile-nav.component';
import { AngularCsv } from 'angular7-csv';
import { AccordingView } from '../../../components/according-view/according-view.component';

import { AuthService } from '../../../models/Auth/auth.service';
import { environment } from 'environments/environment';

declare var $: any;

@Component({
  selector: 'cat-page',
  templateUrl: './validations.html',
  styleUrls: ['./validations.css']
})

export class ValidationStaff implements OnInit, OnDestroy {

  fileToUpload: File;
  planta = 400;

  settingsOAP = {
    noDataMessage: "No hay datos disponibles",
    add: {
      addButtonContent: '<i class="fa fa-user-plus"></i>',
      createButtonContent: '<i class="fa fa-check-square "></i>',
      cancelButtonContent: '<i class="fa fa-window-close "></i>',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '<i class="fa fa-pencil-square "></i>',
      saveButtonContent: '<i class="fa fa-floppy-o "></i>',
      cancelButtonContent: '<i class="fa fa-window-close "></i>',
      confirmSave: true

    },
    delete: {
      deleteButtonContent: '<i class="fa fa-trash "></i>',
      confirmDelete: true
    },
    actions: {
      delete: false,
      edit: false,
      columnTitle: "Acciones"
    },
    columns: {
      Document: {
        title: 'Cedula',
        filter: false
      },
      Names: {
        title: 'Nombre',
        filter: false
      }

    }
  };

  settings = {
    noDataMessage: "No hay datos disponibles",
    add: {
      addButtonContent: '<i class="fa fa-user-plus"></i>',
      createButtonContent: '<i class="fa fa-check-square"></i>',
      cancelButtonContent: '<i class="fa fa-window-close"></i>',
    },
    edit: {
      editButtonContent: '<i class="fa fa-pencil-square"></i>',
      saveButtonContent: '<i class="fa fa-floppy-o"></i>',
      cancelButtonContent: '<i class="fa fa-window-close"></i>',
      confirmSave: true
    },
    actions: {
      add: false,
      delete: false,
      columnTitle: "Acciones"
    },
    columns: {
      CedulaMil: {
        title: 'Cédula militar',
        filter: false,
        editable: false,
      },
      Document: {
        title: 'Cédula civil',
        filter: false,
        editable: false,
      },
      IsAcepted: {
        title: 'Aceptado',
        filter: false,
        editor: {
          type: 'list',
          config: {
            list: [{ value: 1, title: 'Si' }, { value: 0, title: 'No' }],
          }
        },
        type: 'custom',
        renderComponent: AccordingView,
        onComponentInitFunction(instance) {
          instance.save.subscribe(row => {
            console.log("algo se edito")
            // this.saveDocenteDetails(row.id)
          });
        }
      },
      Names: {
        title: 'Nombre',
        filter: false,
        editable: false,
      },
      LastNames: {
        title: 'Apellido',
        filter: false,
        editable: false,
      }
    }
  };



  data = [
    {
    },
  ];



  source: LocalDataSource;
  sourceOAP: LocalDataSource;

  schools: School[] = [];
  SchoolID: number;
  ScnoolAcronim = `${environment.PRINCIPAL_APP_NAME.toUpperCase()}`

  courses: Course[];
  users: UsersField[];
  student: UsersField;
  oapfile: string;
  isok: Boolean;

  registerd: number = 0;
  confirmed: number = 0;
  missing: number = 0;
  oapCount: number = 0;

  state: number = 1;
  isAgrupacion: boolean = false
  isPersonal: boolean = false
  isDisabledOAP: boolean=false;

  public SCHOOL_LOGO_DYNAMIC_SVG:any = (id:number) => environment.SCHOOL_LOGO_DYNAMIC_SVG(id);

  constructor(
    private oapinfoService: OapinfoService,
    private usersService: UsersService,
    private coursesService: CoursesService,
    private attachmentService: AttachmentService,
    private route: ActivatedRoute,
    private router: Router,
    public profileNavComponent: ProfileNavComponent,
    public authservice: AuthService,
  ) {

    this.coursesService.getAllSchools().subscribe(p => this.schools = p);



    for (let role of this.profileNavComponent.roles) {

      if (role.name == "admin") {


        this.SchoolID = role.SchoolID;
        this.ScnoolAcronim = role.SchoolAcronim;

      }
      if (role.name == "Personal") {
        this.isPersonal = true
        this.ScnoolAcronim = role.SchoolAcronim;
        this.SchoolID = role.SchoolID;

      }
      if (role.name == "Agrupacion") {
        this.isAgrupacion = true
        this.ScnoolAcronim = role.SchoolAcronim;
        this.SchoolID = role.SchoolID;

      }

    }
    this.planta += this.SchoolID;
    this.source = new LocalDataSource(); // create the source
    this.sourceOAP = new LocalDataSource(); // create the source
  }

  ngOnInit() {
    var state = 1;



    this.student = {
      Document: '',
      Name1: '',
      LastName1: '',
      CedulaMil: '',
      IsMilitar: true,
      SchoolID: this.SchoolID,
      CourseOferID: null,
    }
    this.isok = false;
    this.coursesService.getAllCoursesBySchool(this.SchoolID).subscribe(p =>{
      
    
    
       this.courses = p.sort(function (a, b) {
        // Ascending: first age less than the previous
        return a["NameCourseOfer"].localeCompare(b["NameCourseOfer"]);
    });

    
    });




  }

  ngOnDestroy() {

  }

  callCourse() {

//CUANDO SE SELECCIONA UN CURSO
    //SI ES =0 , NO ES PLANTA
    if (this.student.CourseOferID != 0) {
      this.confirmed = 0;
      this.usersService.getOAPStudentsByCourseID(this.student.CourseOferID).subscribe(p => {
        var restult = p.filter(x=>x.CourseGroupID>0)

        if (restult.length>0 && !this.isPersonal){
          this.isDisabledOAP=true
          console.log("SE VA A DESHABILITAR EL BORRADO DE OAP")

        }
        this.source.load(p);
        this.registerd = this.source.count();
      });

      this.oapinfoService.getbyCourseOferID(this.student.CourseOferID, this.SchoolID).subscribe(p => {
        this.users = p;
        this.sourceOAP.load(p);
        this.oapCount = this.sourceOAP.count();
        this.missing = this.oapCount - this.confirmed;
      });
    } else {

        this.isDisabledOAP=true
        console.log("SE VA A DESHABILITAR EL BORRADO DE OAP")


      //SI ES 0 , ES PORQUE ES DE PLANTA
      this.source.load(this.data);
      this.oapinfoService.getbyCourseOferID(this.student.CourseOferID, this.SchoolID).subscribe(p => {
        this.users = p;
        this.sourceOAP.load(p);
        this.oapCount = this.sourceOAP.count();
        this.missing = this.oapCount - this.confirmed;
      });
    }


  }


  validate() {



    swal({
      title: "Validar Estudiantes",
      text: "Esta Operación Puede tardar un poco",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Si, Validar!",

    }).then((result) => {



      if (result.value) {
      swal({
        type: 'success',
        title: "Validando ..... ",
        text: "Esto Puede Tomar Un Tiempo",
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,

        onOpen: function () {
          swal.showLoading()
        }
      })
      this.oapinfoService.validateOAP(this.student.CourseOferID).subscribe(p => {

        this.confirmed = p.total;
        this.missing = this.oapCount - this.confirmed;


        var pendi = [];

        this.oapinfoService.getbyCourseOferID(this.student.CourseOferID, this.SchoolID).subscribe(pt => {


          var num1;
          var num2;
          p.faltantes.forEach(function (value) {

            num1 = pt.find(x => x.Document == value);
            if (num1)
              pendi.push(num1)

          });

          this.sourceOAP.load(pendi)
          new AngularCsv(pendi, 'PendientesPorRegistro');
        });


        this.usersService.getOAPStudentsByCourseID(this.student.CourseOferID).subscribe(po => {



          this.source.load(po);
        });

        swal("Validados!", "Se Validaron exitosamente " + p.total + " estudiantes. ", "success");
      });

    }
    });
  }

  delete() {

    if (this.isDisabledOAP==false && this.student.CourseOferID){

      swal({
        title: "Borrar Lista OAP",
        text: "Esta Operación limpia la lista OAP para subir un nuevo archivo",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Si, Borrar!",
  
      }).then((result) => {
  
        if (result.value) {
        this.oapinfoService.deleteOAP(this.student.CourseOferID).subscribe(p => {
  
          this.confirmed = 0;
          this.missing = 0;
  
          this.usersService.getOAPStudentsByCourseID(this.student.CourseOferID).subscribe(po => {
            this.source.load(po);
          });
  
          this.sourceOAP.load(this.data);
  
          swal("Borrado!", "Se Borro exitosamente el listado OAP", "success");
        });

      }
      });
    }
    else{
      this.authservice.swalError("Error al borrar","No se Puede borrar la OAP porque ya hay estudiantes asociados")
    }
    
  }



  onSearch(event): any {
    //this.service.createData(event.newData);
  }


  onCreateConfirm(event): any {

    console.debug(JSON.stringify(event.newData));


    this.oapinfoService.save(this.student.CourseOferID, this.SchoolID, event.newData).subscribe(p => {
      event.confirm.resolve();
    });
  }

  onDeleteConfirm(event): any {
    if (window.confirm('Are you sure you want to delete?')) {
      event.data
      event.confirm.resolve();
    } else {
      event.confirm.reject();
    }
  }

  //edit data from student
  onEditConfirm(event): any {

    swal({
      title: "Aprobación Manual",
      text: "Se Aceptara a " + event.data.Names,
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Si, Aceptar!",

    }).then((result) => {
      if (result.value) {

      let isAcepted: IsAcepted;


      if (event.data.IsAcepted == true) {
        { isAcepted = { IsAcepted: false } }

      } else {
        { isAcepted = { IsAcepted: true } }
      }


      this.oapinfoService.updateUserCourseByID(event.data.UserCourseID, isAcepted).subscribe(p => {
        event.confirm.resolve();
      });

      // swal("Cargado!", "Se Cargó exitosamente " + p.length + "estudiantes del archivo.", "success");
    }
    });

  }

  uploadFile(fileInput: any) {


    this.fileToUpload = <File>fileInput.target.files[0];





    swal({
      title: "Cargar Archivo",
      text: "Esta Operación Puede tardar un poco, te avisaremos cuando termine",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Si, Cargar!",

    }).then((result) => {


      if (result.value) {

      this.attachmentService.sendFile(this.student.CourseOferID, this.SchoolID, this.fileToUpload).then((result) => {


        this.oapinfoService.getbyCourseOferID(this.student.CourseOferID, this.SchoolID).subscribe(p => {
          this.users = p;
          this.sourceOAP.load(p);
          this.oapCount = this.sourceOAP.count();
          this.missing = this.oapCount - this.confirmed;
          swal("Cargado!", "Se Cargó exitosamente " + p.length + "estudiantes del archivo.", "success");
        });

      }, (error) => {
        console.error(error);
      });


    }

    });










  }

  onSearch1(query: string = '') {

    if (query == "") {
      this.source.setFilter([])
    } else {
      query = query.replace(/\s/g, '');

      this.source.setFilter([
        {
          field: 'Document',
          search: query
        }
      ], false);
    }


  }

  onSearch2(query: string = '') {

    if (query == "") {
      this.sourceOAP.setFilter([])
    } else {
      query = query.replace(/\s/g, '');

      this.sourceOAP.setFilter([
        {
          field: 'Document',
          search: query
        },
        {
          field: 'CedulaMil',
          search: query
        }
      ], false);
    }


  }




  colorSchool(idSchool: number) {
    let classes = {};

    switch (idSchool) {
      case 0: classes = { cat__info__item_CEDOC: true }; break;
      case 1: classes = { cat__info__item_CEMIL: true }; break;
      case 4: classes = { cat__info__item_EAS: true }; break;
      case 5: classes = { cat__info__item_ESINF: true }; break;
      case 6: classes = { cat__info__item_ESCAB: true }; break;
      case 7: classes = { cat__info__item_ESART: true }; break;
      case 8: classes = { cat__info__item_ESING: true }; break;
      case 9: classes = { cat__info__item_ESCOM: true }; break;
      case 10: classes = { cat__info__item_ESICI: true }; break;
      case 16: classes = { cat__info__item_ESAVE: true }; break;
      case 17: classes = { cat__info__item_ESLOG: true }; break;
      case 18: classes = { cat__info__item_ESCEQ: true }; break;
      case 19: classes = { cat__info__item_ESPOM: true }; break;
      case 20: classes = { cat__info__item_ESMAI: true }; break;
      case 21: classes = { cat__info__item_ESDIH: true }; break;
      case 22: classes = { cat__info__item_CEMAI: true }; break;
      case 23: classes = { cat__info__item_ESIDE: true }; break;
      case 24: classes = { cat__info__item_ESFES: true }; break;
      case 25: classes = { cat__info__item_CEDOC: true }; break;
      case 33: classes = { cat__info__item_ESMIC: true }; break;
      case 34: classes = { cat__info__item_EMSUB: true }; break;
      case 35: classes = { cat__info__item_ESPRO: true }; break;
      default: classes = { cat__info__item_ALL: true }; break;


    }
    return classes;
  }





  textSchools(id: number) {
    var num = this.schools.find(x => x.id == id);
    if (num)
      return num.NameTSchool;
    return "Nombre de Escuela"
  }


}

