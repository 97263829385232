import { Component, OnInit, OnDestroy, AfterViewChecked, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LocalDataSource, ViewCell } from 'ng2-smart-table';
import { AttachmentService } from "../../../models/attachment/attachment.service";
import { School } from '../../../models/Coursesinfo/school';
import { Course } from '../../../models/Coursesinfo/course';
import { UsersField } from '../../../models/usersInfo/users-fields';
import { OapinfoService } from '../../../models/oapinfo/oapinfo.service';
import { UsersService } from '../../../models/usersInfo/users.service';
import { CoursesService } from '../../../models/Coursesinfo/courses.service';
import { ProfileNavComponent } from '../../../components/profile-nav/profile-nav.component';
import { AuthService } from '../../../models/Auth/auth.service';
import { ContractsInterface } from '../../../models/Coursesinfo/contracts-interface';
import { isNullOrUndefined } from 'util';
import { DomSanitizer } from "@angular/platform-browser";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';//    D
import { debounceTime, switchMap } from 'rxjs/operators'; 
import * as moment from 'moment';
import { environment } from 'environments/environment';
import { data } from 'jquery';

let async = require("async");

declare let $: any;


export class Intermediate {
  public Disponible = 0;
  public contractType: object = {
    0: "Seleccionar Tipo de Contrato",
  }


}


// INICIO COMPONENTE PARA EL NG SMART TABLE DE ACORDE
@Component({
  selector: 'is-active-professor-table',
  template: `
  <b *ngIf="renderValue=='Si'" >  {{renderValue}}</b>
  <b *ngIf="renderValue=='No'"   style='color: red;'>  {{renderValue}}</b>
      `
})
export class isActiveViewProfessor implements ViewCell, OnInit, AfterViewChecked {
  constructor(

  ) { /* TODO document why this constructor is empty */  }
  renderValue: string;
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter()
  ngOnInit() {
    // //console.log("aca esta el render despues")
    //  //console.log(this.intermediate.Grups)
    if (!isNullOrUndefined(this.value)) {
      if (this.value.toString() == "true" || this.value.toString() == "1")
        this.renderValue = "Si"
      if (this.value.toString() == "false" || this.value.toString() == "0")
        this.renderValue = "No"
    }
  }
  ngAfterViewChecked() {
    this.renderValue = String(this.value);
  }
}
// FIN COMPONENTE PARA EL NG SMART TABLE GRUPOS


// INICIO COMPONENTE PARA EL NG SMART TABLE DE VER HOJA DE VIDA
@Component({
  selector: 'view-cv',
  template: `
  <a class="btn btn-sm btn-outline-primary ladda-button mt-3"  href="/Cv-Public/{{renderValue}}" target="_blank" >Ver Hoja de Vida</a>   
        `
})
export class isActiveViewCV implements ViewCell, OnInit, AfterViewChecked {
  constructor(

  ) { }
  renderValue: string;
  @Input() value: string;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter()
  ngOnInit() {
    // //console.log("aca esta el render despues")
    //  //console.log(this.intermediate.Grups)
    if (!isNullOrUndefined(this.value)) {
      this.renderValue = this.value


    }
  }
  ngAfterViewChecked() {
    this.renderValue = String(this.value);
  }
}
// FIN COMPONENTE PARA EL NG SMART TABLE DE VER HOJA DE VIDA



// INICIO COMPONENTE PARA EL NG SMART TABLE USUARIO
@Component({
  selector: 'button-view-contracts',
  template: `
  {{ renderValue }}
`
})
export class ButtonViewTypeContractComponent implements ViewCell, OnInit, AfterViewChecked {
  constructor(
    public intermediate: Intermediate,

  ) { }
  renderValue: string;
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter()
  ngOnInit() {
    // console.debug("aca esta el render despues")
    // console.debug(this.intermediate.Professor)



    //this.renderValue = this.intermediate.classRoomType.find(x=>x === this.value);

    this.renderValue = this.intermediate.contractType[this.value];
    ////console.log("this.value", this.value)
    ////console.log("this.intermediate.classRoomType", this.intermediate.classRoomType )
    ////console.log(" this.renderValue", this.renderValue)

  }
  ngAfterViewChecked() {
    this.renderValue = this.intermediate.contractType[this.value];
  }
  onClick() {
    this.save.emit(this.rowData);
  }
}
// FIN COMPONENTE PARA EL NG SMART TABLE USUARIO



@Component({
  selector: 'contracts',
  templateUrl: './contracts.html',
  styleUrls: ['./contracts.css'],
  providers: [Intermediate, ButtonViewTypeContractComponent, Intermediate, isActiveViewProfessor, isActiveViewCV]
})

export class ContractsComponent implements OnInit, OnDestroy {
  serverTime: Date
  public mask = [/[1-2]/, /\d/, /\d/, /\d/, '-', /[0-1]/, /\d/, '-', /[0-3]/, /\d/];
  schools: School[] = [];
  SchoolID: number;
  ScnoolAcronim = `${environment.PRINCIPAL_APP_NAME.toUpperCase()}`
  isAgrupacion: boolean = false
  typeContracts: any[] = []
  submitbuton: string = "Guardar"
  EnableTable: boolean = false
  collapseCards: boolean = true;
  course_by_school: Course[];
  student: UsersField = {
    CourseOferID: 0,


  };
  classromID = 0
  classRoomsByCourse: any[] = []
  userJobsContractsBySchoolID: any[] = []
  show: boolean = false
  courseOfferSelected: number = 0
  settings = {
    noDataMessage: "No hay datos disponibles",

    edit: {
      editButtonContent: '<i class="fa fa-pencil-square"></i>',
      saveButtonContent: '<i class="fa fa-floppy-o"></i>',
      cancelButtonContent: '<i class="fa fa-window-close"></i>',
      confirmSave: true,
    },
    /* delete: {
      deleteButtonContent: '<i class="fa fa-window-close"></i>',
      confirmDelete: true
    }, */

    actions: {
      add: false,
      delete: false,
      edit: true,
      columnTitle: "Acciones"
    },
    setPaging: {
      page: 1,
      perPage: 15,
      doEmit: true,
    },

    pager: {
      perPage: 15,
      doEmit: true
    },

    columns: {

      FullName: {
        title: 'Nombre Docente',
        filter: true,
        editable: false,
      },
      Email: {
        title: 'Ver Hoja de Vida',
        filter: true,
        editable: false,
        type: 'custom',
        renderComponent: isActiveViewCV,
      },
      TypeContractID: {
        title: 'Tipo de Contrato',
        filter: false,
        editable: false,
        type: 'custom',
        editor: {
          type: 'list',
          config: {
            list: [
            ],
          },
        },
        renderComponent: ButtonViewTypeContractComponent,
        onComponentInitFunction(instance) {
          instance.save.subscribe(row => {
            console.debug("algo se edito")
            // this.saveDocenteDetails(row.id)
          });
        }
      },


      ContractNumber: {
        title: ' Número de Contrato',
        filter: true,
        editable: true,
      },

      ContractHours: {
        title: 'Horas Contratadas',
        filter: false,
        editable: false,
      },

      LeftTime: {
        title: 'Horas Restantes',
        filter: false,
        editable: false,
      },

      StartContract: {
        title: 'Fecha Inicio Contrato',
        filter: true,
        editable: false,
      },

      EndContract: {
        title: 'Fecha Fin Contrato',
        filter: true,
        editable: false,
      },
      /*
            isActive: {
              title: 'Activo',
              filter: true,
              editable: true,
            }*/

      isActive: {
        title: 'Activo',
        filter: false,
        editable: true,
        editor: {
          type: 'list',
          config: {
            list: [{ value: true, title: 'Si' }, { value: false, title: 'No' }],
          }
        },
        type: 'custom',
        renderComponent: isActiveViewProfessor,
        onComponentInitFunction(instance) {
          instance.save.subscribe(row => {
            //console.log("algo se edito")
            // this.saveDocenteDetails(row.id)
          });
        }
      },




    }
  };

  settingsViewUserJobs = {
    noDataMessage: "No hay datos disponibles",

    edit: {
      editButtonContent: '<i class="fa fa-pencil-square"></i>',
      saveButtonContent: '<i class="fa fa-floppy-o"></i>',
      cancelButtonContent: '<i class="fa fa-window-close"></i>',
      confirmSave: true,
    },
    /* delete: {
      deleteButtonContent: '<i class="fa fa-window-close"></i>',
      confirmDelete: true
    }, */

    actions: {
      add: false,
      delete: false,
      edit: false,
      columnTitle: "Acciones"
    },
    setPaging: {
      page: 1,
      perPage: 15,
      doEmit: true,
    },

    pager: {
      perPage: 15,
      doEmit: true
    },

    columns: {

      FullName: {
        title: 'Nombre Docente',
        filter: true,
        editable: false,
      },
      Email: {
        title: 'Ver Hoja de Vida',
        filter: true,
        editable: false,
        type: 'custom',
        renderComponent: isActiveViewCV,
      }


    }
  };

  source: LocalDataSource;
  sourceUserJob: LocalDataSource;
  Userapps: any[] = [];

  
  public myForm: FormGroup = this.builder.group({
    InfoSelected:["", Validators.required],
    TypeContractID:["", Validators.required],
    isActive:["", Validators.required],
    StartContract:["", Validators.required],
    EndContract:["", Validators.required],
    ContractNumber:["", Validators.required],
    ContractHours:[{value:"", disabled: false}, Validators.required],
  });

  public checkErrors:boolean = false;
  public savingData:boolean = false;

  isContratacion: boolean = false;
  EnableTableViewJobs: boolean=false;
  constructor(
    private authService: AuthService,
    private oapinfoService: OapinfoService,
    private usersService: UsersService,
    private coursesService: CoursesService,
    private attachmentService: AttachmentService,
    private route: ActivatedRoute,
    private router: Router,
    public profileNavComponent: ProfileNavComponent,
    public intermediate: Intermediate,
    private _sanitizer: DomSanitizer,
    private builder: FormBuilder,
  ) {



    this.authService.getTime().then(server2 => this.serverTime = server2.time);
    this.source = new LocalDataSource(); // cr
    this.sourceUserJob = new LocalDataSource(); // cr
    this.coursesService.getAllSchools().subscribe(p => this.schools = p);

    for (let role of this.profileNavComponent.roles) {

      if (role.name == "admin") {
        this.SchoolID = role.SchoolID;
        this.ScnoolAcronim = role.SchoolAcronim;

      }
      if (role.name == "Agrupacion") {
        this.isAgrupacion = true
        this.ScnoolAcronim = role.SchoolAcronim;
        this.SchoolID = role.SchoolID;

      }
      if (role.name == "Contratación") {
        this.isContratacion = true
        this.ScnoolAcronim = role.SchoolAcronim;
        this.SchoolID = role.SchoolID;

      }
    }


    this.coursesService.getAllUserJobsBySchoolID(this.SchoolID).subscribe(userJobs => {
      var elemens=[]

      userJobs.forEach(element => {
        if(element.userapp){
          var NombreCompleto = $.grep([element.userapp["Name1"], element.userapp["Name2"]], Boolean).join(" ");
          var ApellidoCompleto = $.grep([element.userapp["LastName1"], element.userapp["LastName2"]], Boolean).join(" ");
          element.FullName = (ApellidoCompleto + ' ' + NombreCompleto).toUpperCase()
          element.Email = element.userapp.CedocEmail
        }
      });

      userJobs = userJobs.sort(function (a, b) {
        return (a["FullName"] && b["FullName"]) ? a["FullName"].localeCompare(b["FullName"]) : 0;
      });
     
      this.sourceUserJob.load(userJobs)
      this.EnableTableViewJobs=true

    })

    //CARGAR TODOS LOS DOCENTES POR ESCUELA
    this.coursesService.getAllUserJobsContractBySchoolID(this.SchoolID).subscribe(userJobsContracts => {
      console.debug("LOS PROFESORES POR ESUELA SON:", userJobsContracts)
      //MOSTRAR EL NOMBRE COMPLETO
      userJobsContracts.forEach(element => {
        if(element.Userapps){
          //console.debug(element)
          let NombreCompleto = $.grep([element.Userapps["Name1"], element.Userapps["Name2"]], Boolean).join(" ");
          let ApellidoCompleto = $.grep([element.Userapps["LastName1"], element.Userapps["LastName2"]], Boolean).join(" ");
          element.FullName = (ApellidoCompleto + ' ' + NombreCompleto).toUpperCase()
          element.Email = element.Userapps.CedocEmail
        }
      });

      userJobsContracts = userJobsContracts.sort(function (a, b) {
        return (a["FullName"] && b["FullName"]) ? a["FullName"].localeCompare(b["FullName"]) : 0;
      });
      
      this.userJobsContractsBySchoolID = userJobsContracts
      this.coursesService.getAllCoursesBySchool(this.SchoolID).subscribe(p => {
        this.course_by_school = p.sort(function (a, b) {
          // Ascending: first age less than the previous
          return (a["FullName"] && b["FullName"]) ? a["FullName"].localeCompare(b["FullName"]) : 0;
        });

        console.debug(this.course_by_school)
      });


      //LUEGO CARGO TODOS LOS TIPOS DE SALONES
      this.coursesService.getAllTypeContracts().subscribe(p => {
        //CON LOS SALONES CARGADOS LOS MOSTRAMOS EN EL SELECT PARA AGREGAR SALONES
        this.typeContracts = p
        let listViewTypeContracts = []
        let RenderViewTypeContracts = []

        //CREO UN ARREGLO CON TODOS LOS TIPOS DE SALONES PARA QUE SE VEAN EN EL SMART TABLE
        p.forEach(element => {
          listViewTypeContracts.push({
            //value: CompleteName.toUpperCase(),
            value: element.id,
            title: element.NameTypeContract,
          })

          RenderViewTypeContracts.push({
            //value: element.id,
            [element.id]: element.NameTypeContract,
          })

        });
        this.settings.columns.TypeContractID.editor.config.list = listViewTypeContracts  //SE CARGA LOS SALONES AL LIST
        this.source.empty() //LIMPIO LA TABLA 
        this.intermediate.contractType = {}
        //VOY A CARGAR CON OBJETOS EL classRoomType
        for (let i = 0; i < RenderViewTypeContracts.length; i++) {
          Object.assign(this.intermediate.contractType, { [listViewTypeContracts[i].value]: listViewTypeContracts[i].title })
        }
        //CUANDO TODO ESTA BIEN, SE CARGA AL SMART TABLE
        this.source.load(userJobsContracts)
        this.EnableTable = true  //SE HABILITA LA TABLA PARA QUE SE HAGA EL RENDERIZADO BIEN.

        //console.log(this.userJobsContractsBySchoolID)
      });





    })




  }

  ngOnInit() {
    this.myForm.get('InfoSelected').valueChanges.pipe(
      debounceTime(400)
    ).subscribe(data => {
      this.coursesService.getAllUserJobsBySchoolID(this.SchoolID).subscribe(responseProviders => {
        responseProviders.forEach(element => {
          let pos = -1
          pos = this.Userapps.findIndex(x => x.CedocEmail === element.userapp.CedocEmail)
          if (pos >= 0) {
          }
          else {
            this.Userapps.push(element.userapp);
          }
        });
      })


    })
    this.setJquerry();

  }

  ngOnDestroy() {

  }






  colorSchool(idSchool: number) {
    let classes = {};

    switch (idSchool) {
      case 0: classes = { cat__info__item_CEDOC: true }; break;
      case 1: classes = { cat__info__item_CEMIL: true }; break;
      case 4: classes = { cat__info__item_EAS: true }; break;
      case 5: classes = { cat__info__item_ESINF: true }; break;
      case 6: classes = { cat__info__item_ESCAB: true }; break;
      case 7: classes = { cat__info__item_ESART: true }; break;
      case 8: classes = { cat__info__item_ESING: true }; break;
      case 9: classes = { cat__info__item_ESCOM: true }; break;
      case 10: classes = { cat__info__item_ESICI: true }; break;
      case 16: classes = { cat__info__item_ESAVE: true }; break;
      case 17: classes = { cat__info__item_ESLOG: true }; break;
      case 18: classes = { cat__info__item_ESCEQ: true }; break;
      case 19: classes = { cat__info__item_ESPOM: true }; break;
      case 20: classes = { cat__info__item_ESMAI: true }; break;
      case 21: classes = { cat__info__item_ESDIH: true }; break;
      case 22: classes = { cat__info__item_CEMAI: true }; break;
      case 23: classes = { cat__info__item_ESIDE: true }; break;
      case 24: classes = { cat__info__item_ESFES: true }; break;
      case 25: classes = { cat__info__item_CEDOC: true }; break;
      case 33: classes = { cat__info__item_ESMIC: true }; break;
      case 34: classes = { cat__info__item_EMSUB: true }; break;
      case 35: classes = { cat__info__item_ESPRO: true }; break;
      default: classes = { cat__info__item_ALL: true }; break;


    }
    return classes;
  }


  setJquerry() {

    $(function () {
      // card controls


      // header double click
      $('.cat__core__sortable .card-header').on('click', function () {
        $(this).closest('.card').toggleClass('cat__core__sortable__collapsed');

      });

    });

  }


  textSchools(id: number) {
    let num = this.schools.find(x => x.id == id);
    if (num)
      return num.NameTSchool;
    return "Nombre de Escuela"
  }


  changeTypeContractUser() {
    const value = Number(this.myForm.get('TypeContractID').value);
    
    this.myForm.patchValue({
      ContractHours: value === 7 ? '' : 1 ,
    })

    if(value === 7){
      this.myForm.get('ContractHours').clearValidators();
      this.myForm.get('ContractHours').disable();
    }
    else{
      this.myForm.get('ContractHours').setValidators(Validators.required);
      this.myForm.get('ContractHours').enable();
    }

     this.myForm.get('ContractHours').updateValueAndValidity();
  }

  validateDateRange(){
    const startDate = this.myForm.get('StartContract').value;
    const endDate = this.myForm.get('EndContract').value;

    console.log(startDate, endDate);
    
    if(!startDate || !endDate)
      return false;

    if(startDate > endDate){
      this.authService.swalError("Ha ocurrido un error", 'La fecha de inicio no puede ser mayor a la de finalización' );
      return false;
    }

    return true;
  }

  createUserJobContract() {
    console.log(this.myForm.value);
    this.checkErrors = true;

    if (!this.validateDateRange() || !this.myForm.valid)
      return

    const data = this.myForm.value;
    data.UserID = data.InfoSelected.id;
    data.SchoolID = this.SchoolID;
    this.authService.swalLoading("Guardando Contrato")

    this.savingData = true;
    this.coursesService.patchUserJobContract(data).pipe(
      switchMap(_ =>  this.coursesService.getAllUserJobsContractBySchoolID(this.SchoolID))
    ).subscribe({
      next:(resp:any)=>{
        let userJobsContracts = resp;

        userJobsContracts.forEach(element => {
          if(element.Userapps){
            let NombreCompleto = $.grep([element.Userapps["Name1"], element.Userapps["Name2"]], Boolean).join(" ");
            let ApellidoCompleto = $.grep([element.Userapps["LastName1"], element.Userapps["LastName2"]], Boolean).join(" ");
            element.FullName = (ApellidoCompleto + ' ' + NombreCompleto).toUpperCase()
            element.Email = element.Userapps.CedocEmail
          }
        });

        userJobsContracts = userJobsContracts.sort(function (a, b) {
          return (a["FullName"] && b["FullName"]) ? a["FullName"].localeCompare(b["FullName"]) : 0;
        });

        this.source.empty()
        this.source.load(userJobsContracts)
        this.userJobsContractsBySchoolID = userJobsContracts

        this.authService.swalSuccess("Contrato creado", '');
        this.myForm.reset();
        this.checkErrors = false;
        this.savingData = false;

        this.myForm.patchValue({
          TypeContractID:'',
          isActive:''
        })

      },error:(err)=>{
        console.log(err);
        this.savingData = false;
        this.authService.swalError("Ha ocurrido un error", err )
      }
    })
  }


  onDeleteConfirm(event) {
    /* if (window.confirm('Are you sure you want to delete?')) {
       this.authService.swalLoading("Borrando Contrati")
       this.coursesService.deleteClassRoom(event.data.id).subscribe(deleted => {
         event.confirm.resolve();
         this.authService.swalEndLoad()
       })
 
     } else {
       event.confirm.reject();
     }
 */
  }

  onEditConfirm(event) {
    this.authService.swalLoading("Acutualizando Contrato")
    this.coursesService.patchUserJobContract({
      id: parseInt(event.newData.id),
      TypeContractID: parseInt(event.newData.TypeContractID),
      isActive: event.newData.isActive,
      ContractNumber: event.newData.ContractNumber,
    }).subscribe(rooms => {
      this.authService.swalEndLoad()
      event.confirm.resolve(event.newData);
    }, err => {
      this.authService.swalError("Error actualizando contrato", "Hubo un error al actualizar el contrato, detalles: " + err)
    })
  }




  changeCheckClassRoom(model, id) {
    //console.log(model, id)
    var num = this.classRoomsByCourse.findIndex(x => x.ClassroomID === id);
    if (num >= 0) {
      this.authService.swalLoading("Realizando Modificación")
      this.coursesService.DeleteClassroomAvailabilitysByID(this.classRoomsByCourse[num].id).subscribe(del => {

      }, err => {
        this.authService.swalError("Error Al Borrar Asociación", "Se produjo un error, detalles: " + err)

      })


    }
    else {
      this.authService.swalLoading("Realizando Modificación")
      // SE CREA LA ASOCIACION Y SE LE ENVIA AL ARRAY DE classRoomsByCourse
      this.coursesService.PatchClassroomAvailabilitysByCoursesOferID({
        "ClassroomID": id,
        "CourseOferID": this.courseOfferSelected,
      }).subscribe(association => {

      }, err => {
        this.authService.swalError("Error Creando Asociación", "Se produjo un error, detalles: " + err)
      })

    }


  }
  checkClassRoom(id: number) {
    var num = this.classRoomsByCourse.find(x => x.ClassroomID === id);
    if (num)
      return true
    else
      return false

  }


  getProviders(event: any) {
    var autosearch = event.target.value
    //LLAMO TODOS LOS PROVEEDORES POR EL ID CEL CENAC


  }

  autocompleListFormatter = (data: any) => {
    let CompleteName = $.grep([data.LastName1, data.LastName2, data.Name1, data.Name2], Boolean).join(" ");
    let html = `<b>Correo:</b> <i>${data.CedocEmail}</i> - ${CompleteName}, C.C. ${data.UserDocuments[0].Document} `;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }




}

