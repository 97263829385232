import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { UsersField } from '../../../models/usersInfo/users-fields';
import { Category } from '../../../models/Coursesinfo/category';
import { Course } from '../../../models/Coursesinfo/course';
import { School } from '../../../models/Coursesinfo/school';
import { TypeCourse } from '../../../models/Coursesinfo/typecourse';
import { AuthService } from '../../../models/Auth/auth.service';
import { CoursesService } from '../../../models/Coursesinfo/courses.service';
import { ProfileNavComponent } from '../../../components/profile-nav/profile-nav.component';
import { environment } from 'environments/environment';


@Component({
    selector: 'CourseAdmin',
    templateUrl: './courseadmin.component.html',
    styleUrls: ['./courseadmin.component.css']
})
export class CourseAdminComponent implements OnInit {

    courses: Course[];
    coursesBase: Course[];
    schools: School[]=[];
    courseinfo: Course;
    TypeCourseID: number = 0;
    courseBaseID: number = 0;
    user: string;
    SchoolID: number = 0;
    typecourses: TypeCourse[] = [];
    isok: boolean;
    DatosDisponibles: boolean = false;
    errorMessage: string = 'Si desea solicitar una cuenta, por favor diligencie el siguiente formulario';
    categoryID: number = 0;
    categorys: Category[];
    public PRINCIPAL_APP_NAME = environment.PRINCIPAL_APP_NAME;

    public SCHOOL_LOGO_DYNAMIC_SVG:any = (id:number) => environment.SCHOOL_LOGO_DYNAMIC_SVG(id);

    constructor(
        private authService: AuthService, 
        private coursesService: CoursesService,
         public profileNavComponent: ProfileNavComponent, 
         private router: Router
         ) {
        //SI NO HAY USUARIOS EN LOCAL STORAGE, LLEVAR A LOGIN
        if (this.authService.getCurrentUser() == null) {
            this.router.navigate(['/register']);
        } else {
            //DE LO CONTRARO CARGAR EL ID DEL USUARIO
            this.user = this.authService.getCurrentUser(); //devuelve el id del usuario
            //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
            if (Number.isInteger(parseInt(this.user))) {
                //CONSULTO EL PERFIL DEL USUARIO
                let validar_usuario = [false];

                for (let role of this.profileNavComponent.roles) {
                    if (role.name == "Inspector Escuela") {
                        validar_usuario = validar_usuario.concat([true])
                        this.SchoolID = role.SchoolID;
                        break
                    }
                    if (role.name == "admin") {
                        validar_usuario = validar_usuario.concat([true])
                        this.SchoolID = role.SchoolID;
                        break
                    }
                    if (role.name == "Bienestar") {
                        validar_usuario = validar_usuario.concat([true])
                        this.SchoolID = role.SchoolID;
                        break
                    }
                    if (role.name == "Directiva") {
                        validar_usuario = validar_usuario.concat([true])
                        this.SchoolID = role.SchoolID;
                        break
                    }
                }


                if (!validar_usuario.includes(true)) { this.router.navigate(['/']); }





                this.coursesService.getAllCoursesMayaBySchoolByTypeByBase(this.SchoolID, 0, this.courseBaseID).subscribe(p => { this.courses = p; this.DatosDisponibles = true });
                //this.coursesService.getAllTypeCourse().subscribe(p => this.typecourses = p);
                let filter = {include:{childrenSchools:"childrenSchools"}, where:{id:this.SchoolID} , order:"NameTSchool ASC"}
                
                this.coursesService.getSchools(JSON.stringify(filter)).subscribe(res=>{
                    
                    this.schools =  this.flattenArray(res);
                });
                
                //this.coursesService.getAllSchools().subscribe(p => {this.schools = p; console.log(" p ", p )});
                this.coursesService.getAllCategory().subscribe(p => this.categorys = p);
                this.coursesService.getAllCoursesMayaBase(this.SchoolID, 0).subscribe(p => { this.coursesBase = p });
                
                //FINALIZA CONTENIDO

            }
        }



    }
    flattenArray(arr) {
        return arr.map(item => {
            if (item.childrenSchools && item.childrenSchools.length > 0) {
                return [item, ...this.flattenArray(item.childrenSchools)];
            } else {
                return item;
            }
        }).flat();
    }
    ngOnInit() {
      // TODO document why this method 'ngOnInit' is empty
    

    }


     selectCategory(model: UsersField) {

        this.categoryID = model.CategoryID
        if (this.categoryID == 0) {
            model.TypeCourseID = 0
        }
        //this.coursesService.getAllCourseBySchoolAndTypeCourse(this.schoolID, model.TypeCourseID).subscribe(p => this.courses = p);
        this.coursesService.getAllTypeCourseByCategory(this.categoryID).subscribe(p => this.typecourses = p);
        this.coursesService.getAllCoursesMayaBySchoolByTypeByBase(this.SchoolID, model.TypeCourseID, this.courseBaseID).subscribe(p => this.courses = p);
        this.coursesService.getAllCoursesMayaBase(this.SchoolID, model.TypeCourseID).subscribe(p => { this.coursesBase = p });

    }

    selectSchool(model) {

        this.categoryID = model.CategoryID
        if (this.categoryID == 0) {
            model.TypeCourseID = 0
        }
        if(this.PRINCIPAL_APP_NAME != 'cedoc' || this.categoryID == undefined 
        || this.courseBaseID == undefined 
        || model.TypeCourseID== undefined){
            this.categoryID = 0
            this.courseBaseID = 0
            model.TypeCourseID = 0
        }
        //this.coursesService.getAllCourseBySchoolAndTypeCourse(this.schoolID, model.TypeCourseID).subscribe(p => this.courses = p);
        this.coursesService.getAllTypeCourseByCategory(this.categoryID).subscribe(p => this.typecourses = p);
        this.coursesService.getAllCoursesMayaBySchoolByTypeByBase(model.SchoolsID, 0, 0).subscribe(p => this.courses = p);
        this.coursesService.getAllCoursesMayaBase(this.SchoolID, 0).subscribe(p => { this.coursesBase = p });
        

    }

    selectTypeCourse(model) {
        this.coursesService.getAllCoursesMayaBySchoolByTypeByBase(this.SchoolID, model.TypeCourseID, this.courseBaseID).subscribe(p => this.courses = p);
        this.coursesService.getAllCoursesMayaBase(this.SchoolID, model.TypeCourseID).subscribe(p => { this.coursesBase = p });
    }

    selectCourseBase() {
        this.coursesService.getAllCoursesMayaBySchoolByTypeByBase(this.SchoolID, 0, this.courseBaseID).subscribe(p => this.courses = p);
    }

    selectNewCourse(course) {
        console.debug(course);
        this.router.navigate(['/admin/newcoursemallas/' + course.id]);
    }

    textTypeCourse(id: number) {
        let num = this.typecourses.find(x => x.id == id);
        if (num)
            return num.NameTypeCourse;
        return "Tipo de Curso"
    }

    colorSchool(idSchool: number) {
        let classes = {};

        switch (idSchool) {
            case 0: classes = { cat__info__item_CEDOC: true }; break;
            case 1: classes = { cat__info__item_CEMIL: true }; break;
            case 4: classes = { cat__info__item_EAS: true }; break;
            case 5: classes = { cat__info__item_ESINF: true }; break;
            case 6: classes = { cat__info__item_ESCAB: true }; break;
            case 7: classes = { cat__info__item_ESART: true }; break;
            case 8: classes = { cat__info__item_ESING: true }; break;
            case 9: classes = { cat__info__item_ESCOM: true }; break;
            case 10: classes = { cat__info__item_ESICI: true }; break;
            case 16: classes = { cat__info__item_ESAVE: true }; break;
            case 17: classes = { cat__info__item_ESLOG: true }; break;
            case 18: classes = { cat__info__item_ESCEQ: true }; break;
            case 19: classes = { cat__info__item_ESPOM: true }; break;
            case 20: classes = { cat__info__item_ESMAI: true }; break;
            case 21: classes = { cat__info__item_ESDIH: true }; break;
            case 22: classes = { cat__info__item_CEMAI: true }; break;
            case 23: classes = { cat__info__item_ESIDE: true }; break;
            case 24: classes = { cat__info__item_ESFES: true }; break;
            case 25: classes = { cat__info__item_CEDOC: true }; break;
            case 33: classes = { cat__info__item_ESMIC: true }; break;
            case 34: classes = { cat__info__item_EMSUB: true }; break;
            case 35: classes = { cat__info__item_ESPRO: true }; break;
            default: classes = { cat__info__item_ALL: true }; break;


        }
        return classes;
    }
}