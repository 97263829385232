import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Subscription, forkJoin, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { ParentLinkService } from "../../services/parent-link.service";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "parent-link",
  templateUrl: "./parent-link.component.html",
  styleUrls: ["./parent-link.component.css"],
})
export class ParentLinkComponent implements OnInit, OnDestroy {
  constructor(
    private _parentLinkService: ParentLinkService,
    @Inject(DOCUMENT) private document: Document
  ) {}
  @Output("onCloseModal") onCloseModal: EventEmitter<boolean> =
    new EventEmitter(false);

  public parentList: any[] = [];
  public studentList: any[] = [];

  public selectedParent: any = null;
  public prevSelectedParent: any = null;
  public studentsToLoad: any[] = [];

  public showParentDropdown: boolean = false;
  public showStudentDropdown: boolean = false;

  public cantLink: any[] = [];
  public linked: any[] = [];
  public isSaving: boolean = false;
  public isLoadingChildren: boolean = false;
  public isDeletingChildren: boolean = false;
  private _childrenSubscription: Subscription = new Subscription();
  ngOnInit() {
    this.document.body.style.overflow = "hidden";
  }

  //On select parent
  public onSelectParent(item: any) {
    this.cantLink = [];
    this.linked = [];
    this.studentsToLoad = [];
    this.prevSelectedParent = this.selectedParent;
    this.selectedParent = item;
    this.getChildren();
  }

  //On select student
  public onSelectStudent(item: any) {
    const student = this.studentsToLoad.find((e) => e.id === item.id);
    if (!student) {
      this.studentsToLoad.push(item);
      item.isSelected = true;
      return;
    }

    const clearStudents = this.studentsToLoad.filter((e) => e.id !== item.id);
    this.studentsToLoad = clearStudents;
    item.isSelected = false;
  }

  //On search student
  public onLoadStudents(students: any) {
    const filteredStudents = this.selectedParent
      ? students.filter((student) => student.id !== this.selectedParent.id)
      : students;

    this.studentList = filteredStudents.map((newStudent) => ({
      ...newStudent,
      isSelected: this.studentsToLoad.some(
        (toLoad) => toLoad.id === newStudent.id
      ),
    }));

    this.showStudentDropdown = this.studentList.length > 0;
  }

  //On search parent
  public onLoadParents(parents: any) {
    const parentList =
      this.studentsToLoad.length > 0
        ? parents.filter(
            (parent) =>
              !this.studentsToLoad.some((student) => student.id == parent.id)
          )
        : parents;

    this.parentList = parentList;
    this.showParentDropdown = this.parentList.length > 0;
  }

  //On input click
  public onInputClick(type: "parent" | "student") {
    if (type === "parent") {
      this.showParentDropdown = this.parentList.length > 0;
      return;
    }

    if (this.selectedParent) {
      const filteredStudents = this.studentList.filter(
        (student) => student.id !== this.selectedParent.id
      );

      if(this.prevSelectedParent && filteredStudents.length > 0){
        const prevSelectedParent = {
          ... this.prevSelectedParent,
          isSelected:false
        }

        const alreadyAdded = filteredStudents.some(
          (student) => student.id === prevSelectedParent.id
        );
        if(!alreadyAdded)
          filteredStudents.push(prevSelectedParent);
      }
      
      this.studentList = filteredStudents;
    }

    this.showStudentDropdown = this.studentList.length > 0;
  }

  //Delete student
  public deleteStudent(item: any) {
    if (this.isDeletingChildren || this.isSaving) return;

    const index = this.studentsToLoad.findIndex((e) => e.id === item.id);
    if (index !== -1) this.studentsToLoad.splice(index, 1);
    else this.studentsToLoad.push(item);

    const itemToDelete = this.studentList.find((e) => e.id === item.id);
    if (itemToDelete) itemToDelete.isSelected = false;

    if (!item.parentToStudentId) return;

    this.isDeletingChildren = true;
    this._parentLinkService
      .deleteParentToStudents(item.parentToStudentId)
      .subscribe({
        next: (resp) => {
          this.isDeletingChildren = false;
        },
        error: (err) => {
          this.isDeletingChildren = false;
          console.log(err);
        },
      });
  }

  //Save students
  public saveStudents() {
    if (
      this.isSaving ||
      !this.selectedParent ||
      this.studentsToLoad.length === 0 ||
      this.isDeletingChildren
    )
      return;

    this.isSaving = true;
    const studentsId = this.studentsToLoad.map((e) => e.id);

    const parentFilter = JSON.stringify({
      where: { StudentUserId: { inq: [...studentsId] } },
      include: [
        { parentUser: "UserDocuments" },
        { studentUser: "UserDocuments" },
      ],
    });

    //Find already linked student - parent
    this._parentLinkService
      .getParentToStudents(parentFilter)
      .pipe(
        switchMap((parentResponse) => {
          const newLinked = this.studentsToLoad.filter(
            (e) =>
              !parentResponse.some((linked) => linked.StudentUserId === e.id)
          );
          const alreadyLinked = this.studentsToLoad.filter((e) =>
            parentResponse.some((linked) => linked.StudentUserId === e.id)
          );

          alreadyLinked.forEach((student) => {
            const parent = parentResponse.find(
              (p) => student.id === p.StudentUserId
            );
            if (parent && !student.parentUser)
              student.parentUser = {
                id: parent.parentUser.id,
                Name1: parent.parentUser.Name1,
                Name2: parent.parentUser.Name2,
                LastName1: parent.parentUser.LastName1,
                LastName2: parent.parentUser.LastName2,
                Document:
                  parent.parentUser.UserDocuments &&
                  parent.parentUser.UserDocuments.length > 0
                    ? parent.parentUser.UserDocuments[0].Document
                    : null,
              };
          });

          const studentsObs = newLinked.map((student) => {
            const data = {
              ParentUserId: this.selectedParent.id,
              StudentUserId: student.id,
            };

            return this._parentLinkService.postParentToStudents(data);
          });

          const obs = studentsObs.length === 0 ? of([]) : forkJoin(studentsObs);

          //Create student - parent relation
          return obs.pipe(
            map((response) => ({ newLinked, alreadyLinked, response }))
          );
        })
      )
      .subscribe({
        next: ({ newLinked, alreadyLinked, response }) => {
          this.cantLink = [...alreadyLinked];
          this.linked = [...newLinked];

          this.isSaving = false;

          //Allows the user to be deleted after being added
          this.studentsToLoad.forEach((toAdd) => {
            const match = response.find(
              (added) => toAdd.id === added.StudentUserId
            );
            if (match) toAdd.parentToStudentId = match.id;
          });
        },
        error: (err) => {
          console.log(err);
          this.isSaving = false;
        },
      });
  }

  private getChildren() {
    this.isLoadingChildren = true;
    const filter = JSON.stringify({
      where: { ParentUserId: this.selectedParent.id },
      include: [
        { parentUser: "UserDocuments" },
        { studentUser: "UserDocuments" },
      ],
    });

    this._childrenSubscription = this._parentLinkService
      .getParentToStudents(filter)
      .subscribe({
        next: (resp) => {
          this.studentsToLoad = [
            ...resp.map(this.processStudentData.bind(this)),
          ];
        },
        error: (err) => {
          console.log(err);
        }, complete:() => this.isLoadingChildren = false
      });
  }

  private processStudentData(data: any) {
    const { studentUser, parentUser, id } = data;
    const result = {
      parentToStudentId: id,
      id: studentUser.id,
      Name1: studentUser.Name1,
      Name2: studentUser.Name2,
      LastName1: studentUser.LastName1,
      LastName2: studentUser.LastName2,
      Document:
        studentUser.UserDocuments && studentUser.UserDocuments.length > 0
          ? studentUser.UserDocuments[0].Document
          : null,

      parentUser: {
        id: parentUser.id,
        Name1: parentUser.Name1,
        Name2: parentUser.Name2,
        LastName1: parentUser.LastName1,
        LastName2: parentUser.LastName2,
        Document:
          parentUser.UserDocuments && parentUser.UserDocuments.length > 0
            ? parentUser.UserDocuments[0].Document
            : null,
      },
    };
    return result;
  }

  public closeModal() {
    this.onCloseModal.emit(false);
    this.document.body.style.overflow = "auto";
  }

  ngOnDestroy(): void {
    this._childrenSubscription.unsubscribe();
  }

  //Hide dropdown
  @HostListener("document:click", ["$event"])
  onDocumentClick(event: Event) {
    const targetElement = event.target as HTMLElement;

    if (
      !targetElement.closest(".dropdown") &&
      targetElement.id !== "parent-input"
    )
      this.showParentDropdown = false;

    if (
      !targetElement.closest(".dropdown") &&
      targetElement.id !== "student-input" &&
      targetElement.id !== "delete-student"
    )
      this.showStudentDropdown = false;
  }
}
