import { Component, OnInit, OnDestroy, AfterViewChecked, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LocalDataSource, ViewCell } from 'ng2-smart-table';
import { AttachmentService } from "../../../models/attachment/attachment.service";
import { School } from '../../../models/Coursesinfo/school';
import { Course } from '../../../models/Coursesinfo/course';
import { UsersField } from '../../../models/usersInfo/users-fields';
import { OapinfoService } from '../../../models/oapinfo/oapinfo.service';
import { UsersService } from '../../../models/usersInfo/users.service';
import { CoursesService } from '../../../models/Coursesinfo/courses.service';
import { ProfileNavComponent } from '../../../components/profile-nav/profile-nav.component';
import { ClassRoomInterface } from '../../../models/Coursesinfo/classrooms-inteface';
import { AuthService } from '../../../models/Auth/auth.service';
import { environment } from 'environments/environment';


var async = require("async");

declare var $: any;


export class Intermediate {
  public Disponible = 0;
  public classRoomType: object = {
    0: "Seleccionar Tipo de Salón",
  }
}



// INICIO COMPONENTE PARA EL NG SMART TABLE USUARIO
@Component({
  selector: 'button-view-clasrooms',
  template: `
  {{ renderValue }}
`
})
export class ButtonViewTypeClassRoomComponent implements ViewCell, OnInit, AfterViewChecked {
  constructor(
    public intermediate: Intermediate,
  ) { }
  renderValue: string;
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter()
  ngOnInit() {
    // console.debug("aca esta el render despues")
    // console.debug(this.intermediate.Professor)



    //this.renderValue = this.intermediate.classRoomType.find(x=>x === this.value);

    this.renderValue = this.intermediate.classRoomType[this.value];
    //console.log("this.value", this.value)
    //console.log("this.intermediate.classRoomType", this.intermediate.classRoomType )
    //console.log(" this.renderValue", this.renderValue)

  }
  ngAfterViewChecked() {
    this.renderValue = this.intermediate.classRoomType[this.value];
  }
  onClick() {
    this.save.emit(this.rowData);
  }
}
// FIN COMPONENTE PARA EL NG SMART TABLE USUARIO



@Component({
  selector: 'classrooms',
  templateUrl: './classrooms.html',
  styleUrls: ['./classrooms.css'],
  providers: [Intermediate, ButtonViewTypeClassRoomComponent, Intermediate]
})

export class ClassRooms implements OnInit, OnDestroy {

  schools: School[] = [];
  SchoolID: number;
  ScnoolAcronim = `${environment.PRINCIPAL_APP_NAME.toUpperCase()}`
  isAgrupacion: boolean = false
  classrooms: any[] = []
  classRoomField: ClassRoomInterface
  submitbuton: string = "Guardar"
  stateButton: boolean = false
  EnableTable: boolean = false
  collapseCards: boolean = true;
  course_by_school: Course[];
  student: UsersField = {
    CourseOferID: 0,


  };
  classromID = 0
  classRoomsByCourse: any[] = []
  roomsBySchool: any[] = []
  show: boolean = false
  courseOfferSelected: number = 0
  settings = {
    noDataMessage: "No hay datos disponibles",

    edit: {
      editButtonContent: '<i class="fa fa-pencil-square"></i>',
      saveButtonContent: '<i class="fa fa-floppy-o"></i>',
      cancelButtonContent: '<i class="fa fa-window-close"></i>',
      confirmSave: true,
    },
    /* delete: {
      deleteButtonContent: '<i class="fa fa-window-close"></i>',
      confirmDelete: true
    }, */

    actions: {
      add: false,
      delete: false,
      edit: true,
      columnTitle: "Acciones"
    },
    setPaging: {
      page: 1,
      perPage: 15,
      doEmit: true,
    },

    pager: {
      perPage: 15,
      doEmit: true
    },

    columns: {

      NameClassroom: {
        title: 'Nombre Salón',
        filter: true,
        editable: true,
      },
      TypeClassroomID: {
        title: 'Tipo de Salón',
        filter: false,
        type: 'custom',
        editor: {
          type: 'list',
          config: {
            list: [
            ],
          },
        },
        renderComponent: ButtonViewTypeClassRoomComponent,
        onComponentInitFunction(instance) {
          instance.save.subscribe(row => {
            console.debug("algo se edito")
            // this.saveDocenteDetails(row.id)
          });
        }
      },


      Chairs: {
        title: 'Numero de Sillas',
        filter: false,
        editable: true,
      }



    }
  };

  source: LocalDataSource;
  isContratacion: boolean = false;

  constructor(
    private authService: AuthService,
    private oapinfoService: OapinfoService,
    private usersService: UsersService,
    private coursesService: CoursesService,
    private attachmentService: AttachmentService,
    private route: ActivatedRoute,
    private router: Router,
    public profileNavComponent: ProfileNavComponent,
    public intermediate: Intermediate,
  ) {
    this.source = new LocalDataSource(); // cr
    this.coursesService.getAllSchools().subscribe(p => this.schools = p);

    for (let role of this.profileNavComponent.roles) {

      if (role.name == "admin") {
        this.SchoolID = role.SchoolID;
        this.ScnoolAcronim = role.SchoolAcronim;

      }
      if (role.name == "Agrupacion") {
        this.isAgrupacion = true
        this.ScnoolAcronim = role.SchoolAcronim;
        this.SchoolID = role.SchoolID;

      }

      if (role.name == "Contratación") {
        this.isContratacion = true
        this.ScnoolAcronim = role.SchoolAcronim;
        this.SchoolID = role.SchoolID;

      }
    }

    //CARGAR TODOS LOS SALONES POR ESCUELA
    this.coursesService.getAllClassRoomsBySchoolID(this.SchoolID).subscribe(rooms => {

      rooms = rooms.sort(function (a, b) {
        return a["NameClassroom"].localeCompare(b["NameClassroom"]);
      });

      this.roomsBySchool = rooms

      this.coursesService.getAllCoursesBySchool(this.SchoolID).subscribe(p => {
        this.course_by_school = p.sort(function (a, b) {
          // Ascending: first age less than the previous
          return a["NameCourseOfer"].localeCompare(b["NameCourseOfer"]);
        });

        console.debug(this.course_by_school)
      });


      //LUEGO CARGO TODOS LOS TIPOS DE SALONES
      this.coursesService.getAllTypeClassRooms().subscribe(p => {
        //CON LOS SALONES CARGADOS LOS MOSTRAMOS EN EL SELECT PARA AGREGAR SALONES
        this.classrooms = p
        var listViewTypeClassRoom = []
        var RenderViewTypeClassRoom = []

        //CREO UN ARREGLO CON TODOS LOS TIPOS DE SALONES PARA QUE SE VEAN EN EL SMART TABLE
        p.forEach(element => {
          listViewTypeClassRoom.push({
            //value: CompleteName.toUpperCase(),
            value: element.id,
            title: element.NameTypeClassroom,
          })

          RenderViewTypeClassRoom.push({
            //value: element.id,
            [element.id]: element.NameTypeClassroom,
          })

        });
        this.settings.columns.TypeClassroomID.editor.config.list = listViewTypeClassRoom  //SE CARGA LOS SALONES AL LIST
        this.source.empty() //LIMPIO LA TABLA 
        this.intermediate.classRoomType = {}
        //VOY A CARGAR CON OBJETOS EL classRoomType
        for (let i = 0; i < RenderViewTypeClassRoom.length; i++) {
          Object.assign(this.intermediate.classRoomType, { [listViewTypeClassRoom[i].value]: listViewTypeClassRoom[i].title })
        }
        //CUANDO TODO ESTA BIEN, SE CARGA AL SMART TABLE
        this.source.load(rooms)
        this.EnableTable = true  //SE HABILITA LA TABLA PARA QUE SE HAGA EL RENDERIZADO BIEN.
      });





    })




  }

  ngOnInit() {

    this.classRoomField = {

      id: null,
      SchoolID: this.SchoolID,
      NameClassroom: "",
      TypeClassroomID: 0,
      Chairs: null

    }
    this.setJquerry();

  }

  ngOnDestroy() {

  }






  colorSchool(idSchool: number) {
    let classes = {};

    switch (idSchool) {
      case 0: classes = { cat__info__item_ALL: true }; break;
      case 1: classes = { cat__info__item_CEMIL: true }; break;
      case 4: classes = { cat__info__item_EAS: true }; break;
      case 5: classes = { cat__info__item_ESINF: true }; break;
      case 6: classes = { cat__info__item_ESCAB: true }; break;
      case 7: classes = { cat__info__item_ESART: true }; break;
      case 8: classes = { cat__info__item_ESING: true }; break;
      case 9: classes = { cat__info__item_ESCOM: true }; break;
      case 10: classes = { cat__info__item_ESICI: true }; break;
      case 16: classes = { cat__info__item_ESAVE: true }; break;
      case 17: classes = { cat__info__item_ESLOG: true }; break;
      case 18: classes = { cat__info__item_ESCEQ: true }; break;
      case 19: classes = { cat__info__item_ESPOM: true }; break;
      case 20: classes = { cat__info__item_ESMAI: true }; break;
      case 21: classes = { cat__info__item_ESDIH: true }; break;
      case 22: classes = { cat__info__item_CEMAI: true }; break;
      case 23: classes = { cat__info__item_ESIDE: true }; break;
      case 24: classes = { cat__info__item_ESFES: true }; break;
      default: classes = { cat__info__item_ALL: true }; break;


    }
    return classes;
  }


  setJquerry() {

    $(function () {
      // card controls


      // header double click
      $('.cat__core__sortable .card-header').on('click', function () {
        $(this).closest('.card').toggleClass('cat__core__sortable__collapsed');

      });

    });

  }


  textSchools(id: number) {
    var num = this.schools.find(x => x.id == id);
    if (num)
      return num.NameTSchool;
    return "Nombre de Escuela"
  }



  createClassroom(data: ClassRoomInterface, valid) {

    if (valid) {
      this.authService.swalLoading("Guardando Salón")

      this.coursesService.patchClassRoom(data).subscribe(data => {

        this.authService.swalEndLoad()
        this.classRoomField = {

          id: null,
          SchoolID: this.SchoolID,
          NameClassroom: "",
          TypeClassroomID: 0,
          Chairs: null

        }
        this.coursesService.getAllClassRoomsBySchoolID(this.SchoolID).subscribe(rooms => {
          this.source.empty()
          this.source.load(rooms)
          this.roomsBySchool = rooms
        })



      }, err => {
        this.authService.swalError("Error al crear salón de clase", "No se pudo crear el salón, detalles " + err)
      })

    }
  }


  onDeleteConfirm(event) {
    if (window.confirm('Are you sure you want to delete?')) {
      this.authService.swalLoading("Borrando Salón")
      this.coursesService.deleteClassRoom(event.data.id).subscribe(deleted => {
        event.confirm.resolve();
        this.authService.swalEndLoad()
      })

    } else {
      event.confirm.reject();
    }

  }

  onEditConfirm(event) {
    this.authService.swalLoading("Acutualizando Salón")
    this.coursesService.patchClassRoom(event.newData).subscribe(rooms => {
      this.authService.swalEndLoad()
      event.confirm.resolve(event.newData);
    }, err => {
      this.authService.swalError("Error actualizando salón", "Hubo un error al actualizar el salón, detalles: " + err)
    })
  }

  callClassroomsAvailability(model) {
    this.courseOfferSelected = model.id
    this.authService.swalLoading("Cargando Salones para el curso seleccionado")
    console.log(model)
    this.coursesService.getAllClassroomAvailabilitiesByCourseOfferID(model.id).subscribe(roomsAva => {
      this.show = true
      this.classRoomsByCourse = roomsAva
      this.authService.swalEndLoad()


    })


  }

  asociateAllClassRooms(model) {
    this.authService.swalLoading("Asociando")
    console.log(model)

    var cx = this
    //PRIMERO BORRO TODAS LAS ASOCIACIONES DEL CURSEOFERID Y DEL CLASSROOMID
    async.forEachLimit(this.classRoomsByCourse, 3, function (classR, callback) {

      console.log(classR)
      cx.coursesService.DeleteClassroomAvailabilitysByID(classR.id).subscribe(del => {
        callback()
      }, err => {
        callback()
      })


    }, function (err) {

      //LUEGO CREO NUEVAMENRE LAS ASOCIACIONES
      async.forEachLimit(cx.roomsBySchool, 3, function (roomsSchool, callback) {

        console.log(roomsSchool)
        cx.coursesService.PatchClassroomAvailabilitysByCoursesOferID({
          "ClassroomID": roomsSchool.id,
          "CourseOferID": model.id,
        }).subscribe(del => {
          callback()
        }, err => {
          callback()
        })


      }, function (err) {

        console.log("SE ASOCIO TODO")
        //ACUTUALIZAR
        cx.callClassroomsAvailability(model)


      })




    })

  }

  changeCheckClassRoom(model, id) {
    console.log(model, id)
    var num = this.classRoomsByCourse.findIndex(x => x.ClassroomID === id);
    if (num >= 0) {
      this.authService.swalLoading("Realizando Modificación")
      this.coursesService.DeleteClassroomAvailabilitysByID(this.classRoomsByCourse[num].id).subscribe(del => {
        this.callClassroomsAvailability({ id: this.courseOfferSelected })
      }, err => {
        this.authService.swalError("Error Al Borrar Asociación", "Se produjo un error, detalles: " + err)

      })


    }
    else {
      this.authService.swalLoading("Realizando Modificación")
      // SE CREA LA ASOCIACION Y SE LE ENVIA AL ARRAY DE classRoomsByCourse
      this.coursesService.PatchClassroomAvailabilitysByCoursesOferID({
        "ClassroomID": id,
        "CourseOferID": this.courseOfferSelected,
      }).subscribe(association => {
        this.callClassroomsAvailability({ id: this.courseOfferSelected })
      }, err => {
        this.authService.swalError("Error Creando Asociación", "Se produjo un error, detalles: " + err)
      })

    }


  }
  checkClassRoom(id: number) {
    var num = this.classRoomsByCourse.find(x => x.ClassroomID === id);
    if (num)
      return true
    else
      return false

  }


}

