import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { AuthService } from '../../../models/Auth/auth.service';
import { AntiquityGpa } from '../../../models/oapinfo/antiquity-gpa';
import { CoursesService } from '../../../models/Coursesinfo/courses.service';
import { School } from '../../../models/Coursesinfo/school';
import { TypeCourse } from '../../../models/Coursesinfo/typecourse';
import { UsersField } from '../../../models/usersInfo/users-fields';
import { Course } from '../../../models/Coursesinfo/course';
import { OapinfoService } from '../../../models/oapinfo/oapinfo.service';
import { UsersService } from '../../../models/usersInfo/users.service';
import { ProfileNavComponent } from '../../../components/profile-nav/profile-nav.component';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'environments/environment';
import { BtnGoToAcademicRecord } from './smartTableComponents/btnAcademicRecord';

declare var $: any;


@Component({
  selector: 'Antiquity',
  templateUrl: './antiquity.component.html',
  styleUrls: ['./antiquity.component.css']

})

export class AntiquityComponent implements OnInit, OnDestroy {

  customOptions: OwlOptions = {
    loop: true,
    navSpeed: 700,
    nav: false,
    dots: false,
    margin: 10,
    responsive: {
      0: {
        items: 4
      },
      400: {
        items: 6
      },
      700: {
        items: 9
      },
      940: {
        items: 18
      }
    }
  }



  data: AntiquityGpa[];
  schools: School[];
  typecourses: TypeCourse[];
  schoolID: number = 0;
  courseofers: Course[];
  courseofer: Course;
  courseoferID: number = 0;
  typecourseID: number = 0;
  idUser: string;

  isDataReady = false;
  isSchoolReady = false;


  settingsAntiquityGPA = {
    noDataMessage: "No hay datos disponibles",
    actions: {
      add: false,
      delete: false,
      edit: false,
      columnTitle: "Acciones"
    },

    add: {
      addButtonContent: '',
      createButtonContent: '',
      cancelButtonContent: '',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '',
      saveButtonContent: '',
      cancelButtonContent: '',
      confirmSave: true

    },
    pager: {
      perPage: 15,
    },
    delete: {
      deleteButtonContent: '',
      confirmDelete: true
    },
    columns: {
      LastNames: {
        title: 'Apellido',
        filter: false,
        editable: false
      },
      Names: {
        title: 'Nombre',
        filter: false,
        editable: false
      },
      AcademicRecord: {
        title: "Historial Académico",
        filter: false,
        editable: false,
        type: "custom",
        renderComponent: BtnGoToAcademicRecord,
        width: "100px"
      },
      CedocEmail: {
        title: `${environment.PRINCIPAL_APP_NAME} Email`,
        filter: false,
        editable: false
      },
      GPA: {
        title: 'Nota',
        filter: false,
        editable: false
      },
      NameCourseOfer: {
        title: 'Nombre del Curso',
        filter: false,
        editable: false
      },
      NameCourseGroup: {
        title: 'Nombre del Grupo',
        filter: false,
        editable: false
      }
    }
  };

  sourceAntiquityGPA: LocalDataSource;

  constructor(
    private authService: AuthService,
    private oapinfoService: OapinfoService,
    private usersService: UsersService,
    private coursesService: CoursesService,
    private profileNavComponent: ProfileNavComponent,
    private router: Router,
  ) {


  }


  ngAfterViewInit(){
        //SI NO HAY USUARIOS EN LOCAL STORAGE, LLEVAR A LOGIN
        if (this.authService.getCurrentUser() == null) {
          this.router.navigate(['/register']);
        } else {
          //DE LO CONTRARO CARGAR EL ID DEL USUARIO
          this.idUser = this.authService.getCurrentUser(); //devuelve el id del usuario
          //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
          if (Number.isInteger(parseInt(this.idUser))) {
            //CONSULTO EL PERFIL DEL USUARIO
            var validar_usuario = [false];
    
            for (let role of this.profileNavComponent.roles) {
              if (role.name == "Inspector Escuela") {
                validar_usuario = validar_usuario.concat([true])
                this.schoolID = role.SchoolID;
                break
              }
              if (role.name == "admin") {
                validar_usuario = validar_usuario.concat([true])
                this.schoolID = role.SchoolID;
                break
              }
              if (role.name == "Registro y Control") {
                validar_usuario = validar_usuario.concat([true])
                this.schoolID = role.SchoolID;
                break
              }
              if (role.name == "Jefe") {
                validar_usuario = validar_usuario.concat([true])
                this.schoolID = role.SchoolID;
                break
              }
            }
    
    
            if (!validar_usuario.includes(true)) { this.router.navigate(['/']); }
    
            this.courseofer = {
              isActive:true,
              SchoolID: this.schoolID,
              CourseOferID: 0,
              TypeCourseID: 0,
    
            }
    
            this.coursesService.getAllSchoolByDepend(this.schoolID).subscribe(depend => {
              if (depend.length > 0) {
                this.schools = depend
    
                this.isSchoolReady = true
                this.isDataReady = true
                this.LoadData()
              } else {
                this.isDataReady = true
                this.LoadData()
    
              }
            })
          }
    
        }
  }



  setNewSchool(id) {
    this.schoolID = id
    this.isDataReady = true
    this.LoadData()
  }

  LoadData() {
    this.authService.swalLoading("Cargando Estudiantes...")

    this.coursesService.getAllCourseBySchoolAndTypeCourse(this.schoolID, 0).subscribe(p => {
      p = p.sort(function (a, b) {
        return a["NameCourseOfer"] == b["NameCourseOfer"] ? 0 : a["NameCourseOfer"] < b["NameCourseOfer"] ? -1 : 1;
      });
      this.courseofers = p
      this.authService.swalEndLoad()

    });
  }

  ngOnInit() {

    this.sourceAntiquityGPA = new LocalDataSource(); // create the source
    this.coursesService.getAllTypeCourse().subscribe(p => this.typecourses = p);
    
  }

  ngOnDestroy() {

  }




  selectTypeCourse(model: UsersField) {
    this.typecourseID = model.TypeCourseID
    this.coursesService.getAllCourseBySchoolAndTypeCourse(this.schoolID, model.TypeCourseID,model["isActive"]).subscribe(p => {
      p = p.sort(function (a, b) {
        return a["NameCourseOfer"] == b["NameCourseOfer"] ? 0 : a["NameCourseOfer"] < b["NameCourseOfer"] ? -1 : 1;
      });
      this.courseofers = p
    });

  }

  selectCourse(model: Course) {
    this.courseoferID = model.CourseOferID;
    this.oapinfoService.getAllGPAStudent(model.CourseOferID, 0).subscribe(p => {
      p = p.sort(function (a, b) {
        // Ascending: first age less than the previous
        return a["LastName1"] == b["LastName1"] ? 0 : a["LastName1"] < b["LastName1"] ? -1 : 1;
      });



      this.sourceAntiquityGPA.load(p);
    });

  }
  onSearch(query: string = '') {

    if (query == "") {
      this.sourceAntiquityGPA.setFilter([])
    } else {
      query = query.replace(/\s/g, '');

      this.sourceAntiquityGPA.setFilter([
        {
          field: 'CedocEmail',
          search: query
        }
      ], false);
    }


  }


}




