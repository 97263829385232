import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomFormsModule } from 'ng2-validation';
import { ReceiptsComponent } from './receipts/receipts.component';
import { OrderModule } from 'ngx-order-pipe';
import { PricesComponent} from './prices/prices.component';
import { Ng2AutoCompleteModule } from '../../../../node_modules/ng2-auto-complete';
import { Ng2SmartTableModule } from '../../../../node_modules/ng2-smart-table';
import { TextMaskModule } from '../../../../node_modules/angular2-text-mask';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ColombiaPipeMododule } from './../../components/profile-nav/profile-nav.component';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ng2-currency-mask/src/currency-mask.config";
import { UrlSaniter } from '../../models/pipes/saniter.pipe';
import { CertificateViewComponent } from './certificate-view/certificate-view.component';
import { CarouselModule } from 'ngx-owl-carousel-o';


export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  decimal: ",",
  precision: 0,
  prefix: "$ ",
  suffix: "",
  thousands: "."
};
export const routes: Routes = [
  { path: 'financiera/receipts', component: ReceiptsComponent },
  { path: 'financiera/prices', component: PricesComponent },
  { path: 'financiera/certificate-view', component: CertificateViewComponent },



];

@NgModule({
  imports: [
    CommonModule,
    CustomFormsModule,
    FormsModule,
    CarouselModule,
    RouterModule.forChild(routes),
    OrderModule,
    ReactiveFormsModule,
    Ng2AutoCompleteModule,
    Ng2SmartTableModule,
    TextMaskModule,
    PdfViewerModule,
    ColombiaPipeMododule,
    CurrencyMaskModule
  ],
  declarations: [
    ReceiptsComponent,
    PricesComponent,
    UrlSaniter,
    CertificateViewComponent,
  ],
  entryComponents: [],
  exports: [RouterModule],
  providers:[ { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }]


})
export class modFinanciera { }