import { TypeBiologicalSex } from "./type-biological-sex";
import { UserDocument } from "./userDocument";
import { ContactInfo } from "./contactInfo";
import { Role } from "./role";
import { UserCourse } from "./usercourse";

//By Dav1dko
import { ClassificationUser } from "./classification-user";

export class UsersField {
  constructor(
    public CedocEmail?: string,
    public id?: number,
    public Name1?: string,
    public Name2?: string,
    public Names?: string,
    public LastName1?: string,
    public LastName2?: string,
    public Photo?: string,
    public LastNames?: string,
    public IsMilitar?: boolean,
    public TimeCreate?: any,
    public IsTemporal?: boolean,
    public UserCourseGPA?: UserCourse,
    public UserDocuments?: UserDocument[],
    public ContactInfos?: ContactInfo[],
    public CurrentResidential?: string,
    public ResidentialCity?: string,
    
    
    //by Dav1dko

    public ClassificationUser?: ClassificationUser,
    public clsUser?: ClassificationUser,

    public UserID?: string,

    public count?: number,

    public Address?: string,
    public Document?: string,
    public TypeDocumentID?: number,
    public Email?: string,
    
    public OtanID?: number,
    public CedulaMil?: string,
    public Range?: string,
    public ForceID?: number,

    public Phone?: any,
    public CellPhone?: any,
    public EmergencyName?: string,
    public EmergencyPhone?: string,

    public MilitarInfos?: any[0],

    public roles?: Role[],

    public ProfesionalProfile?: any,
    public Description?: string,

    public IsStudent?: boolean,

    public IsAcepted?: boolean,

    public UserCourses?: any[0],
    public SchoolID?: number,
    public CourseOferID?: number,
    public JobOferID?: number,
    public SubjectOferID?: number,
    public TypeCourseID?: number,
    public CategoryID?: number,
    public CourseGroupID?: number
  ) {}

  static IsSamePerson(p1, p2) {
    return p1.CedulaMil === p2.CedulaMil;
  }
}
