import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ParentLinkService } from '../../services/parent-link.service';
import { Observable, fromEvent, of } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'user-search-input',
  templateUrl: './user-search-input.component.html',
  styleUrls: ['./user-search-input.component.css']
})
export class UserSearchInputComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchInput:ElementRef;
  @Output('result') result:EventEmitter<any[]> = new EventEmitter();
  @Output('onCLick') onCLick:EventEmitter<Event> = new EventEmitter();

  @Input('id') id:string = '';
  @Input('placeholder') placeholder:string = '';

  constructor(private _parentLinkService: ParentLinkService) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.searchUseQuery();
  }

  private searchUseQuery(){
    fromEvent<Event>(this.searchInput.nativeElement, 'keyup').pipe(
      map((event:Event) => {
        const value = (event.target as HTMLInputElement).value.trim().toLocaleLowerCase();
        return value;
      }),
      debounceTime(400),
      switchMap((value:string)=> {
        return value.length > 3 ? this.getUser(value) : of([])
      } )
    ).subscribe({
      next:(resp)=>{
        this.result.emit(resp);
      },error:((err)=>{
        console.log(err);
      })
    })
  }

  private getUser(value: string): Observable<any> {
    const filter = JSON.stringify({
      where: { or: [{ Document: { regexp: `/${value}/` } }] },
      limit: 4
    })
    return this._parentLinkService.getUser(filter)
  }
}
