import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from '../../../models/Auth/auth.service';
import { Course } from '../../../models/Coursesinfo/course';
import { School } from '../../../models/Coursesinfo/school';
import { TypeCourse } from '../../../models/Coursesinfo/typecourse';
import { UsersService } from '../../../models/usersInfo/users.service';
import { CoursesService } from '../../../models/Coursesinfo/courses.service';
import { ProfileNavComponent } from '../../../components/profile-nav/profile-nav.component';
import { Departament } from '../../../models/usersInfo/departament';
import { TypeTraining } from '../../../models/usersInfo/type-training';
import * as moment from 'moment';
import { environment } from 'environments/environment';

declare let $: any;


@Component({
    selector: 'CourseOfer',
    templateUrl: './courseofer.component.html',
    styleUrls: ['./coursemallas.component.css']
})
export class CourseOferComponent implements OnInit {
    public mask = [/[1-2]/, /\d/, /\d/, /\d/, '-', /[0-1]/, /\d/, '-', /[0-3]/, /\d/];
    isTrainning = false;
    courses: Course[];
    schools: School[] = [];
    user: string;
    typecourses: TypeCourse[] = [];
    isBienestar = false;
    departaments: Departament[] = [];
    typetrainings: TypeTraining[] = [];
    courseoferfield: Course = { CourseID: 0, };
    courseDepartament: Departament;
    submitbuton: string = 'Guardar';
    isok: boolean;
    IsActiveCourse: boolean = true;
    DatosDisponibles: boolean = true;
    SchoolID: number = 0;
    OferCourses: Course[] = [];
    stateButton: boolean = false
    serverTime: Date
    errorMessage: string = 'Si desea solicitar una cuenta, por favor diligencie el siguiente formulario';
    public DateMessage = ""

    constructor(private authService: AuthService, private usersService: UsersService, private coursesService: CoursesService, public profileNavComponent: ProfileNavComponent, private route: ActivatedRoute, private router: Router) {
        //GUARDO LA HORA DEL SERVIDOR
        this.authService.getTime().then(server2 => this.serverTime = server2.time);

        //SI NO HAY USUARIOS EN LOCAL STORAGE, LLEVAR A LOGIN
        if (this.authService.getCurrentUser() == null) {
            this.router.navigate(['/register']);
        } else {
            //DE LO CONTRARO CARGAR EL ID DEL USUARIO
            this.user = this.authService.getCurrentUser(); //devuelve el id del usuario

            //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
            if (Number.isInteger(parseInt(this.user))) {

                //CONSULTO EL PERFIL DEL USUARIO
                let isAuth = false;

                for (let role of this.profileNavComponent.roles) {
                    if (role.name == "Inspector Escuela") {
                        isAuth=true;
                        this.SchoolID = role.SchoolID;
                        break
                    }
                    if (role.name == "admin") {
                        isAuth=true;
                        this.SchoolID = role.SchoolID;
                        break
                    }
                    if (role.name == "Bienestar") {
                        isAuth=true;
                        this.SchoolID = role.SchoolID;
                        this.isBienestar = true;
                        break
                    }
                    if (role.name == "Directiva") {
                        isAuth=true;
                        this.SchoolID = role.SchoolID;
                        break
                    }
                    if (role.name == "Extensión Egresados") {
                        isAuth=true;
                        this.SchoolID = role.SchoolID;
                        break
                    }


                }
                if (!isAuth) { this.router.navigate(['/']); }

                this.coursesService.getAllDepartament().subscribe(p => this.departaments = p);

                this.coursesService.getAllTypeTraining().subscribe(p => this.typetrainings = p);

                this.coursesService.getAllTypeCourseWellness(this.isBienestar).subscribe(p => {
                    this.typecourses = p;
                    this.coursesService.getAllCoursesMayaBySchoolState(this.SchoolID, this.typecourses.map(t => t.id),  this.IsActiveCourse).subscribe(p => {

                        p = p.sort(function (a, b) {
                            // Ascending: first age less than the previous
                            return a["NameCourse"] == b["NameCourse"] ? 0 : a["NameCourse"] < b["NameCourse"] ? -1 : 1;
                        })


                        this.courses = p
                    });


                });

                this.coursesService.getAllSchools().subscribe(p => this.schools = p);

                //FINALIZA CONTENIDO
            }
        }
    }

    public SCHOOL_LOGO_DYNAMIC_SVG:any = (id:number) => environment.SCHOOL_LOGO_DYNAMIC_SVG(id);

    ngOnInit() {

        this.courseoferfield = {
            id: null,
            SchoolID: this.SchoolID,
            TypeCourseID: 2,
            Description: "",
            DepartamentID: 0,
            DurationTotal: null,
            IsMilitar: false,
            NoRegisterMiL: "NA",
            NoRegisterMinE: "NA",
            IsActive: true

        }
    }


    setJquerry() {

        $(function () {
            // card controls
            // header double click
            $('.cat__core__sortable .card-header').on('click', function () {
                $(this).closest('.card').toggleClass('cat__core__sortable__collapsed');
            });
        });
    }


    selectCourseBase(){
        this.coursesService.getAllCoursesMayaBySchoolState(this.SchoolID, [0], this.IsActiveCourse).subscribe(p => {

            p = p.sort(function (a, b) {
                // Ascending: first age less than the previous
                return a["NameCourse"] == b["NameCourse"] ? 0 : a["NameCourse"] < b["NameCourse"] ? -1 : 1;
            })


            this.courses = p
        });
    }
    
    callCourse(model: Course) {



        let num = this.courses.find(x => x.id == model.CourseID);
        this.courseoferfield = num;

        if (this.courseoferfield.TypeCourseID == 17 || this.courseoferfield.TypeCourseID == 18) this.isTrainning = true;
        else this.isTrainning = false;

        this.courseoferfield.NameCourseOfer = this.courseoferfield.NameCourse;
        this.courseoferfield.IsActive = model.IsActive;
        this.coursesService.getAllCoursesBySchoolNotFilter(this.SchoolID, model.CourseID).subscribe(
            p => {
                this.OferCourses = p;
                this.setJquerry();
            }
        )
    }

    saveCourseOfer(model: Course, isValid: boolean) {

        model.SchoolID = this.courseoferfield.SchoolID;
        model.TypeCourseID = this.courseoferfield.TypeCourseID;
        model.CourseID = this.courseoferfield.id;

        this.errorMessage = "Corrija los datos erróneos";
        if (model.Since > model.Until ) {
            this.submitbuton = "Error";
            isValid = false;
        }
        if (isValid) {
            this.stateButton = true
            this.errorMessage = "Validando ... ";
            this.submitbuton = "Validando ... ";

            this.coursesService.createCourseOfer(model).subscribe(
                p => {
                    this.authService.swalSuccess("Creado!", "Se Creó Correctamente la Oferta Académica.");
                    this.isok = true;
                    this.submitbuton = "Guardar";
                    this.callCourse(model);
                    this.stateButton = false
                },
                e => {
                    this.authService.swalError("Error!", "error");

                    this.errorMessage = e;
                    this.isok = false;
                    this.submitbuton = "Error";
                    this.stateButton = false
                });
        }
    }

    colorSchool(idSchool: number) {
        let classes = {};

        switch (idSchool) {
            case 0: classes = { cat__info__item_CEDOC: true }; break;
      case 1: classes = { cat__info__item_CEMIL: true }; break;
      case 4: classes = { cat__info__item_EAS: true }; break;
      case 5: classes = { cat__info__item_ESINF: true }; break;
      case 6: classes = { cat__info__item_ESCAB: true }; break;
      case 7: classes = { cat__info__item_ESART: true }; break;
      case 8: classes = { cat__info__item_ESING: true }; break;
      case 9: classes = { cat__info__item_ESCOM: true }; break;
      case 10: classes = { cat__info__item_ESICI: true }; break;
      case 16: classes = { cat__info__item_ESAVE: true }; break;
      case 17: classes = { cat__info__item_ESLOG: true }; break;
      case 18: classes = { cat__info__item_ESCEQ: true }; break;
      case 19: classes = { cat__info__item_ESPOM: true }; break;
      case 20: classes = { cat__info__item_ESMAI: true }; break;
      case 21: classes = { cat__info__item_ESDIH: true }; break;
      case 22: classes = { cat__info__item_CEMAI: true }; break;
      case 23: classes = { cat__info__item_ESIDE: true }; break;
      case 24: classes = { cat__info__item_ESFES: true }; break;
      case 25: classes = { cat__info__item_CEDOC: true }; break;
      case 33: classes = { cat__info__item_ESMIC: true }; break;
      case 34: classes = { cat__info__item_EMSUB: true }; break;
      case 35: classes = { cat__info__item_ESPRO: true }; break;
      default: classes = { cat__info__item_ALL: true }; break;
        }
        return classes;
    }

    textTypeCourse(id: number) {
        let num = this.typecourses.find(x => x.id == id);
        if (num)
            return num.NameTypeCourse;
        return "Tipo de Curso"
    }

    textSchools(id: number) {
        let num = this.schools.find(x => x.id == id);
        if (num)
            return num.NameTSchool;
        return "Nombre de Escuela"
    }

    ValidateDate(model) {

        let since = ""
        let until = ""
        let start = ""
        let end = ""

        if (model.Since) {
            model.Since = model.Since //PARA ACADEIMCINFO
            since = "INGRESO"
            until = "RETIRO"
            start = "INSCRIPCIÓN INICIO"
            end = "INSCRIPCIÓN FIN"

        }

        let ActualDate = new Date(this.serverTime) // preguntar, YO CREO QUE ES PARA LLAMAR LA FECHA Y GUARDAR EN F.START => BY davidko
        let InputDateSince = new Date(model.Since)//EL TEXTO INGRESADO SE CONVIERTE EN TIPO DATE
        let InputDateUntil = new Date(model.Until) //EL TEXTO INGRESADO SE CONVIERTE EN TIPO DATE
        let InputDateStart = new Date(model.Start) //EL TEXTO INGRESADO SE CONVIERTE EN TIPO DATE
        let InputDateEnd = new Date(model.End) //EL TEXTO INGRESADO SE CONVIERTE EN TIPO DATE
        let validateDateSince = moment(InputDateSince, "YYYY-MM-DD", true).isValid() //VALIDO SI ES FECHA
        let validateDateUntil = moment(InputDateUntil, "YYYY-MM-DD", true).isValid() //VALIDO SI ES FECHA
        let validateDateStart = moment(InputDateStart, "YYYY-MM-DD", true).isValid() //VALIDO SI ES FECHA
        let validateDateEnd = moment(InputDateEnd, "YYYY-MM-DD", true).isValid() //VALIDO SI ES FECHA
        //cuando indica que trabaja actualmente

        if (validateDateSince === false || validateDateUntil === false) { // || validateDateStart === false) En caso de que las 3 fechas sean requeridas al mismo tiempo
            this.DateMessage = "LAS DOS FECHAS DEBEN SER DILIGENCIADAS"
            // return false
        }
        else if (InputDateSince > InputDateUntil) { // || InputDateSince > InputDateStart)
            this.DateMessage = "LA FECHA DE INICIO ES MAYOR A LA FECHA FINAL"
            // this.DateMessage = "LA FECHA DE INICIO ES MAYOR A LA FECHA DE INSCRIPCIÓN"
        }
        else {
            this.DateMessage = ""
        }
    }
}