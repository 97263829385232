import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LocalDataSource, ViewCell } from 'ng2-smart-table';
import { School } from "../../../../models/Coursesinfo/school";
import { Module } from "../../../../models/Coursesinfo/module";
import { UsersService } from '../../../../models/usersInfo/users.service';
import { CoursesService } from '../../../../models/Coursesinfo/courses.service';
import { UsersField } from '../../../../models/usersInfo/users-fields';
import { Subject } from '../../../../models/Coursesinfo/subject';
import { Course } from '../../../../models/Coursesinfo/course';
import { AuthService } from '../../../../models/Auth/auth.service';
import { Role } from '../../../../models/usersInfo/role';
import { ProfileNavComponent } from '../../../../components/profile-nav/profile-nav.component';
import * as moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { forkJoin, of } from 'rxjs';
import { fail } from 'assert';

declare let jQuery: any;
declare let $: any;



// INICIO COMPONENTE PARA EL NG SMART TABLE
@Component({
  selector: 'button-view-courses',
  template: `
    <button (click)="onClick()"class="btn btn-info btn-block btn-squared">Inscribirse</button>
  `,
})
export class ButtonViewCoursesComponent implements ViewCell, OnInit {
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.save.emit(this.rowData);
  }
}

// FIN COMPONENTE PARA EL NG SMART TABLE






@Component({
  selector: 'professor-courses',
  templateUrl: './professor-courses.component.html',
  styleUrls: ['./professor-courses.component.css'],
  providers: [UsersService, CoursesService]
})
export class ProfessorCoursesComponent implements OnInit {
  isCalclated = false;
  user: string;
  student: UsersField;
  homologationSubjectID: number = 0;
  nameSubjectGroup: string = "";
  public mySchoolID: number;
  public CourseID;
  public Subject: Subject[];
  course_by_school: Course[];
  subjects_by_course: Course[];
  Studends_by_Course: any = {count: 0};
  public List_students_by_course: any[];
  public List_students_by_course2: any[];
  public Students: any[];
  public StudentsByGroup: any[];
  public ArrayStudentsByGroup: any;
  public Contador: number;
  public AreStudents: boolean;
  public AreGroups: boolean;
  public AreGroupsButton: boolean = false;
  public CalculatePressed: boolean = false
  state: number = 1;
  Results: any;
  public school: Course[];
  public ErrorGroup: string;
  public EnableAssociation: boolean = false;
  public NumberGroups = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26]
  public SplitStudents = 0;
  abecedario = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
  public CreateCSV: any;
  public ModelTemp: any;
  public GroupProperties: any = []
  public AreGroupsToCheck: boolean = false
  public editedGroups: boolean = false
  //inicia configuracion smart table
  public TotalStudents: number = 0
  modules: Module[];
  schools: School[];
  courses: Course[];

  subjectsCourse: Subject[] = []
  subjects: Course[];

  userid: string;

  areaID: number = 0;
  courseID: number = 0;

  newSubjectGroup: {
    SubjectID: number,
    UserID: number,
    MidTerms: 1,
    DateStart: string,
    DateEnd: string,
    NameSubjectGroup: string,
    CourseOferID: number
    HPM: number,
    IsPhysicalTest:boolean,
    IsSemestralize:boolean

  } = {
      SubjectID: null,
      UserID: null,
      MidTerms: 1,
      DateStart: "",
      DateEnd: "",
      NameSubjectGroup: "",
      CourseOferID: null,
      HPM: 1,
      IsPhysicalTest:false,
      IsSemestralize:false
    }

  settings = {
    noDataMessage: "No hay datos disponibles",
    hideSubHeader: true, // hide filter row
    edit: {
      editButtonContent: '<i class="fa fa-pencil-square"></i>',
      saveButtonContent: '<i class="fa fa-floppy-o"></i>',
      cancelButtonContent: '<i class="fa fa-window-close"></i>',
      confirmSave: true
    },
    actions: {
      add: false,
      delete: false,
      edit: false,
      columnTitle: "Acciones"
    },
    setPaging: {
      page: 1,
      perPage: 15,
      doEmit: true,
    },

    pager: {
      perPage: 15,
      doEmit: true
    },

    columns: {



      LastNames: {
        title: 'Apellidos',
        filter: false,
        editable: false,
      },

      Names: {
        title: 'Nombres',
        filter: false,
        editable: false,
      },
      Group: {
        title: 'Grupo',
        filter: false,
        editable: false,
      }




      /*
          id: {
            title: 'GRUPO',
            filter: false,
            type: 'custom',
            renderComponent: ButtonViewCoursesComponent,
            onComponentInitFunction(instance) {
                instance.save.subscribe(row => {
                   // this.saveDocenteDetails(row.id)
                });
              }
          }
      
          */
    }
  };

  source: LocalDataSource;
  //fin configuracion smart table



  //smart table para crear grupos

  settings2 = {
    noDataMessage: "No hay datos disponibles",

    edit: {
      editButtonContent: '<i class="fa fa-pencil-square"></i>',
      saveButtonContent: '<i class="fa fa-floppy-o"></i>',
      cancelButtonContent: '<i class="fa fa-window-close"></i>',
      confirmSave: true,
    },
    actions: {
      add: false,
      delete: false,
      edit: true,
      columnTitle: "Acciones"
    },
    setPaging: {
      page: 1,
      perPage: 26,
      doEmit: true,
    },

    pager: {
      perPage: 26,
      doEmit: true
    },

    columns: {
      GroupName: {
        title: 'Nombre Grupo',
        filter: false,
        editable: false,
      },

      People: {
        title: 'Personas Por Grupo',
        filter: false,
        editable: false,
      },


      StartDayTime: {
        title: 'Inicio de clases',
        filter: false,
        editable: true,
        class:['icon-i-clock','icon-header']
      },

      EndDayTime: {
        title: 'Fin de clases',
        filter: false,
        editable: true,
        class:['icon-i-clock','icon-header']
      }
    }
  };

  source2: LocalDataSource;

  //fin smarttable para crear grupo


  typeGroupCreation: {
    groups: boolean,
    homologationGroup: boolean
  } = {
      groups: false,
      homologationGroup: false
    }


  public canCreateGroups:boolean = false;
  
  constructor(private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    public profileNavComponent: ProfileNavComponent,
    public servicios: UsersService,
    public coursesService: CoursesService) {
    this.source = new LocalDataSource(); // create the source

    this.source2 = new LocalDataSource(); // create the source


  }

  //INDICO QUE LOS DATOS NO ESTAN DISPONIBLES AL CARGAR EL HTML PARA NO MOSTRAR ERRORES
  DatosDisponibles: boolean = false;


  // DAVID*****************************************************************************
  // DAVID*****************************************************************************
  davidTest(): void { /** TODO: DELETE */
    this.coursesService.getTestDavidData().subscribe(result => {
      console.log('David Test **************************');
      console.log('David Test **************************');
      console.log('CouseOffer',result);
      console.log('David Test **************************');
      console.log('David Test **************************');
    });
  }
  // DAVID*****************************************************************************
  // DAVID*****************************************************************************


  public showInscriptionFuncionality = false;
  ngOnInit() {
    //this.davidTest();
    if (this.authService.getCurrentUser() == null) {
      this.router.navigate(['/register']);
    } else {
      //DE LO CONTRARO CARGAR EL ID DEL USUARIO
      this.user = this.authService.getCurrentUser(); //devuelve el id del usuario
      //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
      if (Number.isInteger(parseInt(this.user))) {
        //console.log(this.authService.getCurrentUser());
        //CONSULTO EL PERFIL DEL USUARIO
        let validar_usuario = [false];


        console.log('============================');
        console.log('============================');
        console.log('ROLES', this.profileNavComponent.roles);
        console.log('============================');
        console.log('============================');
        
        for (let role of this.profileNavComponent.roles) {
          if (role.name == "admin") {
            console.log('SI ES ADMIN');
            this.showInscriptionFuncionality = true;
          }
          if (role.name == "Agrupacion") {
            validar_usuario = validar_usuario.concat([true])
            break
          }
        }


        if (validar_usuario.includes(true)) {

          this.user = this.authService.getCurrentUser(); //devuelve el id del usuario

          let roles: Role[];

          this.servicios.getRolesByUserID(parseInt(this.user)).subscribe(p => {
            roles = p;
            this.mySchoolID = roles[0].SchoolID
            console.debug("La escuela de este personaje es: " + roles[0].SchoolID)

            this.coursesService.getAllCoursesBySchool(roles[0].SchoolID).subscribe(p => {
              this.course_by_school = p.sort(function (a, b) {
                // Ascending: first age less than the previous
                return a["NameCourseOfer"].localeCompare(b["NameCourseOfer"]);
              })

            });


          });




          let state = 1;
          this.AreStudents = false;
          this.AreGroups = false;

          this.student = {
            Document: '',
            Name1: '',
            LastName1: '',
            CedulaMil: '',
            IsMilitar: true,
            SchoolID: 4,
            CourseOferID: 0,
            SubjectOferID: 0,
          }



          $(function () {

            //al iniciar ocultar el input de numero de grupos junto con el slider
            $("#SplitStudents").hide();
            $("#SliderStudents").hide();
            $("#SliderStudentsTitle").hide();


          });


        }
        else {

          this.router.navigate(['/user/my-account']);
        }


      }
    }


  }
  

  callSubjects() {
    this.typeGroupCreation.groups = false;
    this.typeGroupCreation.homologationGroup = false;
    this.ErrorGroup = "";

    this.AreGroupsToCheck = false;
    this.AreGroupsButton = false;
    this.isCalclated = false;
    this.AreGroups = false;

    this.typeGroupCreation.groups=false; 
    this.typeGroupCreation.homologationGroup = !this.typeGroupCreation.homologationGroup;

    let courseOfferSelected = this.course_by_school.find(x => x.id == this.student.CourseOferID)

    console.log("el curso es", courseOfferSelected)
    this.coursesService.getAllSubjectsByCourseBox(courseOfferSelected.CourseID).pipe(take(1)).subscribe(subjects => {
      this.subjectsCourse = subjects
    })
  }

  renameSubject() {
    console.log(this.homologationSubjectID)
    let subjectSelected = this.subjectsCourse.find(x => x.id == this.homologationSubjectID)
    this.nameSubjectGroup = "" + subjectSelected.NameSubject + " - (HOMOLOGACIÓN)";

      this.newSubjectGroup = {
        SubjectID: this.homologationSubjectID,
        UserID: null,
        MidTerms: 1,
        DateStart: moment().format("YYYY-MM-DD"),
        DateEnd: moment().add(1, 'month').format("YYYY-MM-DD"),
        NameSubjectGroup: this.nameSubjectGroup,
        CourseOferID: this.student.CourseOferID,
        HPM: 1,
        IsPhysicalTest:false,
        IsSemestralize:false
      }



  }


  createHomologation() {

    this.authService.swalLoading('Cargando...');
    this.coursesService.getSchoolByID(this.mySchoolID).pipe(take(1)).subscribe(shoolInfo => {

      let filter = {
        where: {
          CedocEmail: `inspeccionestudios.${shoolInfo.SchoolAcronim.toLocaleLowerCase()}@${environment.PRINCIPAL_APP_NAME}.edu.co`
        }
      }

      this.coursesService.getUserapps(JSON.stringify(filter)).pipe(take(1)).subscribe(user => {

        if (user[0].CedocEmail) {
          this.newSubjectGroup.UserID= user[0].id

          this.coursesService.patchSubjectGroups(this.newSubjectGroup).pipe(take(1)).subscribe(newSubject=>{

            this.authService.swalSuccess("Grupo creado","Se ha creado satisfactoriamente el grupo de homologación")


            this.newSubjectGroup = {
              SubjectID: null,
              UserID: null,
              MidTerms: 1,
              DateStart: moment().format("YYYY-MM-DD"),
              DateEnd: moment().add(1, 'month').format("YYYY-MM-DD"),
              NameSubjectGroup: '',
              CourseOferID: this.student.CourseOferID,
              HPM: 1,
              IsPhysicalTest:false,
              IsSemestralize:false
            }
            this.homologationSubjectID=0
            this.nameSubjectGroup=""
            this.typeGroupCreation.groups=false
            this.typeGroupCreation.homologationGroup=false




          },err=>{
            this.authService.swalEndLoad();
          })




        }
        else {
          this.authService.swalError("Error al asignar usuario", "No se encuentra el correo del inspector de estudios")
        }


      })




    })




    console.log("newSubjectGroup", this.newSubjectGroup)
  }

  SubjectChanged(model: Course) {


    //MUESTRO LAS OPCIONES PARA CREAR LOS GRUPOS
    $("#SplitStudents").show();
    $("#SliderStudents").show();
    $("#SliderStudentsTitle").show();
    //RECIBO EL NOMBRE DE LA MATERIA SELECCIONADA


    this.coursesService.getAllAcademicAreasBySchool(model.SubjectID).subscribe(p => {
      this.Subject = p;
    });


  }




  private getGroupsCount(id:number){
    return this.coursesService.getCountGroupsByCourseOferByID(id).pipe(
      catchError( err => {
        console.log(err);
        return of({count: 0 })
      })
    )
  }

  private getStudentsCount(id:number){
    return this.coursesService.getCountStudentsByOfer(id).pipe(
      catchError( err => {
        console.log(err);
        return of({count: 0 })
      })
    )
  }


  public onCheckGroups(){
    this.typeGroupCreation.groups = !this.typeGroupCreation.groups; 
    this.typeGroupCreation.homologationGroup=false;

    this.ErrorGroup = "";

    this.AreGroupsToCheck = false;
    this.AreGroupsButton = false;
    this.isCalclated = false;
    this.AreGroups = false;
  }

  callcourses(model: Course) {
    this.typeGroupCreation.groups = false;
    this.typeGroupCreation.homologationGroup = false;
    this.ErrorGroup = "";

    this.isCalclated = false;
    this.AreGroupsToCheck = false;
    this.AreGroups = false;
    this.AreGroupsButton = false;

    this.canCreateGroups = false;
    let hasGroups = true;
    let hasStudents = false;

    $("#SplitStudents").show();
    $("#SliderStudents").show();
    $("#SliderStudentsTitle").show();

    //BUSCO EL CURSO POR ID Y EXTRAIGO  CourseID
    this.coursesService.getCourseOferByID(model.id).pipe(
      switchMap( offer => {
        return forkJoin({
          groups: this.getGroupsCount(offer.id),
          students: this.getStudentsCount(offer.id)
        }).pipe( map(data => ({data, offer})) )
      })
    ).subscribe({
      next:({data,offer}) => {
        console.log(data);
        this.CourseID = offer;
        this.Studends_by_Course = data.students;

        if(data.groups.count > 0){
          this.authService.swalWaring("Ya existen grupos para este curso", "No se pueden generar automáticamente de nuevo.");
          return;
        }

        if(data.students.count === 0){
          this.authService.swalError("No hay estudiantes inscritos para esta oferta", "");
          return;
        }

        hasGroups = data.groups.count > 0;
        hasStudents = data.students.count > 0;

        this.canCreateGroups = (!hasGroups && hasStudents);
      }, error:(err)=>{
        console.log(err);
      }
    })



    //descargo la lista de estudiantes del curso (obtengo el id)

    this.coursesService.getStudentsByCourseOferByID(model.id).subscribe(p => {

      let temp = []
      for (let j = 0; j < p.length; j++) {

        let NombreCompleto = $.grep([p[j]["Name1"], p[j]["Name2"]], Boolean).join(" ");
        let ApellidoCompleto = $.grep([p[j]["LastName1"], p[j]["LastName2"]], Boolean).join(" ");
        let Apellidos = ApellidoCompleto.toUpperCase()
        temp[j] = p[j]
        temp[j]["FullLastNames"] = Apellidos

      }



      p = temp.sort(function (a, b) {
        // Ascending: first age less than the previous

        return a["FullLastNames"].localeCompare(b["FullLastNames"]);

      });



      this.List_students_by_course = []
      if (p.length == 0) {
        this.SplitStudents = 0
        this.AreGroupsButton = false
        $("#SplitStudents").prop("disabled", true);
      } else {

        for (let k = 0; k < p.length; k++) {

          if (p[k].UserCourses.length > 0) {

            this.List_students_by_course.push(p[k])

          }


        }

        console.debug("el numero de la lista son:" + this.List_students_by_course.length)


        this.AreStudents = true;


        console.debug("La lista de estudiantes son : ");
        console.debug(JSON.stringify(this.List_students_by_course));
        console.debug("####################");

        $("#SplitStudents").prop("disabled", false);

      }

    });



  }


  CheckPeople(model_input) {
    this.TotalStudents = 0;
    this.ErrorGroup = "";

    for (let index = 0; index < this.GroupProperties.length; index++) {


      this.TotalStudents = this.TotalStudents + parseInt(this.GroupProperties[index].People)

    }
    
    if (this.TotalStudents == parseInt(this.Studends_by_Course.count) ) {
      this.authService.swalSuccess("Grupos calculados", "Los grupos se han calculado según el número de alumnos seleccionados.")
      this.showListStudents(model_input)
      this.AreGroups = true
      this.AreGroupsButton = true
      this.editedGroups = true
      this.isCalclated = true
    }
    else {
      this.authService.swalError("Error calculando grupos", "El total de alumnos asignados a los grupos no coincide con el número de alumnos inscritos.")
      this.AreGroups = false
      this.AreGroupsButton = false;
    }


  }


  showListStudents(model) {
    this.AreGroups = false;
    this.AreGroupsButton = false;

    $("#SplitStudents").prop("disabled", true);


    console.debug(model);
    this.ModelTemp = model;
    this.coursesService.getStudentsByCourseOferByID(model.id).subscribe(p => {

      //this.List_students_by_course = p


      this.List_students_by_course = []
      for (let k = 0; k < p.length; k++) {

        if (p[k].UserCourses.length > 0) {

          this.List_students_by_course.push(p[k])

        }


      }



      if (this.List_students_by_course.length == 0) {

        this.SplitStudents = 0
        this.AreGroupsButton = false
        $("#SplitStudents").prop("disabled", true);
      }
      else {



        //this.AreGroups = true;
        //this.AreGroupsButton = true;

        this.AreGroupsToCheck = true

        $("#SplitStudents").prop("disabled", false);
      }

    });




    //console.log("la variable que quiero es: "+JSON.stringify(this.List_students_by_course));

    if (model.SplitStudents == "" || model.SplitStudents == "0")
      model.SplitStudents = 1;








    //console.log("se va a dividir en "+model.SplitStudents+" grupos");

    this.Results = Math.ceil(model.NumberStudents / model.SplitStudents);

    /*
        //LLENAR TABLA INDICANDO LOS NUMEROS DE GRUPOS CON EL NUMERO DE ESTUDIANTES
        if (this.editedGroups == false ) {
          let ayuda = new Array(model.NumberStudents).fill(0);
          this.GroupProperties = []
          for (let gi = 0; gi < model.SplitStudents; gi++) {
    
            //saber cuantos estudiantes hay por grupo
            let ayuda2 = ayuda.splice(0, this.Results);
    
            let NombreGrupo = this.textTypeCourse(this.ModelTemp.id) + ", GRUPO " + this.abecedario[gi]
    
            this.GroupProperties.push({
              Number: gi,
              GroupName: NombreGrupo,
              People: ayuda2.length
            })
    
          }
          this.source2.empty()
          this.source2.load(this.GroupProperties)
          this.TotalStudents = 0
    
          this.CalculatePressed=true
        }
    
        */




    //Creo el array de los grupos y le asocio los ids de los usuarios
    this.ArrayStudentsByGroup = [];

    for (let i = 1; i <= model.SplitStudents; i++) {

      //Hago splice para recoger el grupo de estudiantes segun la cantidad de grupos

      this.StudentsByGroup = this.List_students_by_course.splice(0, this.GroupProperties[i - 1].People);

      //this.StudentsByGroup = this.List_students_by_course.splice(0, this.Results);


      console.debug("para el grupo " + i + " le corresponden los ID");
      console.debug(JSON.stringify(this.StudentsByGroup));

      //a cada grupo le asocio la cantidad de estudiantes



      //SI EL GRUPO QUEDO SIN ESTUDIANTES, NO CREAR MAS GRUPOS de lo contrario asignar el grupo
      // if (this.StudentsByGroup.length === 0)
      //   console.log("EL GRUPO " + i + " quedo sin personas")
      // else
        this.ArrayStudentsByGroup[i - 1] = this.StudentsByGroup;

    }


    // this.source.load(this.ArrayStudentsByGroup);
    //console.log("Los grupos quedaron asi: "+JSON.stringify(this.ArrayStudentsByGroup));



    //convertir el array a un objeto

    let NuevoObjeto = [];
    //PRIMER FOR RECORRE N GRUPOS
    for (let i = 0; i < this.ArrayStudentsByGroup.length; i++) {
      //SEGUNDO FOR RECORRE LOS USUARIOS DEL GRUPO I
      if(this.ArrayStudentsByGroup[i].length ==0){
        let NombreGrupo = this.textTypeCourse(this.ModelTemp.id) + ", GRUPO " + this.abecedario[i]
        NuevoObjeto.push(
          {
            "Names": "",
            "LastNames": "",
            "Group": NombreGrupo
          }
        )
      }else{
        for (let j = 0; j < this.ArrayStudentsByGroup[i].length; j++) {
  
          let NombreCompleto = $.grep([this.ArrayStudentsByGroup[i][j].Name1, this.ArrayStudentsByGroup[i][j].Name2], Boolean).join(" ");
          let ApellidoCompleto = $.grep([this.ArrayStudentsByGroup[i][j].LastName1, this.ArrayStudentsByGroup[i][j].LastName2], Boolean).join(" ");
  
          console.debug("si se guadro en modeltem")
          console.debug(this.ModelTemp)
          let NombreGrupo = this.textTypeCourse(this.ModelTemp.id) + ", GRUPO " + this.abecedario[i]
          let Apellidos = ApellidoCompleto.toUpperCase()
          let Nombres = NombreCompleto.toUpperCase()
  
  
          NuevoObjeto.push(
            {
              "Names": Nombres,
              "LastNames": Apellidos,
              "Group": NombreGrupo
            }
          )
        }
    }



    }






    this.source.empty()
    /*
    this.settings.setPaging.page=1
    this.settings.setPaging.perPage = this.Results;
    this.settings.pager.perPage = this.Results;
    this.settings.pager.doEmit = true
    this.settings.setPaging.doEmit = true
    this.source.reset()
    this.source.refresh()
    */
    this.source.load(NuevoObjeto)
    this.CreateCSV = NuevoObjeto
    console.debug(NuevoObjeto)

  }








  calculatesplit(model) {
    this.SplitStudents = 0;
    this.AreGroups = false;
    this.AreGroupsButton = false;
    this.ErrorGroup = "";

    $("#SplitStudents").prop("disabled", true);


    this.ModelTemp = model;
    this.coursesService.getStudentsByCourseOferByID(model.id).subscribe(p => {

      //this.List_students_by_course = p


      this.List_students_by_course = []
      for (let k = 0; k < p.length; k++) {

        if (p[k].UserCourses.length > 0) {

          this.List_students_by_course.push(p[k])

        }


      }



      if (this.List_students_by_course.length == 0) {

        this.SplitStudents = 0
        this.AreGroupsButton = false
        $("#SplitStudents").prop("disabled", true);
      }
      else {

        //this.AreGroups = true;
        //this.AreGroupsButton = true;
        this.isCalclated = true
        this.AreGroupsToCheck = true
        $("#SplitStudents").prop("disabled", false);


      }

    });




    //console.log("la variable que quiero es: "+JSON.stringify(this.List_students_by_course));

    if (model.SplitStudents == "" || model.SplitStudents == "0")
      model.SplitStudents = 1;

    //console.log("se va a dividir en "+model.SplitStudents+" grupos");

    this.Results = Math.ceil(model.NumberStudents / model.SplitStudents);


    //LLENAR TABLA INDICANDO LOS NUMEROS DE GRUPOS CON EL NUMERO DE ESTUDIANTES

    let ayuda = new Array(model.NumberStudents).fill(0);
    this.GroupProperties = []
    for (let gi = 0; gi < model.SplitStudents; gi++) {

      //saber cuantos estudiantes hay por grupo
      let ayuda2 = ayuda.splice(0, this.Results);

      let NombreGrupo = this.textTypeCourse(this.ModelTemp.id) + ", GRUPO " + this.abecedario[gi]

      this.GroupProperties.push({
        Number: gi,
        GroupName: NombreGrupo,
        People: ayuda2.length,
        StartDayTime: "06:00",
        EndDayTime: "12:00",

      })

    }
    this.source2.empty()
    this.source2.load(this.GroupProperties)
    this.TotalStudents = 0





  }

  clicked() {
    console.log("se ha dado click")
    console.log("se ha dado click")
    console.log("se ha dado click")
  }

  GenerateGroup() {







    this.authService.swalLoading("Creando grupo...")


    console.debug("aun sigue el array disponible" + JSON.stringify(this.ArrayStudentsByGroup));

    console.debug("EL NUMERO DE GRUPOS FUERON?: " + this.ArrayStudentsByGroup.length)


    this.coursesService.getCountGroupsByCourseOferByID(this.CourseID.id).subscribe(p => {

      let Contador = p

      if (Contador["count"] > 0) {
        this.authService.swalWaring("Ya existen grupos para este curso", "No se pueden generar automáticamente de nuevo.")
        this.ErrorGroup = "";
      } else {

        for (let i = 0; i < this.ArrayStudentsByGroup.length; i++) {
          console.debug("Se va a crear el grupo " + this.abecedario[i] + " para el curso " + this.CourseID.id);

          this.source2.getAll().then(value => {


            this.coursesService.CreateCourseGroups(this.CourseID.id, value[i].GroupName, value[i].StartDayTime, value[i].EndDayTime).subscribe(p => { let ok = p });

          });


        }

        //this.EnableAssociation = true;
        this.AreGroupsButton = false;
        // new Angular2Csv(this.CreateCSV, 'My Report');

        //this.authService.swalSuccess("Paso 1: Grupos Creados", "Se Han Creado Los Grupos Correctamente, Ahora Asocie Los Estudiantes En El Paso 2 ")


        let cx = this;
        Swal({
          title: 'Grupos creados',
          text: 'Falta asociar estudiantes.',
          type: 'success',
          showCancelButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: 'Asociar estudiantes a los grupos',
        }).then(function () {
          cx.GenerateAssociation()
        }, function (dismiss) {
          if (dismiss == 'cancel') {
            console.log("se cancelo")
          }
        });






      }

    });




  }


  GenerateAssociation() {


    this.authService.swalLoading("Asociando estudiantes al grupo...")

    this.coursesService.getAllCourseGroupsByCourseOferByID(this.CourseID.id).subscribe(p => {

      let ListaGrupos = p.sort(function (a, b) {
        // Ascending: first age less than the previous
        return a.name == b.name ? 0 : a.name < b.name ? -1 : 1;
      });

      //console.debug(p)
      //console.debug(JSON.stringify(ListaGrupos))

      let totalStudents = 0
      let count = 1
      //RECORRO LOS GRUPOS
      for (let i = 0; i < this.ArrayStudentsByGroup.length; i++) {
        for (let j = 0; j < this.ArrayStudentsByGroup[i].length; j++) {
          totalStudents++
        }
      }

      console.log("toal estudiantes " + totalStudents)
      //RECORRO LOS GRUPOS
      for (let i = 0; i < this.ArrayStudentsByGroup.length; i++) {
        console.debug("SE VA A ACTUALIZAR EL GRUPO " + this.abecedario[i] + " que tiene un id guardado de " + ListaGrupos[i]["id"]);
        //RECORRO LOS ESTUDIANTES DE CADA GRUPO i
        for (let j = 0; j < this.ArrayStudentsByGroup[i].length; j++) {

          let idUsercourse = this.ArrayStudentsByGroup[i][j].UserCourses.find(x => x.CourseOferID == this.CourseID.id);

          let objeto = {

            "UserID": this.ArrayStudentsByGroup[i][j].id,
            "CourseOferID": this.CourseID.id,
            "CourseGroupID": ListaGrupos[i]["id"],
            "id": idUsercourse.id  //el usercource 0 no es el curso correcto  **fix

          }
          console.debug("EL ESTUDIANTE DE ESTE GRUPO ES: " + this.ArrayStudentsByGroup[i][j].LastName1 + " CON EL idUserCourses: " + idUsercourse.id)
          this.coursesService.UpdtateAssociationUserCourse(objeto).subscribe(p => {

            if (count < totalStudents) {
              count++
            } else {
              //this.EnableAssociation = false;
              this.AreGroupsButton = false;
              this.isCalclated = false;
              this.ErrorGroup = "Estudiantes asociados a grupos seleccionados correctamente."
              this.authService.swalSuccess("Estudiantes Asociados", "Estudiantes asociados a grupos seleccionados correctamente.")
            }
          });
        }
      }
    });
  }


  textTypeCourse(id: number) {

    let num = this.course_by_school.find(x => x.id == id);
    if (num)
      return num.NameCourseOfer;
    return "Tipo de Curso"
  }


  onEditConfirmGroups(event) {
    //SCRIPT PARA CONFIRMAR LA HORA DE LA TABLA
    let StartDayTime = event.newData.StartDayTime
    let EndDayTime = event.newData.EndDayTime
    if (moment(StartDayTime, "HH:mm", true).isValid() &&
      moment(EndDayTime, "HH:mm", true).isValid() &&
      moment(StartDayTime, "HH:mm").isBefore(moment(EndDayTime, "HH:mm"))) {
      event.confirm.resolve(event.newData);
    }
    else {
      this.authService.swalError("Error Editando Grupo", "Seleccione una hora correcta y que la hora final de clases sea mayor a la hora inicial")
    }



  }

  /** ******************** DAVID UPDATE ******************** */
  /** ******************** DAVID UPDATE ******************** */
  /** ******************** DAVID UPDATE ******************** */
  idOfertaSeleccionada: number = 0;
  datosOfertaSeleccionada: Course;
  idMateriaSeleccionada: number = 0;
  datosCourseGroupSeleccionado: Course;
  datosMateriaSeleccionada: Subject;
  datosSubjectGroupsSeleccionada: Subject;
  public typeView: number = 1;
  public errorDataSingle = {
    text: '',
    show: false
  }
  public resultDataSingle = {
    textFirst: '',
    first: false,
    textSecond: '',
    second: false,
    textThird: '',
    third: false
  }

  callAllCourses() {
    this.resultDataSingle = {
      textFirst: '',
      first: false,
      textSecond: '',
      second: false,
      textThird: '',
      third: false
    }
    console.log('idOfertaSeleccionada received:',this.idOfertaSeleccionada);
    console.log('course_by_school:',this.course_by_school);
    console.log('datosMateriaSeleccionada:',this.datosMateriaSeleccionada);
    this.coursesService.getCourseOferByIDWithCourseAndSubjects(this.idOfertaSeleccionada).subscribe(p => {
      this.datosOfertaSeleccionada = p;
      console.log('getCourseOferByID',p);
      console.log('this.datosOfertaSeleccionada',this.datosOfertaSeleccionada);
      // if (
      //   this.datosOfertaSeleccionada &&
      //   this.datosOfertaSeleccionada.course &&
      //   this.datosOfertaSeleccionada.course.Subjects &&
      //   this.datosOfertaSeleccionada.course.Subjects.length > 0
      // ) this.datosMateriaSeleccionada = this.datosOfertaSeleccionada.course.Subjects[0];
      this.resultDataSingle.textFirst = '';
      this.resultDataSingle.first = true;
    },
    err => {
      this.errorDataSingle = {
        text: 'Error cargando los datos de la oferta, intentelo de nuevo, si el problema persiste contacte a su administrador.',
        show: true
      }
      console.log('ERROR', err);
    });


    // //Con el id del curso busco la cantidad de usuarios en el curso
    // this.coursesService.getCountStudentsByOfer(model.id).subscribe(p => {

    //   this.Studends_by_Course = p;

    //   console.debug("el numero de estudiantes de ese curso es : " + JSON.stringify(this.Studends_by_Course["count"]));

    // },

    //   err => {
    //     this.Studends_by_Course = { count: "Para el curso seleccionado hay 0" }
    //     console.log("2 Studends_by_Course:", this.Studends_by_Course)
    //   }
    // );


    // //descargo la lista de estudiantes del curso (obtengo el id)

    // this.coursesService.getStudentsByCourseOferByID(model.id).subscribe(p => {

    //   let temp = []
    //   for (let j = 0; j < p.length; j++) {

    //     let NombreCompleto = $.grep([p[j]["Name1"], p[j]["Name2"]], Boolean).join(" ");
    //     let ApellidoCompleto = $.grep([p[j]["LastName1"], p[j]["LastName2"]], Boolean).join(" ");
    //     let Apellidos = ApellidoCompleto.toUpperCase()
    //     temp[j] = p[j]
    //     temp[j]["FullLastNames"] = Apellidos

    //   }



    //   p = temp.sort(function (a, b) {
    //     // Ascending: first age less than the previous

    //     return a["FullLastNames"].localeCompare(b["FullLastNames"]);

    //   });



    //   this.List_students_by_course = []
    //   if (p.length == 0) {
    //     this.SplitStudents = 0
    //     this.AreGroupsButton = false
    //     $("#SplitStudents").prop("disabled", true);
    //   } else {

    //     for (let k = 0; k < p.length; k++) {

    //       if (p[k].UserCourses.length > 0) {

    //         this.List_students_by_course.push(p[k])

    //       }


    //     }

    //     console.debug("el numero de la lista son:" + this.List_students_by_course.length)


    //     this.AreStudents = true;


    //     console.debug("La lista de estudiantes son : ");
    //     console.debug(JSON.stringify(this.List_students_by_course));
    //     console.debug("####################");

    //     $("#SplitStudents").prop("disabled", false);

    //   }

    // });



  }

  manageCourseGroupSelected(): void {
    console.log('datosCourseGroupSeleccionado',this.datosCourseGroupSeleccionado);
    this.datosMateriaSeleccionada = undefined;
  }

  manageSubjectSelected(): void {
    this.resultDataSingle.second = false;
    console.log('datosMateriaSeleccionada',this.datosMateriaSeleccionada);
    this.coursesService.getSubjectGroupByCourseGroupIDAndSubjectIDWithAllData( this.datosCourseGroupSeleccionado.id ,this.datosMateriaSeleccionada.id).subscribe(data => {
      console.log('getSubjectGroupByCourseGroupIDAndSubjectIDWithAllData',data);
      this.datosSubjectGroupsSeleccionada = data;
      this.resultDataSingle.second = true;
    },
    err => {
      this.errorDataSingle = {
        text: 'Error cargando los datos del grupo de la materia, intentelo de nuevo, si el problema persiste contacte a su administrador.',
        show: true
      }
      console.log('ERROR', err);
    });
  }

  createNewSubjectGroup(): void {
    this.resultDataSingle.second = false;
    console.log('createNewSubjectGroup');
    let nameSplited = this.datosCourseGroupSeleccionado.name.split(",");
    let newname = `${this.datosMateriaSeleccionada.NameSubject}, ${nameSplited[nameSplited.length - 1]}`;
    let calculatedMidTerms = 0; // Calculo de los MIDTERMS
    if (this.datosMateriaSeleccionada.HPM >= 0 && this.datosMateriaSeleccionada.HPM <= 10) calculatedMidTerms = 1
    else if (this.datosMateriaSeleccionada.HPM >= 11 && this.datosMateriaSeleccionada.HPM < 21) calculatedMidTerms = 2
    else calculatedMidTerms = 3
    let dataNewSubjectGroup = {
      CourseGroupID: this.datosCourseGroupSeleccionado.id,
      CourseOferID: this.datosOfertaSeleccionada.id,
      IsSemestralize: this.datosOfertaSeleccionada.IsSemestralize,
      SubjectID: this.datosMateriaSeleccionada.id,
      MidTerms: calculatedMidTerms,
      NameSubjectGroup: newname,
      DateStart: moment(this.datosCourseGroupSeleccionado.DateStart).format('YYYY-MM-DD'),
      DateEnd: moment(this.datosCourseGroupSeleccionado.DateEnd).format('YYYY-MM-DD'),
      UserID: null,
      HPM: this.datosMateriaSeleccionada.HPM,
      IsPhysicalTest: this.datosCourseGroupSeleccionado.IsPhysicalTest
    }
    this.coursesService.postSubjectGroup(dataNewSubjectGroup).subscribe(subjectGroupData => {
      console.log('postSubjectGroup',subjectGroupData);
      this.resultDataSingle.third = true;
    }, e => {
      this.errorDataSingle = {
        text: 'Error creando el grupo de la materia, intentelo de nuevo, si el problema persiste contacte a su administrador.',
        show: true
      }
      console.log('error',e);
    })
  }

  datosOfertaSeleccionadaMasiva: Course;
  public errorDataMasive = {
    text: '',
    errorSubjectList: [],
    show: false
  }
  public resultFinalDataMasive = {
    text: '',
    errorSubjectList: [],
    show: false
  }
  public resultDataMasive = {
    text: '',
    show: false
  }
  callAllCoursesMasive() {
    this.resultDataMasive = {
      text: '',
      show: false
    }
    this.errorDataMasive = {
      text: '',
      errorSubjectList: [],
      show: false
    }
    console.log('idOfertaSeleccionada received:',this.idOfertaSeleccionada);
    this.coursesService.getCourseOferByIDWithCourseAndSubjectsMasive(this.idOfertaSeleccionada).subscribe(p => {
      this.datosOfertaSeleccionadaMasiva = p;
      console.log('getCourseOferByIDWithCourseAndSubjectsMasive',p);
      console.log('this.datosOfertaSeleccionadaMasiva',this.datosOfertaSeleccionadaMasiva);
      this.validateCourseOfferEmpty();
    },
    err => {
      this.errorDataSingle = {
        text: 'Error cargando los datos de la oferta, intentelo de nuevo, si el problema persiste contacte a su administrador.',
        show: true
      }
      console.log('ERROR', err);
    });
  }

  showBtnToRegisterUsers = false;
  validateCourseOfferEmpty(): void {
    console.log('validateCourseOfferEmpty');
    this.resultDataMasive.text = '';
    this.errorDataMasive.errorSubjectList = [];
    this.resultDataMasive.show = false;
    let totalSubjects: number = this.datosOfertaSeleccionadaMasiva.course.Subjects.length;
    let isSubjectGroupsOkToRegisterUser = true;
    this.showBtnToRegisterUsers = false;
    this.datosOfertaSeleccionadaMasiva.coursegroups.forEach(courseGroupIterated => {
      if (courseGroupIterated.subjectGroups.length != 0 && courseGroupIterated.subjectGroups.length != totalSubjects) this.errorDataMasive.errorSubjectList.push(courseGroupIterated.name);
      if (isSubjectGroupsOkToRegisterUser && courseGroupIterated.subjectGroups.length != totalSubjects) isSubjectGroupsOkToRegisterUser = false;
    });
    if (this.errorDataMasive.errorSubjectList.length == 0) {
      if (isSubjectGroupsOkToRegisterUser) this.showBtnToRegisterUsers = true;
      else this.resultDataMasive.show = true;
    } else {
      this.errorDataMasive.text = 'Los siguientes grupos ya tienen al menos una materia creada, inscribalas las faltantes manualmente.';
      this.errorDataMasive.show = true;
    }
  }

  createAutomaticSubjectGroups(): void {
    console.log('createAutomaticSubjectGroups');
    this.datosOfertaSeleccionadaMasiva.coursegroups.forEach(courseGroupIterated => {
      let nameSplited = courseGroupIterated.name.split(",");
      this.datosOfertaSeleccionadaMasiva.course.Subjects.forEach(subjectIterated => {
        let newname = `${subjectIterated.NameSubject}, ${nameSplited[nameSplited.length - 1]}`;
        let calculatedMidTerms = 0; // Calculo de los MIDTERMS
        if (subjectIterated.HPM >= 0 && subjectIterated.HPM <= 10) calculatedMidTerms = 1
        else if (subjectIterated.HPM >= 11 && subjectIterated.HPM < 21) calculatedMidTerms = 2
        else calculatedMidTerms = 3
        let dataNewSubjectGroup = {
          CourseGroupID: courseGroupIterated.id,
          CourseOferID: this.datosOfertaSeleccionadaMasiva.id,
          IsSemestralize: this.datosOfertaSeleccionadaMasiva.IsSemestralize,
          SubjectID: subjectIterated.id,
          MidTerms: calculatedMidTerms,
          NameSubjectGroup: newname,
          DateStart: moment(courseGroupIterated.DateStart).format('YYYY-MM-DD'),
          DateEnd: moment(courseGroupIterated.DateEnd).format('YYYY-MM-DD'),
          UserID: null,
          HPM: subjectIterated.HPM,
          IsPhysicalTest: courseGroupIterated.IsPhysicalTest
        }
        this.resultDataMasive.show = false;
        this.coursesService.postSubjectGroup(dataNewSubjectGroup).subscribe(subjectGroupData => {
          console.log('postSubjectGroup AUTO',subjectGroupData);
          this.resultDataSingle.third = true;
        }, e => {
          this.errorDataMasive = {
            text: 'Error creando el grupo de la materia, intentelo de nuevo, si el problema persiste contacte a su administrador.',
            errorSubjectList: [],
            show: true
          }
          console.log('error',e);
        })
      });
    });
  }

  validateCourseOfferComplete(): void {
    console.log('validateCourseOfferComplete');
    this.resultDataMasive.text = '';
    this.resultDataMasive.show = false;
    let totalSubjects: number = this.datosOfertaSeleccionadaMasiva.course.Subjects.length;
    this.datosOfertaSeleccionadaMasiva.coursegroups.forEach(courseGroupIterated => {
      if (courseGroupIterated.subjectGroups.length != totalSubjects) this.errorDataMasive.errorSubjectList.push(courseGroupIterated.name);
    });
    if (this.errorDataMasive.errorSubjectList.length == 0) this.createUserSubject();
    else {
      this.errorDataMasive.text = 'Los siguientes grupos no tiene las materias completas, inscribalas manualmente.';
      this.errorDataMasive.show = true;
    }
  }

  createUserSubject(): void {
    console.log('createUserSubject');
    let cantidadDeEstudiantes = 0;
    let cantidadDeGrupos = 0;
    let cantidadDeEstudiantesPorGrupo = 0;

    cantidadDeEstudiantes = this.datosOfertaSeleccionadaMasiva.UserCourses.length;
    cantidadDeGrupos = this.datosOfertaSeleccionadaMasiva.coursegroups.length;
    cantidadDeEstudiantesPorGrupo = Math.floor(cantidadDeEstudiantes/cantidadDeGrupos);

    console.log(`Estudiantes: ${cantidadDeEstudiantes}, Grupos: ${cantidadDeGrupos}, Estudiantes por grupo: ${cantidadDeEstudiantesPorGrupo}`);
    


    this.datosOfertaSeleccionadaMasiva.coursegroups.forEach(courseGroupIterated => {
      courseGroupIterated.subjectGroups.forEach(subjectGroupIterated => {
        let data = {
          CourseGroupID: subjectGroupIterated.CourseGroupID,
          SubjectGroupID: subjectGroupIterated.id
        }
        this.coursesService.assignUserSubjectV2(data).pipe(take(1)).subscribe(ll => {
          console.log('assignUserSubject',ll);
        }, e => {
          this.errorDataMasive.text = 'Error generando inscripcion de usuarios';
          this.errorDataMasive.show = true;
        })
      });
  
    });


    // this.coursesService.assignUserSubject(dataNewSubjectGroup).subscribe(subjectGroupData => {
    //   console.log('postSubjectGroup AUTO',subjectGroupData);
    //   this.resultDataSingle.third = true;
    // }, e => {
    //   this.errorDataMasive = {
    //     text: 'Error creando el grupo de la materia, intentelo de nuevo, si el problema persiste contacte a su administrador.',
    //     errorSubjectList: [],
    //     show: true
    //   }
    //   console.log('error',e);
    // })




    
  }
}

