import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "app/models/Auth/auth.service";
import { Observable } from "rxjs";

@Injectable( {
  providedIn: 'root'
} )
export class ParentLinkService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
  }

  private baseUrl: string = this.authService.UrlAPI;


  headers(): HttpHeaders{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    });
    return headers;
  }

  getUser (filter?:string): Observable<any> {
    const filterData = filter ? `?filter=${filter}` : ''
    const users = this.http.get(`${this.baseUrl}/ViewUserInfos${filterData}`,{ headers: this.headers() });
    return users;
  }

  getParentToStudents(filter?:string):Observable<any>{
    const filterData = filter ? `?filter=${filter}` : ''
    return this.http.get(`${this.baseUrl}/ParentsToStudents${filterData}`,{ headers: this.headers() });
  }

  updateParentToStudents(id:number, data:any):Observable<any>{
    return this.http.patch(`${this.baseUrl}/ParentsToStudents/${id}`, data, { headers: this.headers() });
  }

  postParentToStudents(data:any):Observable<any>{
    return this.http.post(`${this.baseUrl}/ParentsToStudents`,data,{ headers: this.headers() });
  }

  deleteParentToStudents(id:number):Observable<any>{
    return this.http.delete(`${this.baseUrl}/ParentsToStudents/${id}`,{ headers: this.headers() });
  }
}