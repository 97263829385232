import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { APP_BASE_HREF } from '@angular/common';
import { modAdmin } from './structure/modAdmin/modadmin.module';
import { modAgrupacion } from './structure/modAgrupacion/modagrupacion.module';
import { modFinanciera } from './structure/modFinanciera/modfinanciera.module';
import { StudentFinalComponent } from './structure/modAdmin/students-final/student-final.component';

const routes: Routes = [
  {
    path: '**',
    component: EmptyRouteComponent
  }

];

@NgModule( {
  imports: [
    RouterModule.forRoot( routes ),
    modAdmin,
    modAgrupacion,
    modFinanciera
  ],
  exports: [
    RouterModule,
    modAdmin,
    modAgrupacion,
    modFinanciera
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' }
  ]
} )
export class AppRoutingModule { }
