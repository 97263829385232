import { Component, OnInit} from '@angular/core';
import { AdminUserComponent } from '../admin-user/admin-user.component';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsersService } from 'app/models/usersInfo/users.service';
import { TypeDocument } from 'app/models/usersInfo/typedocument';
import swal from 'sweetalert2';

@Component({
  selector: 'admon360-create-user-admin-modal',
  templateUrl: './create-user-admin-modal.component.html',
  styleUrls: ['./create-user-admin-modal.component.css']
})
export class CreateUserAdminModalComponent implements OnInit {

  documentTypeList: TypeDocument[] = [];
  responseData = {

  }
  constructor(
    private modal:AdminUserComponent,
    private builder: FormBuilder,
    private usersServices: UsersService
    ) { 
      this.usersServices.getAllTypeDocuments().subscribe(p => {
        console.log("Type Documents", p)
        this.documentTypeList = p;
      });
    }

  ngOnInit() {
  }

  closeModal(){

  this.modal.closeModalEvent.emit(false)
  }

  createUser(){
    console.log("data", this.createAdminForm.valid)
    console.log("data", this.createAdminForm.value)
    console.log("data", this.createAdminForm.errors)
    if(this.createAdminForm.invalid){
      console.log("formulario invalido");
      this.createAdminForm.get("name").markAsDirty();
      this.createAdminForm.get("name").markAsTouched();
      this.createAdminForm.get("lastName").markAsDirty();
      this.createAdminForm.get("lastName").markAsTouched();
      this.createAdminForm.get("institutionalEmail").markAsDirty();
      this.createAdminForm.get("institutionalEmail").markAsTouched();
      return;
    }

    let userData = {
      CedocEmail: this.createAdminForm.get('institutionalEmail').value,
      email: this.createAdminForm.get('institutionalEmail').value,
      Name1: this.createAdminForm.get('name').value,
      LastName1: this.createAdminForm.get('lastName').value,
      password: "123456789",
      IsMilitar: 0
    }
    this.usersServices.CreateAdminUser(userData).subscribe(
      (p) => {
        swal(
          "Creado",
          "Se creó correctamente el usuario.",
          "success"
        );
        console.log('usuario creado', p);
        this.resetForm();
      },
      (e) => {
        swal(`Error`, `${e}`, `error`);
        console.log('error creando usuario', e)
      }
    );
  }

  public createAdminForm: FormGroup = this.builder.group({
    name:["", [Validators.required, Validators.minLength(3)]],
    lastName:["", [Validators.required, Validators.minLength(3)]],
    institutionalEmail:["", Validators.required],
  });

  resetForm(){
    this.createAdminForm.reset();
  }
}
