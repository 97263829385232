import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { Routes, RouterModule }  from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { TextMaskModule } from 'angular2-text-mask';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { StudentFinalComponent, AccordingViewProfessor } from './student-final.component';
import { ColombiaPipeMododule } from '../../../components/profile-nav/profile-nav.component';
import { CarouselModule } from 'ngx-owl-carousel-o';

export const routes: Routes = [
    { path: 'admin/studentfinal', component: StudentFinalComponent }
  ];

  @NgModule({
    
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        HttpModule,
        Ng2SmartTableModule,
        TextMaskModule,
        ColombiaPipeMododule,
        CarouselModule
         
      ],
      entryComponents: [
        AccordingViewProfessor,
      ],
      exports: [RouterModule],
      declarations: [
        StudentFinalComponent,
        AccordingViewProfessor
      ]

})
export class StudentFinalModule { }