import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import { SubjectGroup } from '../../../models/usersInfo/subjectgroup';
import { UsersField } from '../../../models/usersInfo/users-fields';
import { CoursesService } from '../../../models/Coursesinfo/courses.service';
import { Course } from '../../../models/Coursesinfo/course';
import { School } from '../../../models/Coursesinfo/school';
import { TypeCourse } from '../../../models/Coursesinfo/typecourse';
import { Module } from '../../../models/Coursesinfo/module';
import { IsAcepted } from '../../../models/Coursesinfo/is-acepted';
import { OapinfoService } from '../../../models/oapinfo/oapinfo.service';
import { AuthService } from '../../../models/Auth/auth.service';
import { ProfileNavComponent } from '../../../components/profile-nav/profile-nav.component';
import { UsersService } from '../../../models/usersInfo/users.service';
import { AccordingView } from '../../../components/according-view/according-view.component';
import { AngularCsv } from 'angular7-csv';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'CvListTeacher',
  templateUrl: './cv-list-teacher.component.html',
  styleUrls: ['./cv-list-teacher.component.css']

})

export class CvListTeacherComponent implements OnInit, OnDestroy {

  customOptions: OwlOptions = {
    loop: true,
    navSpeed: 700,
    nav: false,
    dots: false,
    margin: 10,
    responsive: {
      0: {
        items: 3
      },
      400: {
        items: 6
      },
      740: {
        items: 10
      },
      940: {
        items: 13
      }
    }
  }

  data: SubjectGroup[];
  typecourseID: number = 0;
  schoolID: number = 0;
  courseID: number = 0;
  courses: Course[];
  user: UsersField;
  course: Course;
  schools: School[] = [];
  schoolss: School[] = [];
  typecourses: TypeCourse[];
  areaID: number = 0;
  areas: Course[];
  modules: Module[];
  subjectID: number = 0;
  subjects: Course[];
  idUser: string;
  isAdmin = false;
  isDataReady = false;
  isSchoolReady = false;


  settingsSubjectGroup = {
    noDataMessage: "No hay datos disponibles",
    actions: {
      add: false,
      delete: false,
      edit: true,
      columnTitle: "Acciones"
    },

    add: {
      addButtonContent: '',
      createButtonContent: '',
      cancelButtonContent: '',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '<i class="fa fa-pencil-square"></i>',
      saveButtonContent: '<i class="fa fa-floppy-o"></i>',
      cancelButtonContent: '<i class="fa fa-window-close"></i>',
      confirmSave: true
    },
    pager: {
      perPage: 20,
    },
    delete: {
      deleteButtonContent: '',
      confirmDelete: true
    },
    columns: {

      isAcepted: {
        title: 'Aceptado',
        filter: false,
        editor: {
          type: 'list',
          config: {
            list: [{ value: true, title: 'Si' }, { value: false, title: 'No' }],
          }
        },
        type: 'custom',
        renderComponent: AccordingView,
        onComponentInitFunction(instance) {
          instance.save.subscribe(row => {
            console.log("algo se edito")
            // this.saveDocenteDetails(row.id)
          });
        }
      },

      isSuper: {
        title: 'SuperDocente',
        filter: false,
        editor: {
          type: 'list',
          config: {
            list: [{ value: true, title: 'Si' }, { value: false, title: 'No' }],
          }
        },
        type: 'custom',
        renderComponent: AccordingView,
        onComponentInitFunction(instance) {
          instance.save.subscribe(row => {
            console.log("algo se edito")
            // this.saveDocenteDetails(row.id)
          });
        }
      },

      LastNames: {
        title: 'Apellidos',
        filter: false,
        editable: false,
      },
      Names: {
        title: 'Nombres',
        filter: false,
        editable: false,
      },
      NameTSchool: {
        title: 'Escuela',
        filter: false,
        editable: false,
      },

      NameCourse: {
        title: 'Curso Inscrito',
        filter: false,
        editable: false
      },
      
      NameSubject: {
        title: 'Nombre Materia',
        filter: false,
        editable: false,
      },
      HPM: {
        title: 'Intensidad Horaria',
        filter: false,
        editable: false,
      },
      CedocEmail: {
        title: 'Hoja De Vida',
        filter: false,
        editable: false,
        type: 'html',
      },
      Document: {
        title: 'Cédula',
        filter: false,
        editable: false,
      },
      CedulaMil: {
        title: 'Cedula Militar',
        filter: false,
        editable: false,
      },
      CodeClassRoom: {
        title: 'Código ClassRoom',
        filter: false,
        editable: false,
      },
      CodeBlackBoard: {
        title: 'Código BlackBoard',
        filter: false,
        editable: false,
      }
    }
  };

  sourceSubjectGroup: LocalDataSource;

  source: LocalDataSource;
  isContratacion: boolean = false;

  constructor(public profileNavComponent: ProfileNavComponent,
    private authService: AuthService,
    private usersServices: UsersService,
    private coursesService: CoursesService,
    private route: ActivatedRoute,
    private router: Router,
    private oapinfoService: OapinfoService) {
    this.source = new LocalDataSource(); // create the source

    //SI NO HAY USUARIOS EN LOCAL STORAGE, LLEVAR A LOGIN
    if (this.authService.getCurrentUser() == null) {
      this.router.navigate(['/register']);
    } else {
      //DE LO CONTRARO CARGAR EL ID DEL USUARIO
      this.idUser = this.authService.getCurrentUser(); //devuelve el id del usuario
      //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
      if (Number.isInteger(parseInt(this.idUser))) {
        //CONSULTO EL PERFIL DEL USUARIO
        var validar_usuario = [false];

        for (let role of this.profileNavComponent.roles) {
          if (role.name == "Inspector Escuela") {
            validar_usuario = validar_usuario.concat([true])
            this.schoolID = role.SchoolID;
            break
          }
          if (role.name == "admin") {
            validar_usuario = validar_usuario.concat([true])
            this.schoolID = role.SchoolID;
            this.isAdmin = true;
            break
          }
          if (role.name == "Horarios") {
            validar_usuario = validar_usuario.concat([true])
            this.schoolID = role.SchoolID;
            break
          }
          if (role.name == "Jefe") {
            validar_usuario = validar_usuario.concat([true])
            this.schoolID = role.SchoolID;
            break
          }
          if (role.name == "Calidad") {
            validar_usuario = validar_usuario.concat([true])
            this.schoolID = role.SchoolID;
            break
          }
          if (role.name == "Contratación") {
            this.isContratacion = true
            this.schoolID = role.SchoolID;

          }
        }


        if (!validar_usuario.includes(true)) { this.router.navigate(['/']); }
        this.course = {
          SchoolID: this.schoolID,
          CourseOferID: 0,
          TypeCourseID: 0,
          CourseID: 0,
          JobOferID: 0,
          SubjectID: 0,
        }

        this.coursesService.getAllSchoolByDepend(this.schoolID).subscribe(depend => {
          if (depend.length > 0) {
            this.schools = depend
            this.isSchoolReady = true
            this.isDataReady = true
            this.LoadData()
          } else {
            this.isDataReady = true
            this.LoadData()

          }
        })


        //FINALIZA CONTENIDO

      }
    }
  }

  setNewSchool(id) {
    this.schoolID = id
    this.isDataReady = true
    this.LoadData()
  }

  LoadData() {
    this.authService.swalLoading("Cargando Docentes...")



    this.usersServices.getAllListOAPDs(this.schoolID, this.typecourseID, this.courseID, this.areaID, this.subjectID).subscribe(p => {
      this.sourceSubjectGroup.load(p);
      this.authService.swalEndLoad()
    });
  }

  ngOnInit() {


    this.sourceSubjectGroup = new LocalDataSource(); // create the source
    this.coursesService.getSchoolByID(this.schoolID).subscribe(p => this.schoolss = [p]);
    this.coursesService.getAllTypeCourse().subscribe(p => this.typecourses = p);
    this.coursesService.getAllAcademicAreasBySchool(this.schoolID).subscribe(p => this.modules = p);
    this.coursesService.getAllCoursesMayaBySchoolAndTypeCourses(this.schoolID, 0).subscribe(p => this.courses = p);

  }

  selectSchool(model: UsersField) {
    this.schoolID = model.SchoolID;
    this.coursesService.getAllCoursesMayaBySchoolAndTypeCourses(model.SchoolID, this.typecourseID).subscribe(p => this.courses = p);
    this.coursesService.getAllAcademicAreasBySchool(model.SchoolID).subscribe(p => this.modules = p);
    this.usersServices.getAllListOAPDs(this.schoolID, this.typecourseID, this.courseID, this.areaID, this.subjectID).subscribe(p => {
      this.sourceSubjectGroup.load(p);
    });
  }

  selectTypeCourse(model: UsersField) {
    this.typecourseID = model.TypeCourseID
    this.coursesService.getAllCoursesMayaBySchoolAndTypeCourses(this.schoolID, model.TypeCourseID).subscribe(p => {
      this.courses = p
    });
    this.usersServices.getAllListOAPDs(this.schoolID, this.typecourseID, this.courseID, this.areaID, this.subjectID).subscribe(p => {
      this.sourceSubjectGroup.load(p);
    });
  }

  selectCourse(model: Course) {
    this.courseID = model.CourseID;
    if (this.courseID != 0) {
      this.coursesService.getAllSubjectsByAreaByCourse(this.areaID, this.courseID).subscribe(p => {
        this.subjects = p
      });
    }
    this.usersServices.getAllListOAPDs(this.schoolID, this.typecourseID, this.courseID, this.areaID, this.subjectID).subscribe(p => {
      this.sourceSubjectGroup.load(p);
    });
  }

  selectArea(model: Course) {
    this.areaID = model.JobOferID;
    if (this.courseID != 0) {
      this.coursesService.getAllSubjectsByAreaByCourse(this.areaID, this.courseID).subscribe(p => {
        this.subjects = p
      });
    }
    this.usersServices.getAllListOAPDs(this.schoolID, this.typecourseID, this.courseID, this.areaID, this.subjectID).subscribe(p => {
      this.sourceSubjectGroup.load(p);
    });
  }

  selectSubject(model: Course) {
    this.subjectID = model.SubjectID;
    this.usersServices.getAllListOAPDs(this.schoolID, this.typecourseID, this.courseID, this.areaID, this.subjectID).subscribe(p => {
      this.sourceSubjectGroup.load(p);
    });
  }

  ngOnDestroy() {

  }




  onSearch(query: string = '') {
    if (query == "") {
      this.sourceSubjectGroup.setFilter([])
    } else {
      query = query.replace(/\s/g, '');
      this.sourceSubjectGroup.setFilter([
        {
          field: 'Document',
          search: query
        },
        {
          field: 'CedulaMil',
          search: query
        },
        {
          field: 'CedocEmail',
          search: query
        }
      ], false);
    }
  }

  onEditConfirm(event): any {
    console.log(event)
    var isAcepted: IsAcepted = {
      id: event.data.UserJobID,
      isAcepted: event.newData.isAcepted
    };

    if (this.isAdmin) {
      var isAcepted: IsAcepted  = {
        id: event.data.UserJobID,
        isAcepted: event.newData.isAcepted,
        isSuper: event.newData.isSuper
      };
    } else {
      var isAcepted: IsAcepted = {
        id: event.data.UserJobID,
        isAcepted: event.newData.isAcepted
      };
    }


    this.oapinfoService.updateUserJobByID(isAcepted).subscribe(p => {
      event.confirm.resolve(event.newData);
    });
  }

  ExportTable() {
    const data = [];
    data.push({
      "isAcepted" : "Aceptado",
      "isSuper" : "Superdocente",
      "LastNames" : "Apellidos",
      "Names" : "Nombres",
      "NameTSchool" : "Escuela",
      "NameCourse" : "Curso Inscrito",
      "NameSubject" : "Nombre Materia",
      "HPM" : "Intensidad Horaria",
      "CedocEmail" : "Correo",
      "Document" : "Cédula",
      "CedulaMil" : "Cedula Militar",
      "CodeClassRoom" : "Código ClassRoom",
      "CodeBlackBoard" : "Código BlackBoard",
    });

    for (var i = 0; i < this.sourceSubjectGroup["data"].length; i++) {
      //Get email
      const cedocEmailHtml = this.sourceSubjectGroup["data"][i].CedocEmail;
      const emailMatch = cedocEmailHtml.match(/href="[^"]*\/([^"]+)"/);
      const email = emailMatch ? emailMatch[1] : null;

      data.push({
        "isAcepted" : this.sourceSubjectGroup["data"][i].isAcepted ? "Si" : "No",
        "isSuper" : this.sourceSubjectGroup["data"][i].isSuper ? "Si":"No",
        "LastNames" : this.sourceSubjectGroup["data"][i].LastNames,
        "Names" : this.sourceSubjectGroup["data"][i].Names,
        "NameTSchool" : this.sourceSubjectGroup["data"][i].NameTSchool || 'n/a',
        "NameCourse" : this.sourceSubjectGroup["data"][i].NameCourse || 'n/a',
        "NameSubject" : this.sourceSubjectGroup["data"][i].NameSubject || 'n/a',
        "HPM" : this.sourceSubjectGroup["data"][i].HPM || 'n/a',
        "CedocEmail" : email,
        "Document" : this.sourceSubjectGroup["data"][i].Document,
        "CedulaMil" : this.sourceSubjectGroup["data"][i].CedulaMil || 'n/a',
        "CodeClassRoom" : this.sourceSubjectGroup["data"][i].CodeClassRoom || 'n/a',
        "CodeBlackBoard" : this.sourceSubjectGroup["data"][i].CodeBlackBoard || 'n/a',
      })
    }
    new AngularCsv(data, 'Reporte');
  }
}