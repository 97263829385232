import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import { SubjectGroup } from '../../../models/usersInfo/subjectgroup';
import { UsersField } from '../../../models/usersInfo/users-fields';
import { CoursesService } from '../../../models/Coursesinfo/courses.service';
import { Course } from '../../../models/Coursesinfo/course';
import { School } from '../../../models/Coursesinfo/school';
import { TypeCourse } from '../../../models/Coursesinfo/typecourse';
import { Module } from '../../../models/Coursesinfo/module';
import { IsAcepted } from '../../../models/Coursesinfo/is-acepted';
import { OapinfoService } from '../../../models/oapinfo/oapinfo.service';
import { AuthService } from '../../../models/Auth/auth.service';
import { ProfileNavComponent } from '../../../components/profile-nav/profile-nav.component';
import { UsersService } from '../../../models/usersInfo/users.service';
import { AccordingView } from '../../../components/according-view/according-view.component';
import { isNull, isNullOrUndefined } from 'util';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'environments/environment';

declare var jQuery: any;
var async = require("async");

@Component({
  selector: 'receipts',
  templateUrl: './receipts.component.html',
  styleUrls: ['./receipts.component.css']

})

export class ReceiptsComponent implements OnInit, OnDestroy {

  customOptions: OwlOptions = {
    loop: true,
    navSpeed: 700,
    nav: false,
    dots: false,
    margin: 10,
    responsive: {
      0: {
        items: 3
      },
      400: {
        items: 6
      },
      740: {
        items: 10
      },
      940: {
        items: 13
      }
    }
  }



  data: SubjectGroup[];
  typecourseID: number = 0;
  schoolID: number = 0;
  courseID: number = 0;
  courses: Course[];
  user: UsersField;
  course: Course;
  schools: School[] = [];
  schoolss: School[] = [];
  typecourses: TypeCourse[];
  areaID: number = 0;
  areas: Course[];
  modules: Module[];
  subjectID: number = 0;
  subjects: Course[];
  idUser: string;
  isAdmin = false;
  isDataReady = false;
  isSchoolReady = false;
  stateButton: boolean = false
  academicData: boolean = false
  settingsSubjectGroup = {
    noDataMessage: "No hay datos disponibles",
    actions: {
      add: false,
      delete: false,
      edit: true,
      columnTitle: "Acciones"
    },

    add: {
      addButtonContent: '',
      createButtonContent: '',
      cancelButtonContent: '',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '<i class="fa fa-pencil-square"></i>',
      saveButtonContent: '<i class="fa fa-floppy-o"></i>',
      cancelButtonContent: '<i class="fa fa-window-close"></i>',
      confirmSave: true
    },
    pager: {
      perPage: 20,
    },
    delete: {
      deleteButtonContent: '',
      confirmDelete: true
    },
    columns: {

      IsPaied: {
        title: 'Aceptado',
        filter: false,
        editor: {
          type: 'list',
          config: {
            list: [{ value: true, title: 'Si' }, { value: false, title: 'No' }],
          }
        },
        type: 'custom',
        renderComponent: AccordingView,
        onComponentInitFunction(instance) {
          instance.save.subscribe(row => {
            console.log("algo se edito")
            // this.saveDocenteDetails(row.id)
          });
        }
      },

      isSuper: {
        title: 'SuperDocente',
        filter: false,
        editor: {
          type: 'list',
          config: {
            list: [{ value: true, title: 'Si' }, { value: false, title: 'No' }],
          }
        },
        type: 'custom',
        renderComponent: AccordingView,
        onComponentInitFunction(instance) {
          instance.save.subscribe(row => {
            console.log("algo se edito")
            // this.saveDocenteDetails(row.id)
          });
        }
      },

      LastNames: {
        title: 'Apellidos',
        filter: false,
        editable: false,
      },
      Names: {
        title: 'Nombres',
        filter: false,
        editable: false,
      },
      NameTSchool: {
        title: 'Escuela',
        filter: false,
        editable: false,
      },

      NameCourse: {
        title: 'Curso Inscrito',
        filter: false,
        editable: false
      },
      NameArea: {
        title: 'Área Académica',
        filter: false,
        editable: false,
      },
      NameSubject: {
        title: 'Nombre Materia',
        filter: false,
        editable: false,
      },
      CedocEmail: {
        title: 'Hoja De Vida',
        filter: false,
        editable: false,
        type: 'html',
      },
      Document: {
        title: 'Cédula',
        filter: false,
        editable: false,
      },
      CedulaMil: {
        title: 'Cedula Militar',
        filter: false,
        editable: false,
      },
      CodeClassRoom: {
        title: 'Código ClassRoom',
        filter: false,
        editable: false,
      },
      CodeBlackBoard: {
        title: 'Código BlackBoard',
        filter: false,
        editable: false,
      }
    }
  };

  sourceSubjectGroup: LocalDataSource;

  source: LocalDataSource;
  isContratacion: boolean = false;
  userCourses: any[];
  userCoursesOriginal: any[];
  isDataUserCoursesReady: boolean = false;
  dataPayment = {
    Invoice: "",
    IsPaied: false,
    IsDocument: false,
    id: 0,
    userapp: { UserDocuments: [], ProfesionalProfile: [], AcademicInfos: [] }
  }
  showPdfData: string = "";
  listDocuments: {
    cedula: string,
    ecaes: string,
    icfes: string,
    academic: string[]
  } = {
      cedula: '',
      ecaes: '',
      icfes: '',
      academic: []
    }
  infoAcademic: any;

  public CEMIL_ICON:any = environment.CEMIL_ICON;
  public EJC_ICON:any = environment.EJC_ICON;

  constructor(public profileNavComponent: ProfileNavComponent,
    private authService: AuthService,
    private usersServices: UsersService,
    private coursesService: CoursesService,
    private route: ActivatedRoute,
    private router: Router,
    private oapinfoService: OapinfoService,
    public sanitizer: DomSanitizer) {
    this.source = new LocalDataSource(); // create the source

    //SI NO HAY USUARIOS EN LOCAL STORAGE, LLEVAR A LOGIN
    if (this.authService.getCurrentUser() == null) {
      this.router.navigate(['/register']);
    } else {
      //DE LO CONTRARO CARGAR EL ID DEL USUARIO
      this.idUser = this.authService.getCurrentUser(); //devuelve el id del usuario
      //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
      if (Number.isInteger(parseInt(this.idUser))) {
        //CONSULTO EL PERFIL DEL USUARIO
        var validar_usuario = [false];

        for (let role of this.profileNavComponent.roles) {
          if (role.name == "Financiera") {
            validar_usuario = validar_usuario.concat([true])
            this.schoolID = role.SchoolID;
            break
          }

          if (role.name == "Contratación") {
            this.isContratacion = true
            this.schoolID = role.SchoolID;

          }
        }


        if (!validar_usuario.includes(true)) { this.router.navigate(['/']); }
        this.course = {
          SchoolID: this.schoolID,
          CourseOferID: 0,
          TypeCourseID: 0,
          CourseID: 0,
          JobOferID: 0,
          SubjectID: 0,
        }

        this.coursesService.getAllSchoolByDepend(this.schoolID).subscribe(depend => {
          if (depend.length > 0) {
            this.schools = depend
            this.isSchoolReady = true
            this.isDataReady = true
            //this.viewCarousel()
            this.LoadData()
          } else {
            this.isDataReady = true
            this.LoadData()

          }
        })


        //FINALIZA CONTENIDO

      }
    }
  }


 
  cleanData(modalname: string) {
    this.showPdfData = "";

    this.dataPayment = {
      Invoice: "",
      IsPaied: false,
      IsDocument: false,
      id: 0,
      userapp: { UserDocuments: [], ProfesionalProfile: [], AcademicInfos: [] }
    }

    setTimeout(function () {
      jQuery("#" + modalname).modal('hide');
    }, 500);

  }

  setNewSchool(id) {
    this.schoolID = id
    this.isDataReady = true
    this.LoadData()
  }
  setDataModal(data, type?, index?:number) {

   

    console.log('data: ', data);
    this.showPdfData = data;
    if (type && (type == "cedula" || type == "icfes" || type == "ecaes")) {
      this.academicData = false;
    } else {
      this.academicData = true;
    }
    if (!isNullOrUndefined(index)) {

      this.infoAcademic = this.dataPayment.userapp.AcademicInfos[index];
    
      console.log('info: ', this.infoAcademic);
    }
  }

  showDataModal(type: string, index?: number) {

    if (type == "cedula")
      this.listDocuments.cedula = this.authService.UrlAPI + "/" + this.dataPayment.userapp.UserDocuments[0].DocumentFile
    if (type == "icfes")
      this.listDocuments.icfes = this.authService.UrlAPI + "/" + this.dataPayment.userapp.ProfesionalProfile[0].IcfesID
    if (type == "ecaes")
      this.listDocuments.ecaes = this.authService.UrlAPI + "/" + this.dataPayment.userapp.ProfesionalProfile[0].SaberProID

    if (type == "academic") {
      this.dataPayment.userapp.AcademicInfos.forEach(academic => {
        this.listDocuments.academic.push(this.authService.UrlAPI + "" + academic.Diploma)
      });

    }
 
  }

  LoadData() {
    this.authService.swalLoading("Cargando Docentes...")

    this.usersServices.getAllListOAPDs(this.schoolID, this.typecourseID, this.courseID, this.areaID, this.subjectID).subscribe(p => {
      this.sourceSubjectGroup.load(p);
      this.authService.swalEndLoad()

    });
  }

  ngOnInit() {


    this.sourceSubjectGroup = new LocalDataSource(); // create the source
    this.coursesService.getSchoolByID(this.schoolID).subscribe(p => this.schoolss = [p]);
    this.coursesService.getAllTypeCourse().subscribe(p => this.typecourses = p);
    this.coursesService.getAllAcademicAreasBySchool(this.schoolID).subscribe(p => this.modules = p);
    this.coursesService.getAllCoursesMayaBySchoolAndTypeCourses(this.schoolID, 0).subscribe(p => this.courses = p);
    //this.viewCarousel()

  }

  selectSchool(model: UsersField) {
    this.schoolID = model.SchoolID;
    this.coursesService.getAllCoursesMayaBySchoolAndTypeCourses(model.SchoolID, this.typecourseID).subscribe(p => this.courses = p);
    this.coursesService.getAllAcademicAreasBySchool(model.SchoolID).subscribe(p => this.modules = p);
    this.usersServices.getAllListOAPDs(this.schoolID, this.typecourseID, this.courseID, this.areaID, this.subjectID).subscribe(p => {
      this.sourceSubjectGroup.load(p);
    });
  }

  selectTypeCourse(model: UsersField) {

    this.typecourseID = model.TypeCourseID
    this.coursesService.getAllCoursesMayaBySchoolAndTypeCourses(this.schoolID, model.TypeCourseID).subscribe(p => {
      this.courses = p
    });
    this.usersServices.getAllListOAPDs(this.schoolID, this.typecourseID, this.courseID, this.areaID, this.subjectID).subscribe(p => {
      this.sourceSubjectGroup.load(p);
    });


  }

  selectCourse(model: Course) {
    if (model.CourseID == 0) { }
    else {
      this.courseID = model.CourseID;
      this.userCourses = []
      this.userCoursesOriginal = []
      this.authService.swalLoading("Cargando Datos")
      this.isDataUserCoursesReady = false
      if (this.courseID != 0) {
        //LEGAN LOS CURSOS
        this.coursesService.getallCourseOferByCourseID(this.courseID).subscribe(courseofers => {

          //con los courseofers consulto los UserCourse
          var cx = this
          async.forEach(courseofers, function (course, callback) {

            cx.coursesService.getallUserCoursesByCoursesOfers(course.id).subscribe(users => {
              cx.userCourses = cx.userCourses.concat(users)
              callback()
            }, err => {
              callback()
            })




          }, function (err) {
            cx.userCoursesOriginal = cx.userCourses
            console.debug("LOS USERCOURSES SON:", cx.userCoursesOriginal)
            if (cx.userCoursesOriginal.length == 0)
              cx.authService.swalError("No se Encontraron datos", "No se encontraron datos con los criterios de busqueda")
            else {
              cx.authService.swalEndLoad()
              cx.isDataUserCoursesReady = true
            }


          })


        });


      }
    }

  }

  selectArea(model: Course) {
    this.areaID = model.JobOferID;
    if (this.courseID != 0) {
      this.coursesService.getAllSubjectsByAreaByCourse(this.areaID, this.courseID).subscribe(p => {
        this.subjects = p
      });
    }
    this.usersServices.getAllListOAPDs(this.schoolID, this.typecourseID, this.courseID, this.areaID, this.subjectID).subscribe(p => {
      this.sourceSubjectGroup.load(p);
    });
  }

  selectSubject(model: Course) {
    this.subjectID = model.SubjectID;
    this.usersServices.getAllListOAPDs(this.schoolID, this.typecourseID, this.courseID, this.areaID, this.subjectID).subscribe(p => {
      this.sourceSubjectGroup.load(p);
    });
  }

  ngOnDestroy() {

  }
  showDocuments(data) {
    console.debug(data)

    this.dataPayment = data
    this.academicData = false;
    this.listDocuments = {
      ecaes: '',
      icfes: '',
      cedula: '',
      academic: []
    }
    this.showDataModal("cedula");
    this.showDataModal("icfes");
    this.showDataModal("ecaes");
    this.showDataModal("academic");

  }

  showPayment(data) {
    console.debug(data)

    this.dataPayment = data
  }

  patchUserCourse(model, nameModal: string) {
    console.debug("hola")
    console.debug(model)
    this.coursesService.patchUserCourseById(model.id, model).subscribe(data => {
      this.selectCourse({ CourseID: this.courseID })
      //Esperar 0.5 segundos despues de actualizar y cerrat ventana modal
      setTimeout(function () {
        jQuery("#" + nameModal).modal('hide');
      }, 500);

      this.dataPayment = {
        Invoice: "",
        IsPaied: false,
        id: 0,
        IsDocument: false,
        userapp: { UserDocuments: [], ProfesionalProfile: [], AcademicInfos: [] }
      }

    }, err => {
      this.authService.swalError("Error al guardar", "No se pudo actualizar la información")
    })
  }


  onSearch(query: string = '') {
    if (query == "") {
      this.userCourses = this.userCoursesOriginal
    } else {

      /*
      this.userCourses = this.userCoursesOriginal.filter(function (word) {
        return word.userapp.UserDocuments[0].Document.match(query)  ||
       !isNullOrUndefined(word.userapp.LastName1)  ? word.userapp.LastName1.match(query) :   ||
        word.userapp.LastName2.match(query) ||
        word.userapp.Name1.match(query) ||
        word.userapp.Name2.match(query) 
          
      });
      */
      this.userCourses = []
      this.userCourses = this.userCoursesOriginal.filter(word => word.userapp.UserDocuments[0].Document.match(query))

    }
  }

  onEditConfirm(event): any {
    console.log(event)
    var isAcepted: IsAcepted = {
      id: event.data.UserJobID,
      isAcepted: event.newData.isAcepted
    };

    if (this.isAdmin) {
      var isAcepted: IsAcepted = {
        id: event.data.UserJobID,
        isAcepted: event.newData.isAcepted,
        isSuper: event.newData.isSuper
      };
    } else {
      var isAcepted: IsAcepted = {
        id: event.data.UserJobID,
        isAcepted: event.newData.isAcepted
      };
    }


    this.oapinfoService.updateUserJobByID(isAcepted).subscribe(p => {
      event.confirm.resolve(event.newData);
    });
  }
}