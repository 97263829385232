import { Component, OnInit } from '@angular/core';
import { UsersField } from "../../models/usersInfo/users-fields";
import { Role } from "../../models/usersInfo/role"
import { NgModule } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { isNullOrUndefined } from 'util';
import { AuthService } from "../../models/Auth/auth.service";
import { UsersService } from "../../models/usersInfo/users.service";
import * as moment from 'moment';
declare var $: any;


import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'environments/environment';


@Pipe({name: 'colombiapipe'})

export class ColombiaPipe implements PipeTransform {

  transform(value: Date, expresion : string): any {
  
    return moment(value).utc().format(expresion)

  }

}

// CHEPE es un campeón BitBucket
@NgModule({
  
  declarations: [ColombiaPipe],
  exports: [ColombiaPipe]

})


export class ColombiaPipeMododule { }


@Component({

  selector: 'profile-nav',
  templateUrl: './profile-nav.component.html',
  providers: [UsersService]

})


export class ProfileNavComponent implements OnInit {

  User_info: UsersField = new UsersField();
  User_info_range: any;
  User_info_force: any;
  User_info_TypeDocument: any;
  user: string;
  roles: Role[];
  nameRoles: string[];
  nameSchools: string[];
  User_info2: UsersField[];
  isCharged = false;

  idforce: number;
  idotan: number;


  //IMAGES
  public SCHOOL_LOADER = environment.SCHOOL_LOADER;
  public PRINCIPAL_APP_NAME = environment.PRINCIPAL_APP_NAME;
  

  constructor(private authService: AuthService, private route: ActivatedRoute, public router: Router, private servicios: UsersService) {

  }

  //INDICO QUE LOS DATOS NO ESTAN DISPONIBLES AL CARGAR EL HTML PARA NO MOSTRAR ERRORES

  ProfileNav: boolean;
  
  ngOnInit() {

    this.ProfileNav = false;

    this.roles = [];
    //SI NO HAY USUARIOS EN LOCAL STORAGE, LLEVAR A LOGIN
    this.user = this.authService.getCurrentUser();

    if (!this.user && isNullOrUndefined(this.user) && this.router.url != "/register" && this.router.url != '/privacy-policy') {

      console.log("El usuario no esta logeado");
      this.logout();

    } 
    else {

      this.nameRoles = [];
      this.nameSchools = [];
      //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
      
      if (Number.isInteger(parseInt(this.user))) {

        this.servicios.getRolesByUserID(parseInt(this.user)).subscribe(p => {

          this.roles = p;

          for (var i = 0; i < p.length; i++) {

            this.nameSchools.push(p[i].NameTSchool + " " + p[i].SchoolAcronim)

            if (!(this.nameRoles.indexOf(p[i].name) >= 0)) {

              this.nameRoles.push(p[i].name);

            }

          }

          console.debug(JSON.stringify(this.roles));
          console.debug("El esta logeado y se va a mostrar los datos al inicio");
          this.ProfileNav = true;

        }, 
        
        err => {

          console.log("error roles:" + err);
          this.logout();

        });

      }

    }

  }

  ngAfterViewChecked() {
    //Called after every check of the component's view. Applies to components only.
    //Add 'implements AfterViewChecked' to the class.

    if (this.ProfileNav && !this.isCharged) {

      this.isCharged = true;
      let index = 0;
      var schools = this.nameSchools;

      $("#NameSchool").text(schools[1]);

      (function animate() {

        $("#NameSchool").delay(4000).fadeOut(1000, function () {
          
          index = (index + 1) % schools.length;                   // when fadeOut complete, increment the index (check if go beyond the length of the array)
          this.textContent = schools[index];                      // change text accordingly

        }).fadeIn(1000, animate);                                 // then fadeIn. When fadeIn finishes, call animate again
      })();
    }

  }


  logout() {

    this.servicios.logout().subscribe(response => {

      this.authService.logout();
      this.router.navigate(['/register']);

    }, 
    
    err => {

      console.log(err);
      this.authService.logout();

      if (this.router.url != '/privacy-policy')
        {
          this.router.navigate(['/register']);
        }

    });

  }

}

