import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from '../../../models/Coursesinfo/subject';
import { AuthService } from '../../../models/Auth/auth.service';
import { TypeCourse } from '../../../models/Coursesinfo/typecourse';
import { Module } from '../../../models/Coursesinfo/module';
import { Course } from '../../../models/Coursesinfo/course';
import { School } from '../../../models/Coursesinfo/school';
import { CoursesService } from '../../../models/Coursesinfo/courses.service';
import { ProfileNavComponent } from '../../../components/profile-nav/profile-nav.component';
import { environment } from 'environments/environment';
import { QualitativeDescription } from 'app/models/Coursesinfo/qualitative-description';
import { QualitativeDescriptionLocal } from 'app/models/Coursesinfo/qualitative-description-local';
import { AttachmentService } from 'app/models/attachment/attachment.service';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';

declare var $: any;
@Component({
  selector: 'Coursemallas',
  templateUrl: './coursemallas.component.html',
  styleUrls: ['./coursemallas.component.css']
})

export class CourseMallasComponent implements OnInit {
  private sub: any;
  TypeCredit = 0;
  user: string;
  SchoolID: number = 0;
  typecourses: TypeCourse[] = [];
  academicareas: Module[] = [];
  schools: School[] = [];
  courseoferfield: Course;
  courses: Course[];
  subjectfield: Subject;
  myQualitativeDescription: QualitativeDescriptionLocal;
  public UpdateQualitativeDescription: QualitativeDescription;
  courseIDLocal:number = null
  submitbuton: string = 'Guardar';
  isCourseContext: boolean = true;
  isok: Boolean;
  DatosDisponibles: boolean = false;
  errorMessage: string = 'Si desea solicitar una cuenta, por favor diligencie el siguiente formulario';
  stateButton: boolean = false;
  actualizar: boolean = false
  urlImageEdit:string
  //my strange thing : DAVID
  show: boolean = true
  private STORAGE_NAME_QUALITATIVE_DESC = environment.STORAGE_NAME_QUALITATIVE_DESC;

  public SCHOOL_LOADER = environment.SCHOOL_LOADER;
  public SCHOOL_LOGO_DYNAMIC_SVG: any = (id: number) => environment.SCHOOL_LOGO_DYNAMIC_SVG(id);
  public PRINCIPAL_APP_NAME = environment.PRINCIPAL_APP_NAME;

  constructor(
    private authService: AuthService,
    private coursesService: CoursesService,
    private route: ActivatedRoute,
    public profileNavComponent: ProfileNavComponent,
    private _attachmentService: AttachmentService,
    private router: Router
  ) {
    this.UpdateQualitativeDescription = {}
    if (this.authService.getCurrentUser() == null) this.router.navigate(['/register']);
    else {
      this.user = this.authService.getCurrentUser(); //devuelve el id del usuario
      if (Number.isInteger(parseInt(this.user))) {
        var validar_usuario = [false];
        for (let role of this.profileNavComponent.roles) {
          if (role.name == "Inspector Escuela") {
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
            break
          }
          if (role.name == "admin") {
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
            break
          }
          if (role.name == "Bienestar") {
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
            break
          }
          if (role.name == "Directiva") {
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
            break
          }
        }
        if (!validar_usuario.includes(true)) this.router.navigate(['/']);
        else {
          this.coursesService.getAllTypeCourse().subscribe(p => this.typecourses = p);
          this.coursesService.getAllAcademicAreasBySchool(this.SchoolID).subscribe(p => this.academicareas = p);
          this.coursesService.getAllSchools().subscribe(p => this.schools = p);
          this.coursesService.getAllCoursesMayaBase(this.SchoolID, 0).subscribe(p => { this.courses = p });
        }
      }
    }
  }

  ngOnInit() {
    this.courseoferfield = {
      id: null,
      SchoolID: this.SchoolID,
      TypeCourseID: 0,
      IsSemestralize: false,
      IsAnnual: false,
      Description: "",
      DurationTotal: null,
      IsMilitar: false,
      NoRegisterMiL: "NA",
      NoRegisterMinE: "NA",
      CourseID: null,
      IsActive: true,
      IsBase: false,
      TeachingHoursmin: 0,
      TypeRecord: '',
      DesiredGrade: 0,
    }
    this.subjectfield = {
      id: null,
      SubjectID_Requirement: 0,
      Description: "",
      AcademicAreaID: 0,
      NameSubject: "",
      HFH: null,
      HPM: null,
      MaxSlots: 0,
      SemesterNumber: 0,
      TypeRecord: "",
      DesiredGrade: 0,
      TypeCredit: 0
    }
    this.myQualitativeDescription = {
      year: 0,
      rangeFirts: 0,
      rangeSecond: 0,
      qualitativeOrder: 0,
      qualitativeName: '',
      qualitativeDescription: '',
      showImage: 'No',
      
    }

    this.sub = this.route.params.subscribe(params => {
      if (params['id']) this.getDataFromBD(params['id']);
      else this.DatosDisponibles = true;
      this.setJquerry();
    });
  }

  getDataFromBD(courseId: number): void {
    forkJoin({
      ALL_COURSE_DATA: this.coursesService.getCourseByID(courseId).pipe(take(1)),
      ALL_SCHOOLS_LIST: this.coursesService.getAllSchools().pipe(take(1)),
    }).subscribe({
      next: (data:any) => {
        let { ALL_COURSE_DATA, ALL_SCHOOLS_LIST } = data
        this.courseoferfield = ALL_COURSE_DATA;

        let schoolParentAndChilds = ALL_SCHOOLS_LIST.filter(schoolIterated => (schoolIterated.id == this.SchoolID || schoolIterated.DepenSchoolID == this.SchoolID));
        let ALL_PARENT_AND_CHILDRENS_SCHOOLS_IDS = [];
        schoolParentAndChilds.forEach(schoolParentAndChildsIterated => {
          ALL_PARENT_AND_CHILDRENS_SCHOOLS_IDS.push(schoolParentAndChildsIterated.id!);
        });

        // if (this.courseoferfield.SchoolID != this.SchoolID) {
        if (!ALL_PARENT_AND_CHILDRENS_SCHOOLS_IDS.includes(this.SchoolID)) {
          this.router.navigate(['/admin/newcoursemallas']);
          return;
        }
        this.updateSubjectGradesView();
        this.isCourseContext = false;
        this.DatosDisponibles = true;
        this.setJquerry();
      },
      error: (err) => {
        console.log("ERROR ngOnInit forkjoin response", err)
      },
      complete: () => {
        console.log('complete ngOnInit forkjoin response');
      }
    });
  }

  setJquerry() {
    $(function () {
      // card controls
      // header double click
      $('.cat__core__sortable .card-header').on('click', function () {
        $(this).closest('.card').toggleClass('cat__core__sortable__collapsed');
      });
    });
  }

  saveCourseMallas(model: Course, isValid: boolean) {
    this.errorMessage = "Corriga los datos erroneos";

    if (this.subjectfield.TypeRecord != "Cualitativo") {
      const detectCommas = /^(\d+(\,\d{1,}))$/.test(`${this.courseoferfield.DesiredGrade}`);
      if (detectCommas)
        return this.authService.swalError("Error en decimal", "Los valores decimales debe digitarlos como punto")
    }


    if (isValid) {
      this.stateButton = true
      this.errorMessage = "Validando ... ";
      this.submitbuton = "Validando ... ";
      model.id = this.courseoferfield.id;
      if (model.IsBase) model.CourseID = model.id;
      this.coursesService.createCourse(model, this.courseoferfield.SchoolID).subscribe(
        p => {
          this.stateButton = false
          this.authService.swalSuccess("Curso Creado!", "Por Favor Ingrese Las Materias Del " + this.courseoferfield.NameCourse)
          this.isok = true;
          this.submitbuton = "Guardar";
          this.updateSubjectGradesView();
          this.router.navigate(['admin/newcoursemallas/' + p.id]);
        },
        e => {
          this.stateButton = false
          this.errorMessage = e;
          this.submitbuton = "Error";
          this.isok = false;
        }
      );
    }
  }

  saveSubject(model: Subject, isValid: boolean) {
    this.errorMessage = "Corrija los datos erróneos";
    this.urlImageEdit = ''
    if (this.subjectfield.TypeRecord != "Cualitativo") {
      const detectCommas = /^(\d+(\,\d{1,}))$/.test(`${model.DesiredGrade}`);
      if (detectCommas)
        return this.authService.swalError("Error en decimal", "Los valores decimales debe digitarlos como punto")
    }
    if (isValid) {
      this.stateButton = true
      this.errorMessage = "Validando ... ";
      this.submitbuton = "Validando ... ";
      model.CourseID = this.courseoferfield.id;
      model.DesiredGrade = model.TypeRecord === 'Cualitativo' ? 0 : model.DesiredGrade;
      this.coursesService.createSubject(model, model.CourseID).subscribe(
        p => {
          this.authService.swalSuccess("Creado!", "Se Creó Correctamente la materia.");
          this.isok = true;
          this.submitbuton = "Guardar";
          this.stateButton = false;
          this.ngOnInit();
        },
        e => {
          this.stateButton = false
          this.errorMessage = e;
          this.submitbuton = "Error";
          this.isok = false;
        }
      );
    }
  }

  public fileToUpload: File;
  public fileToUploadEmpty: File;
  public isArchiveError: boolean = false;
  public uploadArchiveText: string = 'Elija el archivo';
  onFileInput(event: any) {
    this.urlImageEdit = ''
    if (!<File>event.target.files[0]) return;
    this.fileToUpload = <File>event.target.files[0];
    console.log("this.fileToUpload ", this.fileToUpload)
    if (this.fileToUpload.size > 5000000) {
      this.authService.swalSuccess("Error!", "El archivo supera el tamaño máximo (5MB).");
      this.uploadArchiveText = 'Error de Archivo'
      this.isArchiveError = true;
    } else {
      this.uploadArchiveText = 'Cambiar el archivo';
      this.isArchiveError = false;
    }

  }

  uploadAttachment(data: QualitativeDescription) {
    this.authService.swalLoading("Cargando!");
    this._attachmentService.storageFile(this.STORAGE_NAME_QUALITATIVE_DESC, this.fileToUpload).then((result: any) => {
      data.imageURL = `/attachments/${this.STORAGE_NAME_QUALITATIVE_DESC}/download/${result['result']['files']['file'][0]['name']}`;

      this.fileToUpload = this.fileToUploadEmpty;
      if(!this.UpdateQualitativeDescription.id){
        this.saveQualitativeDescription(data);
      }else{
        this._actualizarQualitici(data)
      }
    });
  }

  saveQualitativeDescription(data: QualitativeDescription) {
    this.authService.swalLoading("Cargando!");
    this.coursesService.createQualitativeDescription(data).subscribe(
      p => {
        this.authService.swalSuccess("Creado!", "Se Creó correctamente la configuración de notas.");
        console.log('success saveQualitativeDescription', p)
        this.ngOnInit();
      },
      e => {
        this.authService.swalSuccess("Error!", "Algo salió mal, intentelo de nuevo, si el problema persiste, contecte a soporte.");
        console.log('Error saveQualitativeDescription', e);
      }
    );
  }

  deleteQualitativeDescription(data: QualitativeDescription) {
    this.authService.swalLoading("Cargando!");
    this.coursesService.deleteQualitativeDescription(data.id).subscribe(
      p => {
        this.authService.swalSuccess("Creado!", "Se eliminó correctamente la configuración de notas.");
        this.ngOnInit();
      },
      e => {
        this.authService.swalSuccess("Error!", "Algo salió mal, intentelo de nuevo, si el problema persiste, contecte a soporte.");
        console.log('Error deleteQualitativeDescription', e);
      }
    );
  }
  async EditQualitativeDescription(data: QualitativeDescription) {
    console.log("Data ", data)
    this.UpdateQualitativeDescription = this.UpdateQualitativeDescription || {};
    this.myQualitativeDescription.year = data.year
    this.myQualitativeDescription.qualitativeOrder = data.qualitativeOrder
    this.myQualitativeDescription.rangeFirts = Number(data.range.split('-')[0])
    this.myQualitativeDescription.rangeSecond = Number(data.range.split('-')[1])
    this.myQualitativeDescription.qualitativeName = data.qualitativeName
    this.myQualitativeDescription.qualitativeDescription = data.qualitativeDescription
    this.myQualitativeDescription.showImage = data.showImage?'Si':'No'
    this.UpdateQualitativeDescription.id = data.id
    this.UpdateQualitativeDescription.courseID = data.courseID
    this.actualizar = true
    if(data.showImage){
      this.urlImageEdit = data.imageURL   
      const response = await fetch(data.imageURL);
      const blob = await response.blob();
      const lastModified = 1721174742290;
      const name = data.imageURL.split('download/')[1];
        this.fileToUpload = new File([blob], name, {
        type: blob.type,
        lastModified: lastModified,
      });
    }
  }
  _actualizar(data){
    
    if (data.showImage != 'No' && !this.urlImageEdit) {
      this.uploadAttachment(data)
    }
    else {
      data.imageURL = this.urlImageEdit
      this._actualizarQualitici(data)
    };
  }
  _actualizarQualitici(data){
    console.log("data actualizar", data)
    this.UpdateQualitativeDescription.qualitativeDescription = data.qualitativeDescription
    this.UpdateQualitativeDescription.qualitativeName = data.qualitativeName
    this.UpdateQualitativeDescription.qualitativeOrder = data.qualitativeOrder
    this.UpdateQualitativeDescription.range = data.rangeFirts +'-'+ data.rangeSecond
    this.UpdateQualitativeDescription.showImage = data.showImage == "Si"?true:false
    this.UpdateQualitativeDescription.year = data.year
    this.UpdateQualitativeDescription.imageURL = data.imageURL

    this.authService.swalLoading("Cargando!");
    this.coursesService.UpdateQualitativeDescription(this.UpdateQualitativeDescription.id, this.UpdateQualitativeDescription).subscribe(
      p => {
        this.authService.swalSuccess("Actualizado!", "Se actualizo correctamente la configuración de notas.");
        this.actualizar = false
        this.ngOnInit();
      },
      e => {
        this.authService.swalSuccess("Error!", "Algo salió mal, intentelo de nuevo, si el problema persiste, contecte a soporte.");
        console.log('Error deleteQualitativeDescription', e);
      }
    );
    this.urlImageEdit = ''
  }
  
  getObservableToUploadAttachment() {
    let data = this._attachmentService.storageFile( this.STORAGE_NAME_QUALITATIVE_DESC, this.fileToUpload );
    return data;
  }

  validateQualitativeDescriptionFormToSave(model: QualitativeDescriptionLocal, isValid: boolean) {

    if (!isValid) {
      console.log('Formulario invalido');
      return
    }

    let dataToSend: QualitativeDescription = {
      year: model.year,
      courseID: this.courseoferfield.id,
      range: `${model.rangeFirts}-${model.rangeSecond}`,
      qualitativeOrder: model.qualitativeOrder,
      qualitativeName: model.qualitativeName,
      qualitativeDescription: model.qualitativeDescription,
      showImage: model.showImage == 'Si',
      imageURL: null,
    }

    if (dataToSend.showImage && this.fileToUpload) this.uploadAttachment(dataToSend);
    else this.saveQualitativeDescription(dataToSend);
  }
  //my strange thing==> It creates a function to check the id of typecourse, after our initialization, and finally hide or show blocks(columns) : DAVID
  checkTypeCourseByID(form: any) {
    if (form.TypeCourseID == 17 || form.TypeCourseID == 18) this.show = false;
    else this.show = true;
  }

  colorSchool(idSchool: number) {
    let classes = {};
    switch (idSchool) {
      case 0: classes = { cat__info__item_CEDOC: true }; break;
      case 1: classes = { cat__info__item_CEMIL: true }; break;
      case 4: classes = { cat__info__item_EAS: true }; break;
      case 5: classes = { cat__info__item_ESINF: true }; break;
      case 6: classes = { cat__info__item_ESCAB: true }; break;
      case 7: classes = { cat__info__item_ESART: true }; break;
      case 8: classes = { cat__info__item_ESING: true }; break;
      case 9: classes = { cat__info__item_ESCOM: true }; break;
      case 10: classes = { cat__info__item_ESICI: true }; break;
      case 16: classes = { cat__info__item_ESAVE: true }; break;
      case 17: classes = { cat__info__item_ESLOG: true }; break;
      case 18: classes = { cat__info__item_ESCEQ: true }; break;
      case 19: classes = { cat__info__item_ESPOM: true }; break;
      case 20: classes = { cat__info__item_ESMAI: true }; break;
      case 21: classes = { cat__info__item_ESDIH: true }; break;
      case 22: classes = { cat__info__item_CEMAI: true }; break;
      case 23: classes = { cat__info__item_ESIDE: true }; break;
      case 24: classes = { cat__info__item_ESFES: true }; break;
      case 25: classes = { cat__info__item_CEDOC: true }; break;
      case 33: classes = { cat__info__item_ESMIC: true }; break;
      case 34: classes = { cat__info__item_EMSUB: true }; break;
      case 35: classes = { cat__info__item_ESPRO: true }; break;
      default: classes = { cat__info__item_ALL: true }; break;
    }
    return classes;
  }

  textAcademicarea(id: number) {
    var num = this.academicareas.find(x => x.id == id);
    if (num) return num.NameArea;
    return "Área Académica"
  }

  textTypeCourse(id: number) {
    var num = this.typecourses.find(x => x.id == id);
    if (num) return num.NameTypeCourse;
    return "Tipo de Curso"
  }

  textSchools(id: number) {
    var num = this.schools.find(x => x.id == id);
    if (num) return num.NameTSchool;
    return "Nombre de Escuela"
  }

  textSemestralized(isSemestralized) {
    if (isSemestralized == 1) return "Semestres"
    else return "Semanas"
  }

  getPoints(subjectfield) {
    return subjectfield.HPM ? subjectfield.HPM : 0 + subjectfield.HFH ? subjectfield.HFH : 0 + subjectfield.HTI ? subjectfield.HTI : 0
  }


  public getMaxGradeValue(field: any): number {
    if (field.includes("-")) {
      return Math.max(...field.split("-").map(x => Number(x)))
    }
    return 0

  }

  private updateSubjectGradesView() {
    const updateGrades = {
      ...this.subjectfield,
      TypeRecord: this.courseoferfield.TypeRecord,
      DesiredGrade: this.courseoferfield.DesiredGrade,
    }
    this.subjectfield = updateGrades;
  }

}
