import { Component, OnInit, OnDestroy, AfterViewChecked } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { isNullOrUndefined } from 'util';
import { AngularCsv } from 'angular7-csv';
import { LocalDataSource } from 'ng2-smart-table';
import { ViewCell } from 'ng2-smart-table';
import { School } from "../../../../models/Coursesinfo/school";
import { Module } from "../../../../models/Coursesinfo/module";
import { UsersService } from '../../../../models/usersInfo/users.service';
import { CoursesService } from '../../../../models/Coursesinfo/courses.service';
import { UsersField } from '../../../../models/usersInfo/users-fields';
import { Subject } from '../../../../models/Coursesinfo/subject';
import { Course } from '../../../../models/Coursesinfo/course';
import { AuthService } from '../../../../models/Auth/auth.service';
import { Role } from '../../../../models/usersInfo/role';
import { ProfileNavComponent } from '../../../../components/profile-nav/profile-nav.component';
import * as moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { environment } from 'environments/environment';
import { Observable, forkJoin, of, pipe } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
declare var jQuery: any;
declare var $: any;


export class Intermediate {
  public Disponible = 0;
  public Professor: object = {
    0: "Seleccionar Docente",
  }
  public Grups: object = {
    0: " NO GROUP",
  }
}

// INICIO COMPONENTE PARA EL NG SMART TABLE GRUPOS
@Component({
  selector: 'button-view-groups',
  template: `
  {{ renderValue }}
`
})
export class ButtonViewScheduleGroupsComponent implements ViewCell, OnInit, AfterViewChecked {
  constructor(
    public intermediate: Intermediate,
  ) { }
  renderValue: string;
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter()
  ngOnInit() {
    // console.log("aca esta el render despues")
    //  console.log(this.intermediate.Grups)
    this.renderValue = this.intermediate.Grups[this.value];
  }
  ngAfterViewChecked() {
    this.renderValue = this.intermediate.Grups[this.value];
  }
}
// FIN COMPONENTE PARA EL NG SMART TABLE GRUPOS


// INICIO COMPONENTE PARA EL NG SMART TABLE
@Component({
  selector: 'button-view-courses',
  template: `
    <button (click)="onClick()"class="btn btn-info btn-block btn-squared">Inscribirse</button>
  `,
})
export class ButtonViewCoursesEditComponent implements ViewCell, OnInit {
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.save.emit(this.rowData);
  }
}

// FIN COMPONENTE PARA EL NG SMART TABLE





@Component({
  selector: 'professor-edit-courses',
  templateUrl: './professor-edit-courses.component.html',
  styleUrls: ['./professor-edit-courses.component.css'],
  providers: [UsersService, CoursesService, ButtonViewScheduleGroupsComponent, Intermediate, ButtonViewCoursesEditComponent]
})
export class ProfessorCoursesEditComponent implements OnInit, OnDestroy {
  user: string;
  student: UsersField;
  public CourseID;
  public Subject: Subject[];
  course_by_school: Course[];
  subjects_by_course: Course[];
  Studends_by_Course: any;
  public List_students_by_course: any[];
  public List_students_by_course2: any[];
  public Students: any[];
  public StudentsCount: number = 0;
  public StudentsByGroup: any[];
  public ArrayStudentsByGroup: any;
  public Contador: number;
  public AreStudents: boolean;
  public AreGroups: boolean;
  public AreGroupsButton: boolean = false;
  state: number = 1;
  Results: any;
  public school: Course[];
  public ErrorGroup: string;
  public EnableUpdate: boolean = false;
  public CreateCSV: any;
  public NumberGroups = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26]
  public SplitStudents;
  abecedario = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
  public Mensaje = ""
  public courseIDName = "Seleccionar"
  //inicia configuracion smart table
  public Groups: any;
  public GroupsNumber: number = 0;

  modules: Module[];
  schools: School[];
  courses: Course[];

  subjects: Course[];

  userid: string;

  areaID: number = 0;
  courseID: number = 0;

  settings = {
    noDataMessage: "No hay datos disponibles",

    edit: {
      editButtonContent: '<i class="fa fa-pencil-square"></i>',
      saveButtonContent: '<i class="fa fa-floppy-o"></i>',
      cancelButtonContent: '<i class="fa fa-window-close"></i>',
      confirmSave: true

    },
    actions: {
      add: false,
      delete: false,
      edit: true,
      columnTitle: "Acciones"
    },
    setPaging: {
      page: 1,
      perPage: 15,
      doEmit: true,
    },

    pager: {
      perPage: 15,
      doEmit: true
    },

    columns: {



      LastNames: {
        title: 'Apellidos',
        filter: false,
        editable: false,
      },

      Names: {
        title: 'Nombres',
        filter: false,
        editable: false,
      },
      Document: {
        title: 'Documento',
        filter: false,
        editable: false,
      },
      CourseGroupID: {
        title: 'Grupo',
        filter: false,
        type: 'custom',
        editor: {
          type: 'list',
          config: {
            list: [],
          },
        },
        renderComponent: ButtonViewScheduleGroupsComponent,
        onComponentInitFunction(instance) {
          instance.save.subscribe(row => {
            console.log("algo se edito")
            // this.saveDocenteDetails(row.id)
          });
        }




      },






      /*
          id: {
            title: 'GRUPO',
            filter: false,
            type: 'custom',
            renderComponent: ButtonViewCoursesComponent,
            onComponentInitFunction(instance) {
                instance.save.subscribe(row => {
                   // this.saveDocenteDetails(row.id)
                });
              }
          }
      
          */
    }
  };

  source: LocalDataSource;
  //fin configuracion smart table



  settings2 = {
    noDataMessage: "No hay datos disponibles",

    edit: {
      editButtonContent: '<i class="fa fa-pencil-square"></i>',
      saveButtonContent: '<i class="fa fa-floppy-o"></i>',
      cancelButtonContent: '<i class="fa fa-window-close"></i>',
      confirmSave: true,
    },
    actions: {
      add: false,
      delete: false,
      edit: true,
      columnTitle: "Acciones"
    },
    setPaging: {
      page: 1,
      perPage: 26,
      doEmit: true,
    },

    pager: {
      perPage: 26,
      doEmit: true
    },

    columns: {

      name: {
        title: 'Nombre Grupo',
        filter: false,
        editable: false,
      },


      StartDayTime: {
        title: 'Hora inicio de clases',
        filter: false,
        editable: true,
      },

      EndDayTime: {
        title: 'Hora fin de clases',
        filter: false,
        editable: true,
      }
    }
  };
  source2: LocalDataSource;

  public CEMIL_ICON:any = environment.CEMIL_ICON;
  public EJC_ICON:any = environment.EJC_ICON;



  constructor(private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    public servicios: UsersService,
    public coursesService: CoursesService,
    public intermediate: Intermediate,
    public profileNavComponent: ProfileNavComponent,
    private Render: ButtonViewCoursesEditComponent,

  ) {
    this.source = new LocalDataSource(); // create the source

    this.source2 = new LocalDataSource(); // create the source

  }

  //INDICO QUE LOS DATOS NO ESTAN DISPONIBLES AL CARGAR EL HTML PARA NO MOSTRAR ERRORES
  DatosDisponibles: boolean = false;





  ngOnInit() {


    if (this.authService.getCurrentUser() == null) {
      this.router.navigate(['/register']);
    } else {
      //DE LO CONTRARO CARGAR EL ID DEL USUARIO
      this.user = this.authService.getCurrentUser(); //devuelve el id del usuario
      //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
      if (Number.isInteger(parseInt(this.user))) {
        //console.log(this.authService.getCurrentUser());
        //CONSULTO EL PERFIL DEL USUARIO
        var validar_usuario = [false];


        for (let role of this.profileNavComponent.roles) {
          if (role.name == "Agrupacion") {
            validar_usuario = validar_usuario.concat([true])
            break
          }
        }


        if (validar_usuario.includes(true)) {

          this.user = this.authService.getCurrentUser(); //devuelve el id del usuario

          let roles: Role[];

          this.servicios.getRolesByUserID(parseInt(this.user)).subscribe(p => {
            roles = p;
            console.debug("La escuela de este personaje es: " + roles[0].SchoolID)

            this.coursesService.getAllCoursesBySchool(roles[0].SchoolID).subscribe(p => {
              this.course_by_school = p.sort(function (a, b) {
                // Ascending: first age less than the previous
                return a["NameCourseOfer"].localeCompare(b["NameCourseOfer"]);
              })


            });


          });




          var state = 1;
          this.AreStudents = false;
          this.AreGroups = false;

          this.student = {
            Document: '',
            Name1: '',
            LastName1: '',
            CedulaMil: '',
            IsMilitar: true,
            SchoolID: 4,
            CourseOferID: 0,
            SubjectOferID: 0,
          }


        } else {
          this.router.navigate(['/user/my-account']);
        }
      }
    }


  }
  ngOnDestroy() {

  }







  callstudents(model) {

    this.courseIDName = this.CourseByID(model.id)
    this.AreGroups = false;

    this.source2.empty()
    this.source2.refresh()

    this.Mensaje = ""

    console.debug(model);


    //PRIMERO LLAMO A LOS GRUPOS

    this.coursesService.getAllCourseGroupsByCourseOferByID(model.id).subscribe(p => {

      console.log("LOS GRUPOS CONSULTADOS SON:")
      console.log(p)
      //CARGO EL NOMBRE DE CADA GRUPO EN LA VRIABLE INTERMEDIA PARA SER RENDERIZADA
      if (p.length == 0) {
        this.Groups = [{ id: 0, name: "No Existen Grupos Para el Curso Seleccionado" }]
        this.GroupsNumber = 0
        Swal({
          type: 'warning',
          title: 'Para El Curso Seleccionado No Hay Grupos',
        })


      } else {
        this.Groups = p;
        this.source2.load(p)
        this.GroupsNumber = p.length
        this.intermediate.Grups = {}
        for (let i = 0; i < this.Groups.length; i++) {
          Object.assign(this.intermediate.Grups, { [this.Groups[i].id]: this.Groups[i].name.toUpperCase() })
        }
      }



      var ModelForGroupsList = []
      for (let i = 0; i < p.length; i++) {


        ModelForGroupsList.push({

          value: p[i].id,
          title: p[i]["name"],


        })

      }
      this.settings.columns.CourseGroupID.editor.config.list = ModelForGroupsList;


      //LUEGO LLAMO A TODOS LOS USUARIOS DE ESA OFERTA
      this.coursesService.getUsersByCourseOfferHasCourseGroup(model.id).subscribe(userappsOffer => {

        //ELIMINO LOS RESULTADOS QUE NO CONTENGAN NADA EN UserCourses

        //CREO VARIABLE TEMPORAL
        var temp = userappsOffer

        console.log(temp)


        var newListUsers = []


        //RECORRO TODOS LOS DATOS DEVUELTOS POR EL SERVICIO
        for (let i = 0; i < temp.length; i++) {
          //REVISO SI EL USUARIO CONTIENE CURSOS
          if (temp[i].UserCourses.length === 0 ||  //SI USERCOURSE ESTA VACIO
            temp[i].UserCourses[0].length === 0  //EL CAMPO COURSEGROUPID NO EXISTE
          ) {
            //BORRO ESE REGISTRO 
            delete temp[i]
          }
          else {
            //DE LO CONTRARIO REVISO LOS CURSOS QUE TIENE
            //SI LOS CURSOS QUE TIENE ESA PERSONA COINCIDEN CON ALGUNO DE LOS GRUPOS DEL CURSOS
            //CREAR LA LISTA
            var ValidateAllUserCourses: boolean = false
            for (var index = 0; index < temp[i].UserCourses.length; index++) {

              //BUSCO SI EL ID DEL CURSEGROUP X  ESTA EN EL CONSULTADO POR EL CURSO
              var searchCourseGroup = p.findIndex(k => k.id === temp[i].UserCourses[index].CourseGroupID);

              //SI ES MAYOR A CERO ES PORQUE ES EL INDEX  (-1 SI NO ECUENTRA NADA)
              if (searchCourseGroup >= 0) {
                var data = temp[i].UserCourses[index] //GUARDO EL DATO EN UNA VARIABLE TEMPORAL
                delete temp[i].UserCourses //BORRO TODOS LOS USERCOURSES QUE TIENE EL USUARIO
                temp[i].UserCourses = []
                temp[i].UserCourses.push(data) //DEJO EL USERCOURSE QUE ME INTERESA
                ValidateAllUserCourses = true
              }


            }
            //if (ValidateAllUserCourses==false)
            //  delete temp[i] //SI DENTRO DE TODOS LOS USERCOURSES NO ESTABA EL QUE NECESITABA BORRO ESE REGISTRO
            //else
            newListUsers.push(temp[i]) //CON EL DATO DEL USUARIO LO LLEVO A LA NUEVA LISTA
          }



        }


        //CREO EL NUEVO OBJETO
        var NuevoObjeto = [];
        this.StudentsCount = newListUsers.length
        for (let j = 0; j < newListUsers.length; j++) {

          if (!isNullOrUndefined(newListUsers[j])) {


            this.coursesService.getUserappsByCC(newListUsers[j].CedocEmail).subscribe(resp => {
                var NombreCompleto = $.grep([newListUsers[j].Name1, newListUsers[j].Name2], Boolean).join(" ");
                var ApellidoCompleto = $.grep([newListUsers[j].LastName1, newListUsers[j].LastName2], Boolean).join(" ");
                var Apellidos = ApellidoCompleto.toUpperCase()
                var Nombres = NombreCompleto.toUpperCase()
    
    
                var IDCourseGroup = newListUsers[j].UserCourses[0].CourseGroupID
                NuevoObjeto.push(
                  {
                    "id": newListUsers[j].UserCourses[0].id,
                    "Names": Nombres,
                    "LastNames": Apellidos,
                    "CourseGroupID": IDCourseGroup,
                    "Document":resp[0].UserDocuments[0].Document,
                    "UserID": newListUsers[j].id,
                    "CourseOferID": newListUsers[j].UserCourses[0].CourseOferID
    
                  }
                )
                NuevoObjeto = NuevoObjeto.sort(function (a, b) {
                  // Ascending: first age less than the previous
        
                  return a["LastNames"].localeCompare(b["LastNames"]);
        
                });
        
        
                //ASIGNARLE LA VARIABLE TEMPORAL A LISTA DE ESTUDIANTES
                //this.List_students_by_course = temp
                //console.log(JSON.stringify(this.List_students_by_course))
                this.source.load(NuevoObjeto)
                this.CreateCSV = NuevoObjeto
                this.AreGroups = true
            })
          }
        }
      });

    })


  }

  updateStudents() {


    this.source.getAll().then(p => {
      console.debug(p)
      for (let j = 0; j < p.length; j++) {
        let objeto = {
          "CourseOferID": parseInt(p[j].CourseOferID),

          "UserID": parseInt(p[j].UserID),
          "CourseGroupID": parseInt(p[j].CourseGroupID),
          "id": p[j].id


        }
        this.coursesService.UpdtateAssociationUserCourse(objeto).subscribe(p => {
          this.EnableUpdate = false
          this.Mensaje = "Se Han Actualizado Los Grupos a Los Estudiantes Seleccionados"


        });
      }




      //console.log("SE VA A RECIBIR")
      //console.log(p)


    })
  }



  ExportList() {


    var Exporar = [];


    Exporar.push({
      "LastNames": "APELLIDOS",
      "Names": "NOMBRES",
      "Group": "GRUPO",

    })

    for (var i = 0; i < this.CreateCSV.length; i++) {


      var index = this.Groups.findIndex(x => x.id == parseInt(this.CreateCSV[i].CourseGroupID));


      Exporar.push({
        "LastNames": this.CreateCSV[i].LastNames,
        "Names": this.CreateCSV[i].Names,
        "Group": this.Groups[index].name,

      })



    }






    new AngularCsv(Exporar, 'My Report');


  }


  onEditConfirm(event): any {
    const userId = event.data.UserID;
    const previousCourseGroupId = event.data.CourseGroupID;
    const newCourseGroupId = event.newData.CourseGroupID;

    if(Number(previousCourseGroupId) === Number(newCourseGroupId) )
      return;

    this.authService.swalLoading('Cargando...');

    this.getSubjectGroups(Number(previousCourseGroupId)).pipe(
        switchMap(groups => {
          const subjectGroupIDs = groups.map(group => group.id);
          return this.getUserSubjects(subjectGroupIDs, Number(userId));
        }),
        switchMap((userSubjects)=>{
          const userSubjectsObs:Observable<any>[] = userSubjects.map(e => this.coursesService.patchUserSubject(e.id, {...e, isAvailable:false }) );
          return userSubjectsObs.length > 0 ? forkJoin( userSubjectsObs ) : of([]);
        }),
        switchMap(() => this.coursesService.UpdtateAssociationUserCourse(event.newData) ),
        switchMap(()=>{
          const filter = JSON.stringify({
            where:{CourseGroupID: Number(newCourseGroupId) }
          })

          return this.coursesService.getSubjectGroup(filter).pipe( map(e => ({newSubjectGroups:e})));
        }),
        switchMap(({newSubjectGroups})=>{
          const assignUserSubjectObs = newSubjectGroups.map(e => {
            const data = {
              SubjectGroupID: e.id,
              CourseGroupID: Number(newCourseGroupId),
            }
            return this.coursesService.assignUserSubjectBackV2(data)
          });

          return (assignUserSubjectObs.length > 0 ? forkJoin(assignUserSubjectObs) : of([])).pipe(map(e => ({ result: e })));
        })
      ).subscribe({
      next: ({result}) => {
        this.authService.swalSuccess("Hecho!", "El usuario ha sido cambiado de grupo.");

        this.AreGroups = true;
        event.confirm.resolve(event.newData);
      },
      error: (err) => {
        this.authService.swalError('Error', `Ha ocurrido un error al intentar cambiar de grupo: ${err.message || err}`);
        console.log(err);
      }
    });
  }

  getSubjectGroups(courseGroupId: number) {
    const filter: string = JSON.stringify({ where: {and: [{ CourseGroupID: Number(courseGroupId) }, {CourseOferID: Number(this.student.CourseOferID)}]} });
    return this.coursesService.getSubjectGroup(filter);
  }

  getUserSubjects(SubjectGroupIDs: number[], userId: number) {
    let filter = JSON.stringify({
      where: {
        and: [{ UserID: userId }, { SubjectGroupID: { inq: SubjectGroupIDs } }]
      },
      include: [{ subjectGroup:'userapp' }, { userRecord: ['midTerm', 'dateRecord'] }]
    });

    return this.coursesService.getUserSubject(filter, userId);
  }

  CourseByID(id: number) {
    var num = this.course_by_school.find(x => x.id == id);
    if (num)
      return num.NameCourseOfer;
    return "Seleccionar"
  }

  onEditConfirmGroupsUpdate(event) {
    //SCRIPT PARA CONFIRMAR LA HORA DE LA TABLA
    var StartDayTime = event.newData.StartDayTime
    var EndDayTime = event.newData.EndDayTime
    if (moment(StartDayTime, "HH:mm", true).isValid() &&
      moment(EndDayTime, "HH:mm", true).isValid() &&
      moment(StartDayTime, "HH:mm").isBefore(moment(EndDayTime, "HH:mm"))) {
      
      this.authService.swalLoading("Actualizando horas")
      this.coursesService.EditCourseGroupsByID(event.newData.id,event.newData).subscribe(isOK=>{
        this.authService.swalEndLoad()
        event.confirm.resolve(event.newData);

      },err=>{
        this.authService.swalError("Error Guardando Horas", "Ocurrio un error al actualizar las horas, error: "+err)

      })
      
    }
    else {
      this.authService.swalError("Error Guardando Horas", "Seleccione una hora correcta y que la hora final de clases sea mayor a la hora inicial")
    }

  }
  onSearch2(AreGroups: string = ''){
    if (AreGroups == "") {
      this.source.setFilter([])
    } else {
      AreGroups = AreGroups.replace(/\s/g, '');
      this.source.setFilter([
        {
          field: 'Document',
          search: AreGroups
        }
      ], false);
    }
  }


}


