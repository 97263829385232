import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { CustomFormsModule } from 'ng2-validation';
import { ProfessorCoursesComponent, ButtonViewCoursesComponent } from './professor-courses.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';

export const routes: Routes = [
  { path: 'agrupacion/professor/courses', component: ProfessorCoursesComponent },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    CustomFormsModule,
    HttpModule,
    Ng2SmartTableModule,
  ],

  entryComponents: [
    ButtonViewCoursesComponent,
  ],
  declarations: [
    ProfessorCoursesComponent,
    ButtonViewCoursesComponent,
  ]

})
export class ProfessorCoursesModule { }
