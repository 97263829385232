import { Component, OnInit, OnDestroy, AfterViewChecked, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { AttachmentService } from "../../../models/attachment/attachment.service";
import { School } from '../../../models/Coursesinfo/school';
import { Course } from '../../../models/Coursesinfo/course';
import { UsersField } from '../../../models/usersInfo/users-fields';
import { OapinfoService } from '../../../models/oapinfo/oapinfo.service';
import { UsersService } from '../../../models/usersInfo/users.service';
import { CoursesService } from '../../../models/Coursesinfo/courses.service';
import { ProfileNavComponent } from '../../../components/profile-nav/profile-nav.component';
import { AuthService } from '../../../models/Auth/auth.service';


import { DomSanitizer } from "@angular/platform-browser";
import { FormGroup, FormBuilder } from '@angular/forms';//    D
import { environment } from 'environments/environment';


var async = require("async");
declare var inputmask: any;
declare var $: any;






@Component({
  selector: 'prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.css'],
  providers: []
})

export class PricesComponent implements OnInit, OnDestroy {
  serverTime: Date
  DateMessage = ""
  public mask = [/[1-2]/, /\d/, /\d/, /\d/, '-', /[0-1]/, /\d/, '-', /[0-3]/, /\d/];
  schools: School[] = [];
  SchoolID: number;
  ScnoolAcronim = `${environment.PRINCIPAL_APP_NAME.toUpperCase()}`
  isAgrupacion: boolean = false
  typeCourses: any[] = []
  reqField: any;
  submitbuton: string = "Guardar"
  stateButton: boolean = false
  EnableTable: boolean = false
  collapseCards: boolean = true;
  course_by_school: Course[];
  student: UsersField = {
    CourseOferID: 0,


  };
  classromID = 0
  classRoomsByCourse: any[] = []
  userJobsContractsBySchoolID: any[] = []
  show: boolean = false
  courseOfferSelected: number = 0
  yearSelected = 0


  configMask={ prefix: '$ ', thousands: '.', decimal: ',' ,precision:2}

  Userapps: any[] = [];
  public myForm: FormGroup;
  isContratacion: boolean = false;
  schoolReqs: any[] = [];
  schoolReqsFiltered: any[] = [];
  constructor(
    private authService: AuthService,
    private oapinfoService: OapinfoService,
    private usersService: UsersService,
    private coursesService: CoursesService,
    private attachmentService: AttachmentService,
    private route: ActivatedRoute,
    private router: Router,
    public profileNavComponent: ProfileNavComponent,
    private _sanitizer: DomSanitizer,
    private builder: FormBuilder,
  ) {



    this.authService.getTime().then(server2 => this.serverTime = server2.time);

    this.coursesService.getAllSchools().subscribe(p => this.schools = p);

    for (let role of this.profileNavComponent.roles) {

      if (role.name == "admin") {
        this.SchoolID = role.SchoolID;
        this.ScnoolAcronim = role.SchoolAcronim;

      }
      if (role.name == "Inspector Escuela") {
        this.isAgrupacion = true
        this.ScnoolAcronim = role.SchoolAcronim;
        this.SchoolID = role.SchoolID;

      }
      if (role.name == "Financiera") {
        this.isContratacion = true
        this.ScnoolAcronim = role.SchoolAcronim;
        this.SchoolID = role.SchoolID;

      }
    }

    //CARGAR TODOS LOS REQS
    this.coursesService.getAllSchoolReqsBySchoolID(this.SchoolID).subscribe(requestsSchool => {
      this.schoolReqs = requestsSchool
    })

    //Cargar tipo de cursos
    this.coursesService.getAllTypeCourse().subscribe(p => {
      this.typeCourses = p
      this.EnableTable = true  //SE HABILITA LA TABLA PARA QUE SE HAGA EL RENDERIZADO BIEN.
    })


  }

  ngOnInit() {
    this.reqField = {
      id: null,
      SchoolID: this.SchoolID,
      TypeCourseID: 0,
      PriceIncript: "",
      PriceCredit: "",
      Documents: "",
      Year: 0,
      isActive: true,
      SupportFile: ""
    }
    this.setJquerry();

  }

  ngOnDestroy() {

  }


  loadReqs(model) {
   

    this.schoolReqsFiltered = this.schoolReqs.filter(x => x.Year === parseInt(model.YearSelected))
  
    $(function () {
      // card controls


      // header double click
      $('.cat__core__sortable .card-header').on('click', function () {
        
        $(this).closest('.cardedit').toggleClass('cat__core__sortable__collapsed');

      });

    });

  if(this.schoolReqsFiltered.length==0){
    this.authService.swalError("Error al cargar datos","No existen datos que cumplan con el criterio seleccionado")
  }

  }




  colorSchool(idSchool: number) {
    let classes = {};

    switch (idSchool) {
      case 0: classes = { cat__info__item_CEDOC: true }; break;
      case 1: classes = { cat__info__item_CEMIL: true }; break;
      case 4: classes = { cat__info__item_EAS: true }; break;
      case 5: classes = { cat__info__item_ESINF: true }; break;
      case 6: classes = { cat__info__item_ESCAB: true }; break;
      case 7: classes = { cat__info__item_ESART: true }; break;
      case 8: classes = { cat__info__item_ESING: true }; break;
      case 9: classes = { cat__info__item_ESCOM: true }; break;
      case 10: classes = { cat__info__item_ESICI: true }; break;
      case 16: classes = { cat__info__item_ESAVE: true }; break;
      case 17: classes = { cat__info__item_ESLOG: true }; break;
      case 18: classes = { cat__info__item_ESCEQ: true }; break;
      case 19: classes = { cat__info__item_ESPOM: true }; break;
      case 20: classes = { cat__info__item_ESMAI: true }; break;
      case 21: classes = { cat__info__item_ESDIH: true }; break;
      case 22: classes = { cat__info__item_CEMAI: true }; break;
      case 23: classes = { cat__info__item_ESIDE: true }; break;
      case 24: classes = { cat__info__item_ESFES: true }; break;
      case 25: classes = { cat__info__item_CEDOC: true }; break;
      case 33: classes = { cat__info__item_ESMIC: true }; break;
      case 34: classes = { cat__info__item_EMSUB: true }; break;
      case 35: classes = { cat__info__item_ESPRO: true }; break;
      default: classes = { cat__info__item_ALL: true }; break;


    }
    return classes;
  }


  setJquerry() {

    $(function () {
      // card controls


      // header double click
      $('.cat__core__sortable .card-header').on('click', function () {
        
        $(this).closest('.card').toggleClass('cat__core__sortable__collapsed');

      });

    });

  }


  textSchools(id: number) {
    var num = this.schools.find(x => x.id == id);
    if (num)
      return num.NameTSchool;
    return "Nombre de Escuela"
  }

  textTypeCourseID(id: number) {
    var num = this.typeCourses.find(x => x.id == id);
    if (num)
      return num.NameTypeCourse;
    return "Nombre del Curso"
  }



  createPrices(data: any, valid, isNew?: boolean) {
  console.debug("el dato original es",data)
    var save = false;
    if (valid) {

      if (isNew) {
        var reqs = this.schoolReqs.filter(x => (x.TypeCourseID === data.TypeCourseID) && (x.Year === parseInt(data.Year) ) )

        if (reqs.length>0){
          this.authService.swalError("Error al Guardar","Ya Existen datos para el tipo de curso y año seleccionado")
          return
        }
        else{
          save=true
        }

      }
      else {
        save = true
      }



      if (save == true) {
        this.authService.swalLoading("Guardando Información")
        //ELIMINO LO PUNTOS DE LOS PRECIOS
        
          
        //this.authService.swalLoading("Guardando Contrato")
        console.debug("LO QUE SE VA A GUARDAR ES",data)
        this.coursesService.patchSchoolReqs(data).subscribe(data => {
          this.authService.swalEndLoad()
          this.reqField = {
            id: null,
            SchoolID: this.SchoolID,
            TypeCourseID: 0,
            PriceIncript: "",
            PriceCredit: "",
            Documents: "",
            Year: 0,
            isActive: true,
            SupportFile: ""
          }
        
          //CARGAR TODOS LOS REQS
          this.coursesService.getAllSchoolReqsBySchoolID(this.SchoolID).subscribe(requestsSchool => {
            this.schoolReqs = requestsSchool
            this.schoolReqsFiltered=[]
            this.yearSelected=0
            
          })
        }, err => {
          this.authService.swalError("Error al crear precio ", "No se pudo crear el precio al tipo de curso, detalles " + err)
        })

      } else {
        this.authService.swalError("Error Creando Contrato", "Todos los campos son obligatorios")
      }
    }

  }





  maskCurrencyEdit(model, id) {
    console.log(model)
    $(document).ready(function () {
      $('#money' + id).mask('000.000.000.000.000,00', { reverse: true });
    })
    $(document).ready(function () {
      $('#money2' + id).mask('000.000.000.000.000,00', { reverse: true });
    })
    
  }


  maskCurrency(model) {
    console.log(model)
    $(document).ready(function () {
      $('#money').mask('000.000.000.000.000', { reverse: false });
    })
    $(document).ready(function () {
      $('#money2').mask('000.000.000.000.000', { reverse: false });
    })
  }







}

